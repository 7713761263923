<script lang="ts">
    import { blur, fade, fly, slide } from 'svelte/transition';
    import type { IP2PLiquidity, IProviderRate } from '../../Data/P2P';
    import BigNumber from 'bignumber.js';
    import { getTokenInfo } from '../../Data/Tokens';
    import type { IToken } from '../../Data/Tokens';
    import { onMount } from 'svelte';
    import { getAddressInfo } from '../../API/P2P';
    import { web } from '../../Data/Web3Store';
    import { ChainId } from '../../Data/Wallet';
    import { hexToRgba } from '../../API/helpers/hexToRGBA';
    import LoadBlaster from '../Common/LoadBlaster.svelte';
    import { AddLiqTexts, SkipTexts, Locale } from '../../Data/Locale';
    import subZero from '../../API/helpers/subZero';

    export let rampRate: IProviderRate;
    export let asset: IToken | null;
    // export let background: string = '0EA5E9';
    export let minMaxSymbol: string = '';
    export let providerHours: string | undefined;
    export let spreadRange: string | undefined;

    let tokenInfo: IToken;
    let score: number;
    let symbol: string;

    //unused, just grab these from the rampRate directly
    export let min: BigNumber;
    export let max: BigNumber;

    // $: console.log('new ramprate', rampRate, rampRate.min?.toString(), rampRate.max?.toString());

    function scoreToGrade(score: number) {
        if (score > 0) return '🥰';
        if (score == 0) return '🙂';
        if (score < -1) return '😡';
        if (score < 0) return '😟';
        return '🧐';
    }
    onMount(async () => {
        console.log('rampRate', rampRate);
        // console.log('tradeDetails spread range', spreadRange);
        console.log('trade details minmax: ', min?.toFixed(), max?.toFixed());
        let liqDetails = await getAddressInfo(rampRate.maker, asset?.chainId);
        score = Number(liqDetails?.score) || 0;
        
        await getFreshTokenInfo();
    });

    const getFreshTokenInfo = async () => {
        console.log('inside getFreshTokenInfo');
        console.log('asset', asset);
        console.log('rampRate', rampRate);
        if(!asset || !asset?.address) return;
        tokenInfo = (await getTokenInfo($web, asset.address, asset.chainId, true))[0];
        

        symbol = tokenInfo?.symbol || '???';
        min = BigNumber(min) || BigNumber(0) as BigNumber;
        max = BigNumber(max) || BigNumber(0) as BigNumber;

    }
</script>

{#if tokenInfo?.symbol &&  max.gt(0)}
    <main 
    class="flex flex-col venues-container pl-4 whitespace-nowrap"   
    data-testid="trade-details"

    >
        <div class="flex flex-col details gap-2 text-white mb-1 buttonGradient">
            <div class="flex " 
                >
                <div class="flex flex-col">
                    <span>{AddLiqTexts[$Locale].spread}: {rampRate.spread !== 0 ? (BigNumber(rampRate?.spread).div(100).toString() + '%') : spreadRange}</span>
                    <span>{SkipTexts[$Locale].score}: {scoreToGrade(score) || 'Loading...'}</span>
                </div>
                <div class="flex flex-col pl-4">
                    <span>Min: {subZero(min.toString() || "0") + ' ' + (minMaxSymbol || '')}</span>
                    <span>Max: {subZero(max.toString() || "0")+ ' ' + (minMaxSymbol || '')}</span>
                </div>
            </div>
            {#if providerHours}
                <div class="flex items-center" data-tooltip="Trading during these hours will provide the fastest service">
                    <span class="flex items-center"><i class="fad fa-stopwatch text-white pr-2 text-xl"></i>{providerHours}</span>
                </div>
            {/if}
        </div>

    </main>
{:else}
    <main 
    class="flex flex-col venues-container min-h-[70px] pl-4"   
    data-testid="trade-details"

    >
        <div class="flex details gap-2 text-white mb-2 buttonGradient">
            <div style="height: 10px; margin: auto">
            <LoadBlaster showLoad={true} loader={1} color={"fff"} />
        </div>
        </div>
    </main>
{/if}
<style lang="scss">
    .details {
        border-radius: 8px;
        // min-height: 70px;
        padding: 0.5rem 0.75rem;
    }

    .venues-container {
        // width: 100%;
        min-height: 70px;
        min-width: 70px;
        /* max-width: 100%; */
        /* margin: 0 auto; */
    }
</style>