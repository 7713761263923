import { writable } from 'svelte/store';
import type Web3 from 'web3';
import axios from 'axios';
import ABI from './ABI';
import BigNumber from 'bignumber.js';
import backendUrl from '../BackendDetails';
import Contracts from './Contracts';
import { get } from 'svelte/store';
import { rpcLooper } from '../API/helpers/rpcLooper';
import { getTokenLogo } from '../API/helpers/trustWallet';
import { Chains } from './Chains';

export const ETHER: IToken = {
  decimals: 18,
  name: 'Ether',
  symbol: 'ETH',
  logoURI: '/images/coins/ETH.svg',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 1,
};
export const GOERLI_ETH: IToken = {
  decimals: 18,
  name: 'Ether Goerli',
  symbol: 'gETH',
  logoURI: '/images/coins/ETH.svg',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 5,
};
export const RINKEBY_ETH: IToken = {
  decimals: 18,
  name: 'Rinkeby',
  symbol: 'ETH',
  logoURI: '/images/coins/ETH.svg',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 4,
};
export const BNB: IToken = {
  decimals: 18,
  name: 'BNB',
  symbol: 'BNB',
  logoURI: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 56,
};
export const WETH: IToken = {
  decimals: 18,
  name: 'Wrapped Ether',
  symbol: 'WETH',
  logoURI: '/images/coins/WETH.png',
  address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  chainId: 1,
};
export const WBNB: IToken = {
  decimals: 18,
  name: 'WBNB',
  symbol: 'WBNB',
  logoURI: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.png',
  address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  chainId: 56,
};
export const MATIC: IToken = {
  decimals: 18,
  name: 'Matic',
  symbol: 'MATIC',
  logoURI: 'https://cryptologos.cc/logos/polygon-matic-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 137,
};
export const AVAX: IToken = {
  decimals: 18,
  name: 'Avax',
  symbol: 'AVAX',
  logoURI: 'https://cryptologos.cc/logos/avalanche-avax-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 43114,
};
export const FTM: IToken = {
  decimals: 18,
  name: 'Fantom',
  symbol: 'FTM',
  logoURI: 'https://cryptologos.cc/logos/fantom-ftm-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 250,
};
export const xDAI: IToken = {
  decimals: 18,
  name: 'xDai',
  symbol: 'xDAI',
  logoURI: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 100,
};
export const PLS: IToken = {
  decimals: 18,
  name: 'PLS',
  symbol: 'PLS',
  logoURI: 'https://dd.dexscreener.com/ds-data/chains/pulsechain.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 369,
};
export const ARETH: IToken = {
  decimals: 18,
  name: 'Arbitrum Ether',
  symbol: 'ARETH',
  logoURI: 'https://get.celer.app/cbridge-icons/chain-icon/arbitrum.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 42161,
};
export const HECO: IToken = {
  decimals: 18,
  name: 'Huobi Token',
  symbol: 'HT',
  logoURI: 'https://cryptologos.cc/logos/huobi-token-ht-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 128,
};
export const OKT: IToken = {
  decimals: 18,
  name: 'OKex Token',
  symbol: 'OKT',
  logoURI: 'https://cryptologos.cc/logos/okb-okb-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 66,
};
export const KUCOIN: IToken = {
  decimals: 18,
  name: 'KuCoin Token',
  symbol: 'KCS',
  logoURI: 'https://cryptologos.cc/logos/kucoin-token-kcs-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 321,
};
export const CELO: IToken = {
  decimals: 18,
  name: 'Celo Token',
  symbol: 'CELO',
  logoURI: 'images/coins/CELO.svg',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 42220,
};
export const ONE: IToken = {
  decimals: 18,
  name: 'Harmony ONE',
  symbol: 'ONE',
  logoURI: 'images/coins/ONE.svg',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 1666600000,
};
export const CRO: IToken = {
  decimals: 18,
  name: 'Cronos',
  symbol: 'CRO',
  logoURI: 'https://swap.crodex.app/cro.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 25,
};
export const IOTEX: IToken = {
  decimals: 18,
  name: 'IOTEX',
  symbol: 'IOTEX',
  logoURI: 'https://cryptologos.cc/logos/iotex-iotx-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 4689,
};
export const VLX: IToken = {
  decimals: 18,
  name: 'Velas',
  symbol: 'VLX',
  logoURI: 'https://cryptologos.cc/logos/velas-vlx-logo.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 106,
};
export const METIS: IToken = {
  decimals: 18,
  name: 'Metis',
  symbol: 'METIS',
  logoURI: '/images/natives/metis.png',
  address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  chainId: 1088,
};
export const WDOGE: IToken = {
  decimals: 18,
  name: 'WDOGE',
  symbol: 'WDOGE',
  logoURI: '/images/natives/wdoge.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 1088,
};
export const CET: IToken = {
  decimals: 18,
  name: 'CoinEx Token',
  symbol: 'CET',
  logoURI: '/images/natives/coinex.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 52,
};
export const ETHW: IToken = {
  decimals: 18,
  name: 'Ethereum PoW',
  symbol: 'ETHW',
  logoURI: '/images/natives/ethw.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 10001,
};
export const BONE: IToken = {
  decimals: 18,
  name: 'Bone',
  symbol: 'BONE',
  logoURI: '/images/natives/bone.png',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  chainId: 109,
};

export const BLANK: IToken = {
  decimals: 18,
  name: null,
  symbol: '',
  logoURI: '',
  address: '',
  chainId: 0,
};

export interface IToken {
  name?: string | null;
  chainId?: number;
  symbol?: string | null;
  decimals?: number;
  address?: string;
  logoURI?: string;
  tokenID?: number;
  isRBX?: boolean;
  isSponsored?: boolean;
  //perhaps add additional attribute for tokenID for NFTs
}

export interface ICurrency {
  name: string | null;
  symbol: string | null;
  decimals: number | 2;
  logoURI?: string;
}

export interface ISpecialToken {
  chainId: number;
  address: number;
}

export interface ITokenBalance {
  [keys: string]: {
    balance: string;
    stableBalance: string;
  };
}

export interface IChainTokenBalance {
  [keys: number]: {
    [keys: string]: {
      balance: string;
      stableBalance: string;
    };
  };
}

export const BaseTokens = [
  ETHER,
  GOERLI_ETH,
  RINKEBY_ETH,
  BNB,
  MATIC,
  KUCOIN,
  OKT,
  HECO,
  ARETH,
  xDAI,
  FTM,
  AVAX,
  CELO,
  ONE,
  CRO,
  IOTEX,
  VLX,
  METIS,
  WDOGE,
  CET,
  ETHW,
  PLS
];

export const TokenBalances = writable<ITokenBalance>({});
export const ChainTokenBalances = writable<IChainTokenBalance>({});

export const Tokens = writable<IToken[]>([]);

export const getAllTokensFromBackend = async (chainId: number): Promise<IToken[]> => {
  let resp;
  try {
    resp = await axios.get(`${backendUrl}/all-tokens`, {
      params: { chainId: chainId },
    });
  } catch (e) { }

  if (!resp) {
    const big = require('../Data/bigTokenList.json');

    resp = big.tokens.filter((t: { chainId: number }) => t.chainId === chainId);
  }
  return !resp?.data ? resp : resp.data;
};

export const getSpecialTokens = async () => {
  try {
    const resp = await axios.get(`${backendUrl}/special-lists`);

    const specialLists: {
      rbxTokens: ISpecialToken[];
      sponsoredTokens: ISpecialToken[];
    } = resp.data;

    return specialLists;
  } catch (e) {
    return {
      rbxTokens: [],
      sponsoredTokens: [],
    };
  }
};

export const getBalance = async (web3: Web3, address: string, token: string): Promise<BigNumber> => {
  if (!address) return new BigNumber(0);
  if(token.toLowerCase() == Contracts.BASE.toLowerCase()){
    const raw = await web3.eth.getBalance(address);
    return new BigNumber(raw).div(10 ** 18);
  }
  const contract = new web3.eth.Contract(ABI.erc20, token);
  const raw = await contract.methods.balanceOf(address).call();
  const dec = await contract.methods.decimals().call();

  return new BigNumber(raw).div(10 ** dec);
};

export const getTokenInfo = async (
  web3: any | null,
  token: string,
  cid: number = 1,
  skipLogo: boolean = false
): Promise<IToken[]> => {
  let chainId = cid;
  let chains = get(Chains);

  if (token.toLowerCase() == Contracts.BASE.toLowerCase()) {
    // console.log('getting base info for chainId', `/images/coins/${chains[chainId]?.nativeCurrency?.symbol}.svg`);
    return [
      {
        address: Contracts.BASE,
        chainId: chainId,
        decimals: 18,
        logoURI: `/images/coins/${chains[chainId]?.nativeCurrency?.symbol}.svg`,
        name: chains[chainId]?.nativeCurrency?.name,
        symbol: chains[chainId]?.nativeCurrency?.symbol,
      }
    ];
  }

  let w3: Web3 | undefined = undefined;
  try {
    w3 = await rpcLooper(cid);
    if (!w3) return [];
  } catch (e) {
    return [];
  }

  try {

    const contract = new w3.eth.Contract(ABI.erc20, token);
    let symbol = await contract.methods.symbol().call();
    let decimals = parseInt(await contract.methods.decimals().call());
    let name = await contract.methods.name().call();

    return [
      {
        address: token,
        chainId: chainId,
        decimals: decimals,
        logoURI: skipLogo ? '' : (await getTokenLogo(token, chainId, symbol)) || '/images/mysterytoken1.png',
        name: name,
        symbol: symbol,
      },
    ];
  } catch (e) {
    return [];
  }

};