import { chains, goodRPCs } from '../../Data/Chains';
import { secondaryWeb3, web } from '../../Data/Web3Store';
import { get, writable } from 'svelte/store';
import { ChainId, WalletConnected } from '../../Data/Wallet';
import './iframeProvider';
import { isEmbeddedInIFrame } from './iframeProvider';
import Web3 from 'web3';

export const lastChain = writable<number>(0);

export async function rpcLooper(chain: number, rand: boolean = true, fresh: boolean = false, start = 0): Promise<Web3 | undefined> {
    const walletConnectedCheck = get(WalletConnected);
    const walletChain = walletConnectedCheck ? get(ChainId) : 0;

    if (walletChain == chain) {
        console.log('using wallet connection');
        return get(web);
    }
    console.log('not using wallet connection');

    const lastrun = get(lastChain);

    let existingConnection: boolean = !!get(secondaryWeb3);
    let w3s: Web3 = get(secondaryWeb3) || new Web3();

    let cid = null;// = get(lastChain) || null;
    if (chain == lastrun && existingConnection) {
        return w3s;
    } else if (existingConnection) {
        try {
            cid = (await Promise.race([w3s.eth.getChainId(), new Promise((resolve) => setTimeout(resolve, 1000, false))])) || null;
        } catch {
            cid = null;
        }
        if (!!cid && cid == chain) {
            lastChain.set(cid as number);

            return w3s;
        }
    }

    chain = chain || get(ChainId);

    const Chains = chains;
    let urls = !!Chains[chain] && Chains[chain]?.rpc ? Chains[chain]?.rpc?.length : null;

    let connected: boolean = false;
    let endpoint: number = start > urls ? start % urls : start; // loop back around if start is greater than urls
    let web3: Web3 = new Web3();
    let rpc;
    let triedRpcs: string[] = [];
    let runs = 0;

    while (!connected && runs < urls) {
        endpoint = rand ? Math.round(Math.random() * urls) : endpoint;
        rpc = Chains[chain]?.rpc[endpoint];
        if (triedRpcs.includes(rpc)) {
            continue;
        }
        triedRpcs.push(rpc);

        if (!rpc) {
            rpc = Chains[chain]?.rpc[0];
            if (!rpc) return undefined;
            continue;
        }
        try {
            const provider = new Web3.providers.HttpProvider(rpc);
            web3 = new Web3(provider);

            connected = !!(await web3.eth.getBlock('latest')) ? true : false;
            if (!connected) {
                runs++;
                continue;
            } else {
                  secondaryWeb3.set(web3);
            }
        } catch (error) {
            runs++;
            continue;
        }
    }

    if (connected) {
        const chainid = await web3.eth.getChainId();
        lastChain.set(chainid);
        return web3;
    } else {
        return undefined;
    }
}
