<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte';
    import { DEXPreload } from '../../Data/DEXPreload';
    import { AVAX, BNB, ETHER, getBalance, PLS, type IToken } from '../../Data/Tokens';
    import { ChainId, Wallet } from '../../Data/Wallet';
    import chains from '../../Data/Chains';
    import { web } from '../../Data/Web3Store';
    import Contracts from '../../Data/Contracts';
    import BigNumber from 'bignumber.js';
    import { getTokenLogo } from '../../API/helpers/trustWallet';
    import { tokens } from '../../Data/P2P';
    import LoadBlaster from './LoadBlaster.svelte';

    const dispatch = createEventDispatcher();

    interface ITokenWithBalance extends IToken {
        walletBalance: BigNumber;
    }

    $: updateAssetList();

    let loading = false;
    let assetList: ITokenWithBalance[] = [];
    let selectedAsset: ITokenWithBalance | undefined;
    const NATIVES: {[key: number]: IToken} = {56: BNB, 1: ETHER, 43114: AVAX, 369: PLS}

    const updateAssetList = async () => {
        loading = true;
        assetList = [
            ...tokens
                .filter(token => token.chainId == $ChainId && token.address?.toLowerCase() !== $DEXPreload.receiving_token_address.toLowerCase())
                .map(token => ({...token, walletBalance: new BigNumber(0)}))
        ];
        // //add native
        // const nativeBal = await getBalance($web, $Wallet.address, Contracts.BASE);
        // assetList.push({...NATIVES[$ChainId], walletBalance: nativeBal});
        assetList = assetList;

        for(let i = 0; i < tokens.length; i++){
            if(tokens[i].chainId == $ChainId){
                assetList.push({...tokens[i], walletBalance: await getBalance($web, $Wallet.address, tokens[i].address || '')})
            }
        }

        const assetMap = new Map();
        assetList.forEach(asset => {
            assetMap.set(asset.address, asset);
        });
        assetList = Array.from(assetMap.values()).filter(asset => asset.address !== $DEXPreload.receiving_token_address);
        loading = false;
    }

    const onSelectAsset = (asset: ITokenWithBalance) => {
        if(asset.symbol == selectedAsset?.symbol){
            selectedAsset = undefined;
            return;
        }
        selectedAsset = asset;
        $DEXPreload.spending_chain_id = asset.chainId;
        $DEXPreload.spending_token_address = asset.address;
        $DEXPreload.spending_token_symbol = asset.symbol || '';
    }

    const onSwapClick = () => {
        const queryString = '?spending_token=' + 
            $DEXPreload.spending_token_address +
            '&spending_chain_id=' + 
            $DEXPreload.spending_chain_id + 
            '&receiving_token=' + 
            $DEXPreload.receiving_token_address + 
            '&receiving_chain_id=' + 
            $DEXPreload.receiving_chain_id;

        window.open(`https://launch.rbxdefi.com/exchange/dex/${queryString}`);
        dispatch('close');
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="fixed inset-0 z-30 flex flex-col justify-center items-center modal-holder" >
    <div class="modalBg infoText px-1 rounded-xl neu-border z-[31]">
        <div class="flex w-full justify-center  items-center gap-4  text-center my-2">
            <i class="fad fa-exclamation-triangle text-amber-700 text-4xl"  style="--fa-primary-color: #e12323; --fa-secondary-color: #ecbe18; --fa-secondary-opacity: 1;"></i>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="font-bold w-1/2">{$DEXPreload.trade_size?.toFixed(2)} {$DEXPreload.receiving_token_symbol} required to accept trade!</div>
        </div>
        <hr class="border-gray-200 w-1/2 m-auto my-2" />
        <!-- <p class="text-black text-center px-4 mt-2">
            Select an asset from this list, and then click the blue button below to be taken to a prefilled DEX page where you can swap into {$DEXPreload.receiving_token_symbol}<br />
        </p> -->

        <div class="flex w-full  items-center justify-between px-6">
            <!-- <h2 class="text-2xl">{$DEXPreload.trade_size?.toFixed(2)} {$DEXPreload.receiving_token_symbol} Required</h2> -->
            <span class="text-sm m-auto font-thin">Balance: <span class="text-red-600 font-bold">{$DEXPreload.wallet_balance?.toFixed(2)} {$DEXPreload.receiving_token_symbol}</span>
        </div>

        <p class="text-gray-500 text-center my-1 italic text-sm">Select an asset below to swap into {$DEXPreload.receiving_token_symbol}</p>

        <div class="asset-container">
            {#each assetList as asset}
                <button class="asset infoText text-sm {selectedAsset?.symbol == asset?.symbol ? 'selected' : ''}" on:click={() => onSelectAsset(asset)}>
                    <div class="flex items-center justify-between">
                        <img src={asset.logoURI} class='w-6 mr-2' alt=""/>
                        <span class="">{asset?.symbol}</span>
                    </div>
                    {#if loading && asset.walletBalance.eq(0)}
                        <LoadBlaster showLoad={true}/>
                    {:else}
                        <span class="">Bal: {asset.walletBalance.toFixed(3)}</span>
                    {/if}
                </button>
            {/each}
        </div>

        <div class="flex gap-8 justify-center my-4">
            <div class="flex justify-center gap-3 md:gap-4 w-1/3">
                <button class="collat bg-gray-500 border-gray-200" on:click={(_) => dispatch('close')}>Close</button>
            </div>

            <div class="flex justify-center gap-3 md:gap-4 w-1/3">
                <button 
                    class="collat {selectedAsset && selectedAsset.walletBalance.gt(0) ? 'bg-sky-500 border-sky-200' : 'bg-gray-500 border-gray-200'}"
                    disabled={!selectedAsset}
                    on:click={onSwapClick}>Swap
                </button>
            </div>
        </div>
    </div>
    <div on:click={() => dispatch('close')} class="w-screen h-screen bg-transparent fixed top-0 left-0"></div>
</div>

<style lang="scss">
    h2 {
        @apply text-lg text-sky-400 font-semibold text-center;
    }

    .asset-container{
        @apply flex items-center justify-center flex-wrap;
    }

    .asset{
        @apply flex flex-col items-center rounded-md py-1 mx-1;
        flex-basis: calc(33.333% - 1rem);
    }

    .asset:hover{
        @apply bg-sky-100;
        box-shadow:inset 0px 0px 0px 1px #3059b0;//rgb(14 165 233 / var(--tw-border-opacity));
    }

    .selected{
        @apply bg-sky-500 text-white;
    }

    .neu-border {
        border-width: 3px;
        border-style: solid;
        border-bottom-left-radius: 2rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 0rem;
        overflow: hidden;
        box-shadow: 0px 0px 5px 0px;// rgba(0, 0, 0, 0.1);
        // border-block-color: darkgrey;
        // border-block-style: dotted;
    }

    .modal-holder {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .collat {
        @apply p-[0.5rem] w-[100%] rounded-2xl px-4 py-1 border-2 text-white;
    }
</style>
