import { IRawListing } from "Data/P2P";
import { AllLiquidityStore, P2PCache } from "../../Data/P2PStore"
import { get } from "svelte/store"
import BigNumber from "bignumber.js";

export const getSpreadRange = (currencySymbol: string, assetSymbol: string, direction: string) => {
    const listings = get(AllLiquidityStore).returnData;

    let matched: IRawListing[] = [];
    for(let i = 0; i < listings.length; i++){
        // console.log('asset symbol getspreadrange', assetSymbol);
        // console.log('currency symbol getspreadrange', currencySymbol);
        // console.log('getspreadrange', 
        //     listings[i].type == direction,
        //     listings[i].active,
        //     listings[i].asset.symbol?.toLowerCase() == assetSymbol.toLowerCase(),
        //     listings[i].currency == currencySymbol,
        //     listings[i].asset.symbol,
        //     listings[i].currency,
        //     listings[i].active,
        //     listings[i].type
        // ) 
        if(
            listings[i].type == direction &&
            listings[i].active && 
            listings[i].asset.symbol?.toLowerCase() == assetSymbol.toLowerCase() && 
            listings[i].currency == currencySymbol
        ) matched.push(listings[i]);
    }

    // console.log('found', matched.length, 'possible listings getspreadrange', matched)
    let lowest = Infinity;//can safely be 10k
    let highest = 0;
    for(let i = 0; i < matched.length; i++){
        if(+matched[i].spread > highest) highest = +matched[i].spread;
        if(+matched[i].spread < lowest) lowest = +matched[i].spread;
    }
    // console.log('getSpreadRange lowest', lowest);
    // console.log('getSpreadRange highest', highest);
    // console.log('getSpreadRange result', `${BigNumber(lowest).div(100).toFixed(1)}% - ${BigNumber(highest).div(100).toFixed(1)}%`);
    if (isFinite(lowest) && isFinite(highest)) return `${BigNumber(lowest).div(100).toFixed(1)}% - ${BigNumber(highest).div(100).toFixed(1)}%`
}