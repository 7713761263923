import { get, writable } from 'svelte/store';
import { P2P_CACHE } from './LocalStorageItems';
import { ICollatPreFetch, type IP2PTradeLiq } from './P2P';
import { IRawListing } from './P2P';
import type { Result } from 'API/utils/aggregateMinMax';

const loadAndMerge = () => {
  const mem = JSON.parse(localStorage.getItem(P2P_CACHE) || '[]') as IP2PTradeLiq[];
  // localStorage.setItem(P2P_STORE, JSON.stringify(mem, null, 2))
  // P2PCache.set(mem);
  return mem;
};

// export const PairStore = writable<IP2PTradeLiq[]>(loadAndMerge());

// function that returns an IP2PTradeLiq[] with duplicates removed

const p2pStore = (p2pInfo: IP2PTradeLiq[]) => {
  if (localStorage.getItem(P2P_CACHE) === null || localStorage.getItem(P2P_CACHE) == '[]') localStorage.setItem(P2P_CACHE, JSON.stringify(p2pInfo));
  const saved = JSON.parse(localStorage.getItem(P2P_CACHE) || ''); // convert to object
  const { subscribe, set, update } = writable(saved); // create the underlying writable store

  return {
    subscribe,
    set: (data: IP2PTradeLiq[]) => {
      localStorage.setItem(P2P_CACHE, JSON.stringify(data)); // save also to local storage as a string
      return set(data);
    },
    update: (incomingData: IP2PTradeLiq[]) => {
      localStorage.setItem(P2P_CACHE, JSON.stringify(incomingData)); // save also to local storage as a string
      return set(incomingData);
    },
  };
};

export const P2PCache = p2pStore(loadAndMerge());

export const Collateral = writable<ICollatPreFetch>();

export const AllLiquidityStore = writable<{
  returnData: IRawListing[];
  minMax: Result;
  pairs: any[][];
  timestamp: number;
}>();