import { provider } from 'web3-core';
import { writable } from 'svelte/store';

export interface ProviderInfo {
    uuid: string;
    name: string;
    icon: string;
}

export interface Provider {
    info: ProviderInfo;
    provider: any;
}

export const walletConnectProvider = {
    info: {
        uuid: 'walletconnect',
        name: 'WalletConnect',
        icon: 'wallets/walletConnect.png',
    },
    provider: null,
};

// export const AvailableProviders = writable<Provider[]>([walletConnectProvider]);

// custom store to make sure walletconnect is last
function createAvailableProvidersStore() {
    const { subscribe, set, update } = writable<Provider[]>([walletConnectProvider]);

    function ensureWalletConnectLast(providers) {
        const updatedProviders = providers.filter((provider) => provider.info.uuid !== walletConnectProvider.info.uuid);
        updatedProviders.push(walletConnectProvider);
        return updatedProviders;
    }

    return {
        subscribe,
        set: (providers) => set(ensureWalletConnectLast(providers)),
        update: (updater) => update((providers) => ensureWalletConnectLast(updater(providers))),
    };
}

export const AvailableProviders = createAvailableProvidersStore();

export const Provider = writable<provider | null>(null);
