export function generateComplementaryColors(): [string, string] {
  const hue = Math.floor(Math.random() * 360);
  const color1 = hslToHex(hue, 0.70, 0.40); // Darker background color
  const color2 = hslToHex((hue + 180) % 360, 0.70, 0.80); // Brighter text color
  return [color1, color2];
}

export function hslToHex(h: any, s: any, l: any) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n: any) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function generateBlurredPlaceholder(input: string): string {
  // Helper function to generate random complementary colors
  function generateComplementaryColors(): [string, string] {
    const hue = Math.floor(Math.random() * 360);
    const color1 = `hsl(${hue}, 70%, 40%)`; // Darker background color
    const color2 = `hsl(${(hue + 180) % 360}, 70%, 80%)`; // Brighter text color
    return [color1, 'white'];
  }

  // Helper function to encode SVG to Data URI
  function svgToDataURL(svg: string): string {
    const encodedSVG = encodeURIComponent(svg);
    return `data:image/svg+xml,${encodedSVG}`;
  }

  // Define the coin and text colors
  const [coinColor, textColor] = generateComplementaryColors();

  // Calculate the optimal font size for the text
  const maxTextWidth = 72; // 80% of the main circle size
  const fontSize = 100; //Math.min(Math.floor(maxTextWidth / (input.length / 2)), 20);

  // Generate the SVG content
  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <filter id="blurMe">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
        </filter>
        <radialGradient id="radialGradient">
          <stop offset="0%" style="stop-color:white; stop-opacity:0.65" />
          <stop offset="100%" style="stop-color:white; stop-opacity:0" />
          <animate attributeName="cx" values="-10%; 120%" dur="2s" repeatCount="indefinite" />
          <animate attributeName="cy" values="-10%; 120%" dur="2s" repeatCount="indefinite" />
        </radialGradient>
      </defs>
      <g filter="url(#blurMe)">
        <circle cx="50" cy="50" r="48" fill="${coinColor}" stroke="black" stroke-width="2" />
        <circle cx="50" cy="50" r="45" fill="url(#radialGradient)" />
        <circle cx="50" cy="50" r="43" fill="#00000030" stroke="white" stroke-width="0.75" />
        <foreignObject x="14" y="20" width="72" height="60">
          <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:${fontSize}%; font-family:'Open Sans', Arial; font-weight:bold; color:${textColor}; text-align:center; word-wrap:break-word; line-height:1.1; overflow-wrap:anywhere; display:flex; justify-content:center; align-items:center; height:100%;">
            ${input}
          </div>
        </foreignObject>
      </g>
    </svg>
  `;

  // Return the Data URI
  return svgToDataURL(svgContent);
}


export function generateCoinSVG(input: string): string {
    // Helper function to generate random complementary colors
    function generateComplementaryColors(): [string, string] {
      const hue = Math.floor(Math.random() * 360);
      const color1 = `hsl(${hue}, 70%, 40%)`; // Darker background color
      const color2 = `hsl(${(hue + 180) % 360}, 70%, 80%)`; // Brighter text color
      return [color1, 'white'];
    }
  
    // Helper function to encode SVG to Data URI
    function svgToDataURL(svg: string): string {
      const encodedSVG = encodeURIComponent(svg);
      return `data:image/svg+xml,${encodedSVG}`;
    }
  
    // Define the coin and text colors
    const [coinColor, textColor] = generateComplementaryColors();
  
    // Calculate the optimal font size for the text
    const maxTextWidth = 72; // 80% of the main circle size
    const fontSize = 100; //Math.min(Math.floor(maxTextWidth / (input.length / 2)), 20);
  
    // Generate the SVG content
    const svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="48" fill="${coinColor}" stroke="black" stroke-width="2" />
        <circle cx="50" cy="50" r="45" fill="url(#radialGradient)" />
        <circle cx="50" cy="50" r="43" fill="#00000030" stroke="white" stroke-width="0.75" />
        <defs>
          <radialGradient id="radialGradient" cx="30%" cy="30%" r="50%" fx="30%" fy="5%">
            <stop offset="0%" style="stop-color:white; stop-opacity:0.65" />
            <stop offset="100%" style="stop-color:white; stop-opacity:0" />
          </radialGradient>
        </defs>
        <foreignObject x="14" y="20" width="72" height="60">
          <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:${fontSize}%; font-family:'Open Sans', Arial; font-weight:bold; color:${textColor}; text-align:center; word-wrap:break-word; line-height:1.1; overflow-wrap:anywhere; display:flex; justify-content:center; align-items:center; height:100%;">
            ${input}
          </div>
        </foreignObject>
      </svg>
    `;
  
    // Return the Data URI
    return svgToDataURL(svgContent);
  }


  export function generatePlaceholder(input: string): string {
    // Helper function to generate random complementary colors
    function generateComplementaryColors(): [string, string] {
      const hue = Math.floor(Math.random() * 360);
      const color1 = `hsl(${hue}, 70%, 40%)`; // Darker background color
      const color2 = `hsl(${(hue + 180) % 360}, 70%, 80%)`; // Brighter text color
      return [color1, 'white'];
    }
  
    // Helper function to encode SVG to Data URI
    function svgToDataURL(svg: string): string {
      const encodedSVG = encodeURIComponent(svg);
      return `data:image/svg+xml,${encodedSVG}`;
    }
  
    // Define the coin and text colors
    const [coinColor, textColor] = generateComplementaryColors();
  
    // Calculate the optimal font size for the text
    const maxTextWidth = 72; // 80% of the main circle size
    const fontSize = 100; //Math.min(Math.floor(maxTextWidth / (input.length / 2)), 20);
  
    // Generate the SVG content
    const svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="48" fill="${coinColor}" stroke="black" stroke-width="2" />
        <circle cx="50" cy="50" r="45" fill="url(#radialGradient)" />
        <circle cx="50" cy="50" r="43" fill="#00000030" stroke="white" stroke-width="0.75" />
        <defs>
        <radialGradient id="radialGradient">
            <stop offset="0%" style="stop-color:white; stop-opacity:0.65" />
            <stop offset="100%" style="stop-color:white; stop-opacity:0" />
          <animate attributeName="cx" values="-10%; 120%" dur="5s" repeatCount="indefinite" />
          <animate attributeName="cy" values="-10%; 120%" dur="5s" repeatCount="indefinite" />
          </radialGradient>
        </defs>
        <foreignObject x="14" y="20" width="72" height="60">
          <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:${fontSize}%; font-family:'Open Sans', Arial; font-weight:bold; color:${textColor}; text-align:center; word-wrap:break-word; line-height:1.1; overflow-wrap:anywhere; display:flex; justify-content:center; align-items:center; height:100%;">
            ${input}
          </div>
        </foreignObject>
      </svg>
    `;
  
    // Return the Data URI
    return svgToDataURL(svgContent);
  }


  export function darkenColor(color: string): string {
    if(!color) return '#000000';
    let r: number = 0;
    let g: number = 0;
    let b: number = 0;

    if (color.startsWith('#')) {
        // Convert hex to RGB
        const hex = color.slice(1);
        r = parseInt(hex.length === 3 ? hex[0] + hex[0] : hex.substring(0, 2), 16);
        g = parseInt(hex.length === 3 ? hex[1] + hex[1] : hex.substring(2, 4), 16);
        b = parseInt(hex.length === 3 ? hex[2] + hex[2] : hex.substring(4, 6), 16);
    } else if (color.startsWith('rgb')) {
        // Extract RGB values
        [r, g, b] = color.match(/\d+/g)?.map(Number) ?? [];
    } else if (color.startsWith('hsl')) {
        const hslValues = color.match(/\d+/g)?.map(Number);
        if (hslValues) {
          const [h, s, l] = hslValues;
          const a = s * Math.min(l, 100 - l) / 100;
          const f = (n: number) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color / 100);
          };
          r = f(0);
          g = f(8);
          b = f(4);
        } else {
          throw new Error('Invalid color format');
        }

        // Darken color by 20%
        r = Math.max(0, r - r * 0.2);
        g = Math.max(0, g - g * 0.2);
        b = Math.max(0, b - b * 0.2);
      }

        return `rgb(${r}, ${g}, ${b})`;
}
