export const MultiCalls: { [key: number]: any } = {
  1: {
    name: 'Ethereum',
    address: '0x5Eb3fa2DFECdDe21C950813C665E9364fa609bD2',
  },
  5: {
    name: 'Goerli',
    address: '0xc30FbD745B5E1e54Aa7E183DBb9B0ec22b698F14',
  },
  97: {
    name: 'BSC Testnet',
    address: '0xDFC3694e8c59D4B0C9f9727Aae39198CB0088488',
  },
  25: {
    name: 'Cronos',
    // address: '0x0fA4d452693F2f45D28c4EC4d20b236C4010dA74',
    address: '0x4BFf1bfFbB9DAAD94a81E8A1ceEc2FE3eE012AF3',
  },
  100: {
    name: 'Gnosis',
    address: '0x0e71ED327E42048E68169A9bd1fE2acbc8B1F1c9',
  },
  128: {
    name: 'Heco',
    address: '0xc9a9F768ebD123A00B52e7A0E590df2e9E998707',
  },
  137: {
    name: 'Polygon',
    address: '0x0e71ED327E42048E68169A9bd1fE2acbc8B1F1c9',
  },
  1666600000: {
    name: 'Harmony',
    address: '0xe4216984f476145D605F056f4Ba975C9a39C4c17',
  },
  250: {
    name: 'Fantom',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  369: {
    name: 'PulseChain',
    address: '0x5Eb3fa2DFECdDe21C950813C665E9364fa609bD2',
  },
  42161: {
    name: 'Arbitrum',
    // address: '0x7A7443F8c577d537f1d8cD4a629d40a3148Dd7ee',
    address: '0x18EADA3C6F820Eb6713B4123632668899104b9f9',
  },
  42220: {
    name: 'Celo',
    address: '0xA452B5dc91F1678ECA14E650d39b1cc9DF63B93a',
  },
  43114: {
    name: 'Avalanche',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  56: {
    name: 'Bsc',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  4689: {
    name: 'Iotex',
    address: '0x9a734e90d89f0c346e27c404d350ff56dead55f1',
  },
  1313161554: {
    name: 'Aurora',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  1088: {
    name: 'Metis',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  592: {
    name: 'Astar',
    address: '0xB82D81F10cae3337F2d1c513A5d854A4895c45b3',
  },
  42262: {
    name: 'Oasis',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  2001: {
    name: 'Milkomeda',
    address: '0xa048352794faCeA60dE81836Ac834f2feb9Da356',
  },
  66: {
    name: 'Okex',
    address: '0x0e71ED327E42048E68169A9bd1fE2acbc8B1F1c9',
  },
  2000: {
    name: 'DogeChain',
    address: '0x5c2b5B98738Cd08d618f4aC228736611f963F57b',
  },
  52: {
    name: 'CoinEx',
    address: '0x5c2b5B98738Cd08d618f4aC228736611f963F57b',
  },
  10001: {
    name: 'EthPoW',
    address: '0xAd46eec07713eE4CD882EC0cc4eD950f632d88b2',
  },
  421613: {
    name: 'Arbitrum Görli',
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  8453: {
    name: 'Base',
    address: '0x1B6b1d3Ca3fa22589665829EBa9599930326bA14',
  },
  109: {
    name: 'Shibarium',
    address: '0xb4227B278B296b8Ff2C04FC2fF7ED053841c1825',
  },
};

/*

updated:
4/21/2022, 1:06:22 PM : 0xB82D81F10cae3337F2d1c513A5d854A4895c45b3 : astar
4/21/2022, 12:39:59 PM : 0xa048352794faCeA60dE81836Ac834f2feb9Da356 : oasis
4/21/2022, 12:41:08 PM : 0xa048352794faCeA60dE81836Ac834f2feb9Da356 : aurora
4/21/2022, 12:41:45 PM : 0xa048352794faCeA60dE81836Ac834f2feb9Da356 : milkomeda
4/21/2022, 12:42:05 PM : 0xa048352794faCeA60dE81836Ac834f2feb9Da356 : metis
4/21/2022, 12:51:29 PM : 0x0e71ED327E42048E68169A9bd1fE2acbc8B1F1c9 : okex
4/21/2022, 12:53:29 PM : 0x0e71ED327E42048E68169A9bd1fE2acbc8B1F1c9 : gnosis
4/21/2022, 1:16:56 PM : 0xe4216984f476145D605F056f4Ba975C9a39C4c17 : harmony


needs to be updated:


*/
