import ABI from './ABI';
import type { BigNumber } from 'bignumber.js';

export interface IRelayData {
  originChainID: number;
  sender: string;
  receiver: string;
  finalToken: string;
  block: number;
  maxSlippage: number | string;
  bridgedValue: string | BigNumber;
}

export interface ICrossChainTX {
  txHash: string;
  userWallet: string;
  contract: string;
  originChainID: number;
  destChainID: number;
  transferID: string;
  topic: string[];
  data?: IRelayData;
  status?: string;
}

export interface ICrossSwapper {
  name: string;
  chainId: number;
  address: string;
  topic?: any[];
  inputs?: any[];
  startBlock?: number;
  bridgeTopic?: string[];
  nativeCoin?: string;
}

export interface ITeleSwapRouters {
  [keys: number]: ICrossSwapper;
}

export const TeleSwapRouters: ITeleSwapRouters = {
  5: {
    name: 'TESTNET ETH ERC20 CrossSwapper',
    chainId: 5,
    // address: '0x8Fb9d86626Acf2e4ED94147D43Bf0A8A8cB38780',
    //address: '0xA5774d3efE74F52869f0D53f60543749BF1969B9',
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 7839459,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
    nativeCoin: '',
  },
  97: {
    name: 'BSC Testnet CrossSwapper',
    chainId: 97,
    // address: '0x0daD977be5556e3CdA3A4A1968518210bb972faC',
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 17559860,//old
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  56: {
    name: 'BSC Smart Chain',
    chainId: 56,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 22387266,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  1: {
    name: 'ETH MainNet',
    chainId: 1,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 15801561,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  137: {
    name: 'Polygon/Matic',
    chainId: 137,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 34646358,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  43114: {
    name: 'Avalanche',
    chainId: 43114,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 21378971,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  250: {
    name: 'Fantom',
    chainId: 250,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 49739403,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  25: {
    name: 'Cronos',
    chainId: 25,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 9264354,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  369: {
    name: 'PulseChain',
    chainId: 25,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 9264354,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  100: {
    name: 'Gnosis',
    chainId: 100,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 24690642,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  66: {
    name: 'OKX',
    chainId: 66,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 14824975,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  1088: {
    name: 'Metis',
    chainId: 1088,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 3852983,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  2001: {
    name: 'Milkomeda',
    chainId: 2001,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 6512972,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  1666600000: {
    name: 'Harmony',
    chainId: 1666600000,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 32964038,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  1313161554: {
    name: 'Aurora',
    chainId: 1313161554,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 76762879,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  42262: {
    name: 'Oasis',
    chainId: 69,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 3383222,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  42220: {
    name: 'Celo',
    chainId: 42220,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 20424880,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  52: {
    name: 'CoinEx Smart Chain Mainnet',
    chainId: 52,
    address: '0x77CF963b4f76a554a80A107d2fFa368e42e4F6aC',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 0,
    //bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  592: {
    name: 'Astar',
    chainId: 592,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 0,
    bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },

  10001: {
    name: 'Ethereum PoW Mainnet',
    chainId: 10001,
    address: '0x55Fc5392bD23de608CB0eCfE77Fbf4Fc633cE541',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 0,
    //bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  42161: {
    name: 'Arbitrum One',
    chainId: 42161,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 67377246,
    //bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  8453: {
    name: 'Base',
    chainId: 8453,
    address: '0xc57D494758298E9D3D816a9b4Efe4e8A662FEbeA',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 2030913,
    //bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  },
  109: {
    name: 'Shibarium',
    chainId: 109,
    address: '0x7fC008DC9B3caD6344B6A1F0d8be388C13c78910',
    topic: ['0x233980feeb3de0826eb764c2ecfce1c79b5750901bd6eddce3f32b03059147f0'],
    inputs: [ABI.teleSwap, ABI.erc20, ABI.uniRouter],
    startBlock: 1541748,
    //bridgeTopic: ['0x79fa08de5149d912dce8e5e8da7a7c17ccdf23dd5d3bfe196802e6eb86347c7c'],
  }
};

export default TeleSwapRouters;

/*

already updated:
4/21/2022, 12:44:44 AM : 0xF13617dFe047035a790C9594dF781C2f04c18817 : bsc
4/21/2022, 12:44:44 AM : 0x18eada3c6f820eb6713b4123632668899104b9f9 : poly
4/21/2022, 12:45:16 AM : 0x1B6b1d3Ca3fa22589665829EBa9599930326bA14 : ftm
4/21/2022, 12:45:56 AM : 0x1B6b1d3Ca3fa22589665829EBa9599930326bA14 : avax
4/21/2022, 12:42:45 PM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : gnosis
4/21/2022, 12:42:05 PM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : okex
4/21/2022, 12:41:46 PM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : metis
4/21/2022, 12:41:22 PM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : milkomeda
4/21/2022, 12:39:59 PM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : harmony
4/21/2022, 12:39:02 PM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : oasis
4/21/2022, 12:45:56 AM : 0xF96FE51dFF1E7434abC68c39Bb111E83b3dE7e4d : astar




needs to be added:

*/