import { writable } from 'svelte/store';
import type BigNumber from 'bignumber.js';

interface IDEXPreload {
    spending_token_address?: string;
    spending_token_symbol?: string;
    spending_chain_id?: number;
    receiving_token_address: string;
    receiving_token_symbol: string;
    receiving_chain_id: number;
    trade_size?: BigNumber;//no decimals
    wallet_balance?: BigNumber//no decimals
}

export const DEXPreload = writable<IDEXPreload>({receiving_token_address: '', receiving_chain_id: -1, receiving_token_symbol: ''});