import type { IP2PTrade } from "../Data/P2P";
import BigNumber from "bignumber.js";
import subZero from "./helpers/subZero";
import { Wallet } from "../Data/Wallet";
import { get } from "svelte/store";
import { PLATFORM_FEE_MULTIPLIER } from "../Data/Constants";
import { addActionLogLine } from "../Data/ActionLogs";

export const getTradeAmount = (trade: IP2PTrade, position: 'top' | 'bottom') => {
    if (position == 'top')
        return trade.type == 'ONRAMP' ?
            BigNumber(trade.totalCost || 0).toFixed(2) :
            subZero(BigNumber(trade.amount || 0).toFixed(3));
    else
        return trade.type == 'ONRAMP' ?
            subZero(
                BigNumber(trade.amount || 0)
                    .times(trade.taker?.toLowerCase() == get(Wallet).address.toLowerCase() ? get(PLATFORM_FEE_MULTIPLIER) : 1)
                    .toFixed(3)
            ) :
            BigNumber(trade.totalCost || 0).toFixed(2);
}

export const getShortenedId = (trade: IP2PTrade, i?: number): string => {
    const id = get(Wallet).address.toLowerCase() == trade.maker?.toLowerCase() ? trade.venue?.takerId : trade.venue?.makerId;
    if (i) {
        return id?.split('::')[i]?.slice(0, 9) + (id?.split('::')[i]?.length || 0 > 9 ? '...' : '');
    }
    return id?.slice(0, 9) + (id?.length || 0 > 9 ? '...' : '');
};

export const getActionTitle = (trade: IP2PTrade) => {
    if (trade.rampPhase == 0 && get(Wallet).address.toLowerCase() != trade.maker?.toLowerCase()) return 'Waiting...';
    if (trade.rampPhase == 0 && get(Wallet).address.toLowerCase() == trade.maker?.toLowerCase()) return 'Accept Trade';
    if (trade.rampPhase == 1) return 'Trade Rejected';
    if (trade.rampPhase == 2 && get(Wallet).address.toLowerCase() == trade.taker?.toLowerCase()) return 'Conf. #';
    if (trade.rampPhase == 2 && get(Wallet).address.toLowerCase() == trade.maker?.toLowerCase()) return 'Complete';
    if (trade.rampPhase == 3 && trade.type == 'OFFRAMP') return 'Complete';
    if (trade.rampPhase == 3 && trade.type == 'ONRAMP' && get(Wallet).address.toLowerCase() != trade.maker?.toLowerCase()) return 'Waiting...';
    if (trade.rampPhase == 3 && trade.type == 'ONRAMP' && get(Wallet).address.toLowerCase() == trade.maker?.toLowerCase()) return 'Complete';
    if (trade.rampPhase == 4) return 'Trade Complete';
    if (trade.rampPhase == 5) return 'Cancelled';

    return 'Waiting...';
};

export const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    addActionLogLine(`Copied to clipboard!`);
};

export const getPhaseProgress = (trade: IP2PTrade): number => {
    if (trade.rampPhase == 0) return 20;
    if (trade.rampPhase == 1) return 100;
    if (trade.rampPhase == 2) return 35;
    if (trade.rampPhase == 3) return 80;
    if (trade.rampPhase == 4) return 100;
    if (trade.rampPhase == 5) return 100;

    return 100;
};

export const getAmountTooltip = (trade: IP2PTrade): string => {
    if (get(Wallet).address.toLowerCase() == trade.maker?.toLowerCase()) {
        const totalAmount = BigNumber(trade.amount || 0).toFixed(6);
        const platformFee = BigNumber(trade.amount || 0)
            .times(1 - get(PLATFORM_FEE_MULTIPLIER))
            .toFixed(6);
        const userReceives = BigNumber(trade.amount || 0)
            .minus(BigNumber(trade.amount || 0).times(1 - get(PLATFORM_FEE_MULTIPLIER)))
            .toFixed(6);
        const party = trade.type == "OFFRAMP" ? "You" : "User";
        return `Total amount: \n${totalAmount} ${trade.asset.symbol}\nPlatform Fee: \n${platformFee} ${trade.asset.symbol}\n${party} Receives: \n${userReceives} ${trade.asset.symbol}`;
    } else {
        return BigNumber(trade.amount || 0).toFixed();
    }
};

export const isMaker = (trade: IP2PTrade) => {
    return trade.maker?.toLowerCase() === get(Wallet).address.toLowerCase();
};

export const isTaker = (order: IP2PTrade) => {
    return !isMaker(order);
};