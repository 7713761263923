import axios from "axios"

const serverURL = `${import.meta.env.VITE_SKIPSCAN_SERVER_URL}/get-provider-time-range`

export const fetchProviderHours = async (address: string, returnFormattedString = true): Promise<string | Date[]> => {
    console.log('fetching provider hours');
    const params = {address: address}
    const resp = await axios.get('https://skipscan.win/api/get-provider-time-range', {
        params: params
    });

    console.log('provider hours resp', resp.data);

    if(resp.data[0] == 0 && resp.data[1] == 0) return '';

    const startHour = resp.data[0];
    const endHour = resp.data[1];

    const startDate = new Date();
    startDate.setUTCHours(startHour, 0, 0, 0);
    const endDate = new Date();
    endDate.setUTCHours(endHour, 0, 0, 0);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('provider hours timezone', timeZone);

    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: timeZone, timeZoneName: 'short' };
    const startLocal = startDate.toLocaleTimeString(undefined, options);
    const endLocal = endDate.toLocaleTimeString(undefined, options);

    return returnFormattedString ? `${startLocal} - ${endLocal}` : [startDate, endDate];
}