<script lang="ts">
    import { PageTexts, Locale } from '../../Data/Locale';

    interface IProductText {
        [key: string]: string;
    }

    let texts: IProductText;
    let titles: IProductText;

    $: {
        texts = {
            global: PageTexts[$Locale].global_payment_methods_desc,
            liquidity: PageTexts[$Locale].liquidity_provision_desc,
            risk: PageTexts[$Locale].risk_mitigation_desc,
            toolkit: PageTexts[$Locale].toolkit_desc
        };

        titles = {
            global: PageTexts[$Locale].global_payment_methods,
            liquidity: PageTexts[$Locale].liquidity_provision,
            risk: PageTexts[$Locale].risk_mitigation,
            toolkit: PageTexts[$Locale].toolkit
        };
    }

    const pics: IProductText = {
        global: `/images/methods.svg`,
        liquidity: `/images/liquidity.svg`,
        risk: `/images/risk.svg`,
        toolkit: `/images/toolkit.svg`
    };

    let selectedText = 'global';
    let selectedImage: string;
</script>

<div class="products items-start">
    <div class="flex flex-col flex-1 items-center">
        <h1 class=" text-4xl md:text-[3.25rem] font-semibold mb-6">
            {PageTexts[$Locale].what_weve_built}
        </h1>
        <h2 class=" text-lg mb-8 text-center max-w-[70ch]">
            {PageTexts[$Locale].products_desc}
        </h2>
    </div>

    <div class="flex items-center font-bold">
        {#each Object.keys(titles) as item}
            <button on:click={() => (selectedText = item)} class="px-1 md:px-4 text-xs md:text-base menu-item" class:active={selectedText !== item}
                >{titles[item]}</button>
        {/each}
    </div>

    <div class="content">
        <div class="md:flex w-full md:w-auto grow mb-6 md:mb-0">
            <img class="placeholder max-w-[80%] md:max-w-[350px] shadow" src={pics[selectedText]} alt="pic" />
        </div>
        <div class="md:flex md:flex-col md:grow md:max-w-[75%]">
            <div class="text-4xl md:text-4xl text-center md:text-right pb-4 font-bold">{titles[selectedText]}</div>
            <div class="pt-2 max-w-[65ch]">{texts[selectedText]}</div>
        </div>
    </div>
</div>

<style lang="scss">
    .products {
        @apply py-12  mx-auto flex-1 flex flex-col items-center;
        min-height: 700px;
    }

    .content {
        @apply md:flex md:gap-12 mt-12 md:items-center min-h-[20rem];
    }

    img.shadow {
        -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(5px 5px 2px rgb(0 0 0 / 70%));
    }
</style>
