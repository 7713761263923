<script lang="ts">
  import { PageTexts, Locale } from "../../Data/Locale";
</script>

<a
  href="https://skipshift.io/#/integrate"
  target="_blank"
  class="p-3 bubble-border border border-fuchsia-300 text-black hover:text-white hover:bg-fuchsia-500"
  >
  <span class="mr-2 text-fuchsia-300">{PageTexts[$Locale].integrate}</span>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_313_226)">
      <path
        d="M11.9923 0.274439C12.1681 0.098716 12.4064 0 12.655 0C12.9035 0 13.1419 0.098716 13.3177 0.274439L14.7265 1.68326C14.9022 1.85903 15.0009 2.09741 15.0009 2.34595C15.0009 2.5945 14.9022 2.83287 14.7265 3.00865L13.3177 4.41747C13.1419 4.59319 12.9035 4.69191 12.655 4.69191C12.4064 4.69191 12.1681 4.59319 11.9923 4.41747L10.5825 3.00865C10.4068 2.83287 10.3081 2.5945 10.3081 2.34595C10.3081 2.09741 10.4068 1.85903 10.5825 1.68326L11.9914 0.274439H11.9923ZM2.34334 15H3.74935V11.2507H0V12.6567C0 13.2782 0.246887 13.8742 0.686349 14.3137C1.12581 14.7531 1.72185 15 2.34334 15ZM3.74935 6.56397V10.3133H0V6.56397H3.74935ZM8.43603 10.3133H4.68668V6.56397H8.43603V10.3133ZM4.68668 11.2507H8.43603V15H4.68668V11.2507ZM9.37337 6.56397V10.3133H13.1227V6.56397H9.37337ZM9.37337 11.2507H13.1227V12.6567C13.1227 13.2782 12.8758 13.8742 12.4364 14.3137C11.9969 14.7531 11.4009 15 10.7794 15H9.37337V11.2507ZM0 5.62663H3.74935V1.87729H2.34334C1.72185 1.87729 1.12581 2.12417 0.686349 2.56363C0.246887 3.0031 0 3.59913 0 4.22063V5.62663ZM4.68668 5.62663V1.87729H8.43603V5.62663H4.68668Z"
        fill="white"></path>
    </g>
    <defs>
      <clipPath id="clip0_313_226">
        <rect width="15" height="15" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</a>

<style lang="scss">
  a {
    @apply py-4 flex items-center justify-center transition-colors duration-300 cursor-pointer md:mr-8;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    a {
      width: 200px;
    }
  }
</style>