import { writable } from 'svelte/store';

interface UserSettings {
    autoCollatteral: boolean;
    locale: string;
    showInactive: boolean;
    providerMode: boolean;
    preferredNetwork: string;
}

// Retrieve the initial value from localStorage or use default values

function createSettingsStore() {
    console.log('creating settings store');
    let params = localStorage.getItem('userSettings');
    if (!params) {
        console.log('no user settings found, using defaults');
        params = JSON.stringify({
            autoCollatteral: false,
            locale: 'en',
            showInactive: false,
            providerMode: false,
            preferredNetwork: 'eth'
        });
    }
    const initialSettings: UserSettings = JSON.parse(params);
    initialSettings.showInactive = false;

    const { subscribe, set, update } = writable<UserSettings>(initialSettings);

    return {
        subscribe,
        set: (value: UserSettings) => {
            localStorage.setItem('userSettings', JSON.stringify(value));
            set(value);
        },
        update: (updater: (value: UserSettings) => UserSettings) => {
            update((current) => {
                const updated = updater(current);
                localStorage.setItem('userSettings', JSON.stringify(updated));
                return updated;
            });
        }
    };
}

export const USER_SETTINGS = createSettingsStore();
