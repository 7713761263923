<script lang="ts">
    import { onDestroy, onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let count = 5;
    let width = 100; // Starting width for the color overlay
    let interval: string | number | NodeJS.Timer | undefined;
    let reset = false;

    onMount(() => {
        interval = setInterval(() => {
            if (count > 0) {
                count -= 1;
                width -= 20; // Decrease width by 20% each second
            } else {
                console.log('clearing interval');
                clearInterval(interval);
                dispatch('close');
            }
        }, 1000);
    });

    onDestroy(() => {
        clearInterval(interval);
    });

    const closeModal = () => {
        count = 0;
        clearInterval(interval);
        dispatch('close');
    };

    const resetCount = () => {
        reset = true;
        width = 100;
        clearInterval(interval);
    };
</script>

<button class="countdown-btn" on:click|preventDefault|stopPropagation={closeModal}>
    {#if !reset}
        <div class="overlay" style="--width: {width};"></div>
        <div class="relative">Closing in {count}</div>
    {:else}
        <div class="overlay" style="--width: {width};"></div>
        <div class="relative">Close</div>
    {/if}
    <!-- <div class="relative">Closing in {count}</div> -->
</button>
<button id="walletModalCountdown" on:click|preventDefault|stopPropagation={resetCount} class:hidden={reset} class="closeCountdown"><i class="fas fa-times-circle" style="color: #ff2929;"></i></button>

<style lang="scss">
    .closeCountdown {
        color: #ff2929;
        position: relative;
        align-self: end;
        margin-bottom: 1rem;
    }
    .countdown-btn {
        position: relative;
        width: 100%;
        /* height: 50px; */
        max-height: 2rem;
        padding: 0.25rem 0rem;
        /* line-height: 50px; */
        color: white;
        border: 0px solid transparent;
        cursor: pointer;
        overflow: hidden;
        background-color: #2943a4; /* Background color */
        border-radius: 25px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #07143057; /* Foreground color */
        width: calc(var(--width, 100%) * 1%); /* Start from full width */
        transition: width 1s linear;
        border-radius: 50px;
    }
</style>
