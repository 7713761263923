import type { IToken } from "./Tokens";


export interface IRBXTokens {
  [key: number]: IToken;
}

export const RBXTokens: IRBXTokens = {
  1: {
    address: '0x8254e26e453EB5aBd29B3c37AC9E8Da32E5d3299',
    decimals: 18,
    logoURI: '/images/rbx.png',
    symbol: 'RBX',
  },
  56: {
    address: '0xaCE3574B8b054E074473a9Bd002e5dC6dd3dfF1B',
    decimals: 18,
    logoURI: '/images/rbx.png',
    symbol: 'RBX',
  },
  43114: {
    address: '0x94960952876e3ED6A7760B198354fCc5319A406a',
    decimals: 18,
    logoURI: '/images/rbx.png',
    symbol: 'RBX',
  },
  //PNUTS FOR TESTNET
  5: {
    address: '0x2ae279B61112f0c8D9784cc37761731b6a4e6fFd',
    decimals: 18,
    logoURI: '/images/rbx.png',
    symbol: 'PNUTS',
  },
};

export const supportedChains = [1, 56, 43114, 369, 109];