<script lang="ts">
    import { PageTexts, Locale } from '../../Data/Locale';
</script>

<div class="partners">
    <div class="flex flex-col flex-1">
        <h1 class=" text-4xl md:text-[3.25rem] font-semibold mb-6">
            {PageTexts[$Locale].partners_title}
        </h1>
        <h2 class=" text-lg mb-8 max-w-[70ch] text-center">
            {PageTexts[$Locale].partners_desc}
        </h2>
    </div>
    <div class="partner-container flex-wrap">
        <div class="partner-frame">
            <a href="https://gempad.app">
                <img src="images/tokens/gempad.svg" alt="Gempad" />
            </a>
        </div>
        <div class="partner-frame">
            <a href="https://transfermole.com">
                <img src="images/tokens/transfermole2.png" alt="TransferMole" />
            </a>
        </div>
        <div class="partner-frame">
            <a href="https://rbxdefi.com">
                <img src="images/tokens/rbx.png" alt="RBX" />
            </a>
        </div>
    </div>
    <div class="partner-frame text-4xl flex pt-4">
        <div class="self-center italic font w-full">
            {PageTexts[$Locale].and_more}
        </div>
    </div>
</div>

<style lang="scss">
    .partner-container {
        @apply flex items-center justify-center;
    }
    .partner-frame {
        @apply w-[8em] md:w-[12em] px-6 justify-center text-center;
    }

    .partners {
        @apply px-4 py-6 md:px-16 md:py-24 w-full flex-1 flex flex-col items-center;
    }
</style>
