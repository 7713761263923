import { get, writable } from 'svelte/store';
import { P2P_ASSETS } from './LocalStorageItems';
import type { IToken } from './Tokens';
import { ChainId } from './Wallet';

const loadAndMerge = () => {
    const chainId = get(ChainId);
    const mem = JSON.parse(localStorage.getItem(P2P_ASSETS + chainId) || '[]') as { timestamp: number; chain: number; data: IToken[] };
    // localStorage.setItem(P2P_STORE, JSON.stringify(mem, null, 2))
    // P2PCache.set(mem);
    return mem.data;
};

export const p2pAssets = (p2pInfo: IToken[]) => {
    const chainId = get(ChainId);
    const toStore = { timestamp: Date.now(), chain: chainId, data: p2pInfo || [] };
    if ((localStorage.getItem(P2P_ASSETS + chainId) === null || localStorage.getItem(P2P_ASSETS + chainId) == '[]') && p2pInfo?.length > 0)
        localStorage.setItem(P2P_ASSETS + chainId, JSON.stringify(toStore));

    const storedValue = localStorage.getItem(P2P_ASSETS + chainId);
    const { subscribe, set, update } = writable(storedValue ? JSON.parse(storedValue) : null);

    return {
        subscribe,
        set: (data: IToken[]) => {
            const chainId = get(ChainId);
            const toStore = { timestamp: Date.now(), chain: chainId, data: data };
            localStorage.setItem(P2P_ASSETS + chainId, JSON.stringify(toStore)); // save also to local storage as a string
            return set(toStore);
        },
        update: (incomingData: IToken[]) => {
            return set(incomingData);
        }
    };
};

export const P2PAssets = p2pAssets(loadAndMerge());
