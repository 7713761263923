export function createLogPanel() {
    const container = document.createElement('div');
    const button = document.createElement('button');
    const logPanel = document.createElement('div');
    const logContainer = document.createElement('div');
    const clearButton = document.createElement('button');
    const scrollToBottomButton = document.createElement('button');
    const toggleWrapButton = document.createElement('button');
    const searchInput = document.createElement('input');

    // Style the button, clear button, scroll to bottom button, and log panel
    button.textContent = 'Logs';
    button.style.position = 'fixed';
    button.style.background = 'skyblue';
    button.style.borderRadius = '10px';
    button.style.bottom = '20px';
    button.style.right = '20px';
    button.style.zIndex = '1000';
    button.style.padding = '5px 10px';

    clearButton.textContent = 'Clear';
    clearButton.style.position = 'fixed';
    clearButton.style.background = 'skyblue';
    clearButton.style.borderRadius = '10px';
    clearButton.style.bottom = '20px';
    clearButton.style.right = '100px';
    clearButton.style.zIndex = '1000';
    clearButton.style.padding = '5px 10px';

    scrollToBottomButton.textContent = '↓';
    scrollToBottomButton.style.position = 'fixed';
    scrollToBottomButton.style.bottom = '10%';
    scrollToBottomButton.style.right = '10%';
    scrollToBottomButton.style.zIndex = '1000';
    scrollToBottomButton.style.display = 'none';
    scrollToBottomButton.style.background = 'skyblue';
    scrollToBottomButton.style.width = '30px';
    scrollToBottomButton.style.height = '30px';
    scrollToBottomButton.style.borderRadius = '50%';
    scrollToBottomButton.style.border = '3px double black';

    toggleWrapButton.textContent = 'Wrap';
    toggleWrapButton.style.position = 'fixed';
    toggleWrapButton.style.bottom = '20px';
    toggleWrapButton.style.right = '180px';
    toggleWrapButton.style.zIndex = '1000';
    toggleWrapButton.style.background = 'skyblue';
    toggleWrapButton.style.width = 'auto';
    toggleWrapButton.style.borderRadius = '10px';
    // toggleWrapButton.style.border = '2px solid black';
    toggleWrapButton.style.padding = '5px 10px';

    searchInput.type = 'text';
    searchInput.placeholder = 'Search logs...';
    searchInput.style.width = '100%';
    searchInput.style.boxSizing = 'border-box';
    searchInput.style.marginTop = '10px';

    logPanel.style.display = 'none';
    logPanel.style.position = 'fixed';
    logPanel.style.bottom = '60px';
    logPanel.style.right = '20px';
    logPanel.style.width = '300px';
    logPanel.style.height = '400px';
    logPanel.style.overflow = 'auto';
    logPanel.style.background = 'black';
    logPanel.style.border = '1px solid black';
    logPanel.style.borderRadius = '10px';
    logPanel.style.zIndex = '999';
    logPanel.style.fontSize = '10px';
    logPanel.style.whiteSpace = 'nowrap';

    logContainer.style.padding = '10px';

    // Append elements to the document body
    document.body.appendChild(button);
    document.body.appendChild(clearButton);
    document.body.appendChild(scrollToBottomButton);
    document.body.appendChild(toggleWrapButton);
    logPanel.appendChild(logContainer);
    logPanel.appendChild(searchInput);
    document.body.appendChild(logPanel);

    // Toggle visibility of the log panel on button click
    button.onclick = function () {
        logPanel.style.display = logPanel.style.display === 'none' ? 'block' : 'none';
        if (logPanel.style.display === 'block') {
            logPanel.scrollTop = logPanel.scrollHeight;
        }
    };

    // Clear the log panel content on clear button click
    clearButton.onclick = function () {
        logContainer.innerHTML = '';
    };

    // Scroll to bottom button click event
    scrollToBottomButton.onclick = function () {
        logPanel.scrollTop = logPanel.scrollHeight;
    };

    // Show/Hide scroll to bottom button based on scroll position
    logPanel.onscroll = function () {
        if (logPanel.scrollTop + logPanel.clientHeight >= logPanel.scrollHeight) {
            scrollToBottomButton.style.display = 'none';
        } else {
            scrollToBottomButton.style.display = 'block';
        }
    };

    // Toggle text wrapping
    toggleWrapButton.onclick = function () {
        logContainer.style.whiteSpace = logContainer.style.whiteSpace === 'nowrap' ? 'normal' : 'nowrap';
    };

    // Filter logs based on search input
    searchInput.oninput = function () {
        const filter = searchInput.value.toLowerCase();
        const logEntries = logContainer.querySelectorAll('div');
        logEntries.forEach((entry) => {
            if (entry.textContent?.toLowerCase().includes(filter)) {
                entry.style.display = '';
            } else {
                entry.style.display = 'none';
            }
        });
    };

    // Generate a random color for each log entry
    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    // Override console.log to handle objects
    const oldLog = console.log;
    console.log = function (...messages) {
        oldLog.apply(console, messages);
        const formattedMessages = messages.map((m) => {
            const color = getRandomColor();
            if (typeof m === 'object') {
                try {
                    return `<div style="color:${color};">${JSON.stringify(m, null, 2)}</div>`;
                } catch (error) {
                    return `<div style="color:${color};">Error in object serialization: ${error?.message}</div>`;
                }
            } else {
                return `<div style="color:${color};">${m?.toString() || ''}</div>`;
            }
        });
        logContainer.innerHTML += formattedMessages.join('') + '<br>';
        logPanel.scrollTop = logPanel.scrollHeight;
    };

    return {
        log: console.log,
        element: logPanel,
    };
}