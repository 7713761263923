import { EventEmitter } from 'eventemitter3';

const DEFAULT_TARGET_ORIGIN = '*';
const DEFAULT_TIMEOUT_MILLISECONDS = 60000;
const JSON_RPC_VERSION = '2.0';

// Interface for the source of events, typically the window.
export interface MinimalEventSourceInterface {
  addEventListener(eventType: 'message', handler: (message: MessageEvent) => void): void;
}

// Interface for the target of events, typically the parent window.
export interface MinimalEventTargetInterface {
  postMessage(message: any, targetOrigin?: string): void;
}

/**
 * Options for constructing the iframe Ethereum provider.
 */
interface IFrameEthereumProviderOptions {
  targetOrigin?: string;
  timeoutMilliseconds?: number;
  eventSource?: MinimalEventSourceInterface;
  eventTarget?: MinimalEventTargetInterface;
}

/**
 * Interface to keep track of pending promises.
 */
interface PromiseCompleter<TResult, TErrorData> {
  resolve(result: JsonRpcSuccessResponseMessage<TResult> | JsonRpcErrorResponseMessage<TErrorData>): void;
  reject(error: Error): void;
}

type MessageId = number | string | null;

interface JsonRpcRequestMessage<TParams = any> {
  jsonrpc: '2.0';
  id?: MessageId;
  method: string;
  params?: TParams;
}

interface BaseJsonRpcResponseMessage {
  id: MessageId;
  jsonrpc: '2.0';
}

interface JsonRpcSuccessResponseMessage<TResult = any> extends BaseJsonRpcResponseMessage {
  result: TResult;
}

interface JsonRpcError<TData = any> {
  code: number;
  message: string;
  data?: TData;
}

interface JsonRpcErrorResponseMessage<TErrorData = any> extends BaseJsonRpcResponseMessage {
  error: JsonRpcError<TErrorData>;
}

type ReceivedMessageType =
  | JsonRpcRequestMessage
  | JsonRpcErrorResponseMessage
  | JsonRpcSuccessResponseMessage;

/**
 * Generates a unique identifier.
 */
function getUniqueId(): number {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}

export type IFrameEthereumProviderEventTypes =
  | 'connect'
  | 'close'
  | 'notification'
  | 'chainChanged'
  | 'networkChanged'
  | 'accountsChanged'
  | string; // Allow custom event types

/**
 * Interface for event handling in IFrameEthereumProvider.
 */
export interface IFrameEthereumProvider {
  on(event: 'connect', handler: () => void): this;
  on(event: 'close', handler: (code: number, reason: string) => void): this;
  on(event: 'notification', handler: (result: any) => void): this;
  on(event: 'chainChanged', handler: (chainId: string) => void): this;
  on(event: 'networkChanged', handler: (networkId: string) => void): this;
  on(event: 'accountsChanged', handler: (accounts: string[]) => void): this;
  on(event: string, handler: (...args: any[]) => void): this; // For custom events
}

/**
 * Represents an error in an RPC response.
 */
export class RpcError extends Error {
  public readonly isRpcError = true;
  public readonly code: number;
  public readonly data?: any;

  constructor(code: number, message: string, data?: any) {
    super(message);
    this.code = code;
    this.data = data;

    // Maintain proper stack trace (only available on V8 engines like Chrome and Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RpcError);
    }
  }

  public toJSON(): { code: number; message: string; data?: any } {
    return {
      code: this.code,
      message: this.message,
      data: this.data,
    };
  }
}


/**
 * The main IFrameEthereumProvider class.
 */
export class IFrameEthereumProvider extends EventEmitter<IFrameEthereumProviderEventTypes> {
  public get isIFrame(): true {
    return true;
  }

  public get currentProvider(): IFrameEthereumProvider {
    return this;
  }

  private enabled: Promise<string[]> | null = null;
  private readonly targetOrigin: string;
  private readonly timeoutMilliseconds: number;
  private readonly eventSource: MinimalEventSourceInterface;
  private readonly eventTarget: MinimalEventTargetInterface;
  private readonly providerUUID?: string;
  private readonly completers: Record<string, PromiseCompleter<any, any>> = {};

  public constructor(providerUUID?: string, options: IFrameEthereumProviderOptions = {}) {
    super();

    const {
      targetOrigin = DEFAULT_TARGET_ORIGIN,
      timeoutMilliseconds = DEFAULT_TIMEOUT_MILLISECONDS,
      eventSource = window,
      eventTarget = window.parent,
    } = options;

    this.targetOrigin = targetOrigin;
    this.timeoutMilliseconds = timeoutMilliseconds;
    this.eventSource = eventSource;
    this.eventTarget = eventTarget;
    this.providerUUID = providerUUID;

    this.eventSource.addEventListener('message', this.handleEventSourceMessage);
  }

  /**
   * Connect to the provider from the parent window.
   */
  public requestConnection(): void {
    this.eventTarget.postMessage({ method: 'targetProvider', uuid: this.providerUUID }, this.targetOrigin);
  }

  /**
   * Get the list of available wallets from the parent.
   */
  public async getWallets(): Promise<any[]> {
    return this.send<any[], any[]>('getWallets');
  }

  /**
   * Helper method that handles transport and request wrapping.
   * @param method - Method to execute.
   * @param params - Params to pass to the method.
   */
  private async execute<TParams, TResult, TErrorData>(
    method: string,
    params?: TParams,
    requestId?: MessageId
  ): Promise<JsonRpcSuccessResponseMessage<TResult> | JsonRpcErrorResponseMessage<TErrorData>> {
    const id = requestId !== undefined && requestId !== null ? requestId : getUniqueId();
    const payload: JsonRpcRequestMessage = {
      jsonrpc: JSON_RPC_VERSION,
      id,
      method,
      ...(params !== undefined ? { params } : {}),
    };

    const promise = new Promise<
      JsonRpcSuccessResponseMessage<TResult> | JsonRpcErrorResponseMessage<TErrorData>
    >((resolve, reject) => {
      this.completers[String(id)] = { resolve, reject };
    });

    this.eventTarget.postMessage(payload, this.targetOrigin);

    setTimeout(() => {
      if (this.completers[String(id)]) {
        this.completers[String(id)].reject(
          new Error(`RPC ID "${id}" timed out after ${this.timeoutMilliseconds} milliseconds`)
        );
        delete this.completers[String(id)];
      }
    }, this.timeoutMilliseconds);

    return promise;
  }

  /**
   * Sends a JSON RPC and returns the result.
   * @param method - Method to send to the parent provider.
   * @param params - Parameters to send.
   */
  public async send<TParams = any[], TResult = any>(method: string, params?: TParams): Promise<TResult> {
    const response = await this.execute<TParams, TResult, any>(method, params);

    if ('error' in response) {
      throw new RpcError(response.error.code, response.error.message, response.error.data);
    } else {
      return response.result;
    }
  }

  /**
   * Requests the parent window to enable access to the user's web3 provider.
   */
  public async enable(): Promise<string[]> {
    if (this.enabled === null) {
      const promise = this.send<string[], string[]>('enable')
        .then((accounts) => {
          this.enabled = Promise.resolve(accounts);
          return accounts;
        })
        .catch((error) => {
          if (this.enabled === promise) {
            this.enabled = null;
          }
          throw error;
        });
      this.enabled = promise;
    }
    return this.enabled;
  }

  /**
   * Backwards compatibility method for web3.
   * @param payload - Payload to send to the provider.
   * @param callback - Callback to be called when the provider resolves.
   */
  public async sendAsync(
    payload: { method: string; params?: any[]; id?: MessageId },
    callback: (error: Error | null, result?: any) => void
  ): Promise<void> {
    try {
      const response = await this.execute(payload.method, payload.params, payload.id);
      if ('error' in response) {
        callback(new RpcError(response.error.code, response.error.message, response.error.data));
      } else {
        callback(null, response.result);
      }
    } catch (error) {
      callback(error as Error);
    }
  }

  /**
   * EIP-1193 compliant request method.
   * @param args - JSON-RPC request arguments.
   */
  public async request<T = any>(args: { method: string; params?: unknown[] | object }): Promise<T> {
    const { method, params } = args;

    if (method === 'eth_requestAccounts') {
      if (this.enabled === null) {
        const promise = this.send<string[], T>(method, params as any)
          .then((accounts) => {
            this.enabled = Promise.resolve(accounts);
            return accounts;
          })
          .catch((error) => {
            if (this.enabled === promise) {
              this.enabled = null;
            }
            throw error;
          });
        this.enabled = promise;
      }
      return this.enabled as Promise<T>;
    } else {
      return this.send<any, T>(method, params as any);
    }
  }

  /**
   * Handles messages from the event source.
   * @param event - Message event to be processed by the provider.
   */
  private handleEventSourceMessage = (event: MessageEvent) => {
    const data = event.data;
    if (!data) {
      return;
    }

    const message = data as ReceivedMessageType;

    if (message.jsonrpc !== JSON_RPC_VERSION) {
      return;
    }

    // If the message has an ID, it is possibly a response message
    if (typeof message.id !== 'undefined' && message.id !== null) {
      const completer = this.completers[String(message.id)];
      if (completer) {
        if ('error' in message || 'result' in message) {
          completer.resolve(message);
        } else {
          completer.reject(new Error('Response from provider did not have error or result key'));
        }
        delete this.completers[String(message.id)];
      }
    }

    // If the method is a request from the parent window, it is likely a subscription.
    if ('method' in message) {
      switch (message.method) {
        case 'notification':
          this.emit('notification', message.params);
          break;
        case 'connect':
          this.emitConnect();
          break;
        case 'close':
          if (Array.isArray(message.params)) {
            this.emit('close', message.params[0], message.params[1]);
          }
          break;
        case 'chainChanged':
          if (Array.isArray(message.params)) {
            this.emit('chainChanged', message.params[0]);
          }
          break;
        case 'networkChanged':
          if (Array.isArray(message.params)) {
            this.emit('networkChanged', message.params[0]);
          }
          break;
        case 'accountsChanged':
          if (Array.isArray(message.params)) {
            this.emitAccountsChanged(message.params[0]);
          }
          break;
        case 'message':
          this.emit('message', message.params);
          break;
        default:
          this.emit(message.method, message.params);
          break;
      }
    }
  };

  private emitConnect(): void {
    if (this.enabled === null) {
      this.enabled = Promise.resolve([]);
    }
    this.emit('connect');
  }

  private emitAccountsChanged(accounts: string[]): void {
    this.enabled = Promise.resolve(accounts);
    this.emit('accountsChanged', accounts);
  }
}

/**
 * Returns true if the current window context appears to be embedded within an iframe element.
 */
export function isEmbeddedInIFrame(): boolean {
  return window && window.parent && window.self && window.parent !== window.self;
}
