// import yahooFinance from 'yahoo-finance2';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import { CurrencyRates } from '../../Data/CurrencyRates';

const currencyAPIs: {[key: string]: string} = {
  'ARS': 'https://api.bluelytics.com.ar/v2/latest',
}

export const getRate = async (currency: string):Promise<BigNumber> => {
  let rate: number = 0;
  let fallback = false;


  if(currency == 'USD') return BigNumber(1);


  try {
    const rates = await axios.get('https://skipscan.win/api/get-fx-rates');

    console.log('skipscan fx rates', rates.data);

    CurrencyRates.set(rates.data);
  }catch(e){
    console.error('skipscan fx rates failed!');
    fallback = true;
  }

  if(fallback){
    try {
      const rates = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');

      rate = rates.data.rates[currency];

      console.log('rates', rates.data.rates['ARS']);

      const currenciesToPatch = Object.keys(currencyAPIs);
      for (let i = 0; i < currenciesToPatch.length; i++) {
        const patched = await getPatchedRate(currenciesToPatch[i]);
        rates.data.rates[currenciesToPatch[i]] = patched;
      }

      console.log('rates', rates.data.rates);

      CurrencyRates.set(rates.data.rates);
    } catch (e) {
      fallback = true;
    }
  }

  return BigNumber(rate);
}

const getPatchedRate = async (currency: string) => {
  const resp = await axios.get(currencyAPIs[currency]);
  if(currency == 'ARS') return resp.data.blue.value_avg;
}


// getRate('JPY');