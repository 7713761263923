import axios from "axios";
import type { IP2PTrade } from "Data/P2P";

const serverURL = `${import.meta.env.VITE_SKIPSCAN_SERVER_URL}/get-user-trade-counts`
interface TradeCountsResponse {
    active: Record<string, number>;
    inactive: Record<string, number>;
}

export const fetchAddressTrades = async (address: string): Promise<TradeCountsResponse | undefined> => {
    const resp = await axios.get('https://skipscan.win/api/get-user-trade-counts', {//todo: use the fucken env
        params: {
            address: address,
        }
    });

    console.log('fetchAddressTrades response data', resp.data);

    if(resp) return resp.data;
    else return;
}