import { P2PDataStore } from '../../Data/P2PDataStore';
import { IRawListing } from '../../Data/P2P';
import BigNumber from 'bignumber.js';
import { get } from 'svelte/store';

export type MinMax = {
    min: BigNumber;
    max: BigNumber;
};

export type Result = {
    [key: string]: MinMax;
};

export const aggregateMinMax = (data: IRawListing[]): Result => {
    const result: Result = {};

    data.forEach(({ currency, asset, min, max, type, maker }) => {
        // console.log('maker', maker, 'min', min, 'max', max);
        if(['0x9b824F4b003A301C1496B2d924Fb773B3a15123C'].includes(maker)) return;
        const key = `${currency}-${asset.address}-${asset.chainId}-${type}`;
        const minBN = new BigNumber(min);
        const maxBN = new BigNumber(max);

        if (!result[key]) {
            result[key] = { min: minBN, max: maxBN };
        } else {
            result[key].max = BigNumber.max(result[key].max, maxBN);
            result[key].min = BigNumber.min(result[key].min, minBN);
        }
    });

    return result;
};

export const getMinMax = (currency: string, asset: string, chainId: number, type: string): MinMax => {
    console.log('getMinMax: ', currency, asset, chainId, type);
    type = type === 'BUY' ? '1' : '0';
    const key = `${currency}-${asset}-${chainId}-${type}`;
    const key2rev = `${asset}-${currency}-${chainId}-${type}`;
    const result = get(P2PDataStore)?.minMax[key] || get(P2PDataStore)?.minMax[key2rev];
    console.log('getMinMax: ', result, key, key2rev);
    if (!result) return { min: new BigNumber(0), max: new BigNumber(0) };
    result.min = BigNumber(result?.min || 0);
    result.max = BigNumber(result?.max || 0);
    return result || { min: new BigNumber(0), max: new BigNumber(0) };
}


// Example usage:
// const data: IRawListing[] = [/* array of your data */];
// const result = aggregateMinMax(data);
// console.log(result);