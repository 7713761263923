import { web } from '../../Data/Web3Store';
import { get } from 'svelte/store';
import { Chains } from '../../Data/Chains';
import { Tokens } from '../../Data/Tokens';
import Web3 from 'web3';
import axios from 'axios';
import { generateCoinSVG } from '../utils/tokenImagerator';
import Contracts from '../../Data/Contracts';
import type { IToken } from '../../Data/Tokens';
import pMemoize from 'p-memoize';

/**
 * Retrieves the token logo URL based on the address, chainId, symbol, and optional URL.
 * 
 * @param address - The address of the token.
 * @param chainId - The chainId to which the token belongs.
 * @param symbol - The symbol of the token.
 * @param url - Optional URL to fetch the token logo.
 * 
 * @returns A URL pointing to the token logo.
 */
async function getTokenLogoDirect(address: string, chainId: number, symbol: string, url?: string): Promise<string> {
  if (symbol == 'USDT' || symbol == 'USDt') return '/images/coins/USDT.svg';
  // If URL is provided, try fetching it first.
  //make sure the url isnt just mysteryToken png data 
  if (url && !url.includes('data:image')) {
    try {
      const res = await axios.get(url);
      if (res.status === 200) return url;
    } catch { }
  }

  if (!symbol) return generateCoinSVG('TKN');
  if(address.toLowerCase() == Contracts.BASE.toLowerCase()) return generateCoinSVG('NTV');

  const checksummed = new Web3().utils.toChecksumAddress(address);
  const tokens = get(Tokens);
  let logo = buildLogoURL(checksummed, chainId);

  const matchingToken = tokens.find((token: IToken) =>
    token?.address?.toLowerCase() === address.toLowerCase() && token?.chainId == chainId
  );

  const backupImg = matchingToken?.logoURI;

  if (logo) try {
    const response = await fetch(logo);
    if (response.status === 200) return logo;
  } catch { }

  if (backupImg) try {
    const backupResponse = await fetch(backupImg);
    if (backupResponse.status === 200) return backupImg;
  } catch {
    return generateCoinSVG(symbol) || '/images/mysterytoken1.png';
  }


  return generateCoinSVG(symbol) || '/images/mysterytoken1.png';
}

export const getTokenLogo = pMemoize(getTokenLogoDirect, { cacheKey: arguments_ => arguments_.join(',')});

/**
 * Builds the logo URL based on the checksummed address and chainId.
 * 
 * @param checksummed - The checksummed address of the token.
 * @param chainId - The chainId to which the token belongs.
 * 
 * @returns A URL pointing to the token logo.
 */
function buildLogoURL(checksummed: string, chainId: number): string {
  if (chainId === 369) {
    return `https://tokens.app.pulsex.com/images/tokens/${checksummed}.png`;
  }

  //TODO: remove temp shiba fix
  if(chainId === 109 && checksummed.toUpperCase() == '0X495EEA66B0F8B636D441DC6A98D8F5C3D455C4C0') {
    return `/images/tokens/shiba.png`
  }else if(chainId === 109){
    return `https://dex.marswap.exchange/images/tokens/${checksummed.toUpperCase()}.png`
  }

  const chain = getChainName(chainId);
  if(!chain) return '';
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chain}/assets/${checksummed}/logo.png`;
}


// gets the right url needed to pull token info from trustwallet repo usinig just the token address
export async function getTrustWalletUrl(address: string, chainId: number) {
  const w3 = get(web) || new Web3();
  let checksummed = w3.utils.toChecksumAddress(address);
  const chainName = getChainName(chainId);
  const url = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chainName}/assets/${checksummed}/info.json`;

  let urlCheck;
  try {
    urlCheck = await fetch(url); //.then((res) => res.status === 200);
  } catch {
    return false;
  }

  return urlCheck.status === 200 ? url : false;
}

export function getChainInfo(address: string, chainId: number) {
  const chainName = getChainName(chainId);
  const url = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chainName}/info/info.json`;
  return url;
}

// function that returns the chain name from the chain id by pulling from the Chains.ts object
function getChainName(chainId: number) {
  const chains = get(Chains);

  let name = chains[chainId]?.name?.toLowerCase();
  let shortName = chains[chainId]?.shortName?.toLowerCase();
  let chain = shortNames.find(
    (c) => name?.search(c) !== -1 || shortName?.search(c) !== -1 || c?.search(name) !== -1 || c?.search(shortName) !== -1
  );

  if (chain === 'binance') chain = 'smartchain';

  return chain;
}

const shortNames = [
  'aeternity',
  'aion',
  'algorand',
  'aptos',
  'arbitrum',
  'ark',
  'aryacoin',
  'aurora',
  'avalanchec',
  'band',
  'binance',
  'bitcoin',
  'bitcoincash',
  'bitcoingold',
  'bluzelle',
  'boba',
  'callisto',
  'canto',
  'cardano',
  'celo',
  'classic',
  'cosmos',
  'cronos',
  'cryptoorg',
  'dash',
  'decred',
  'digibyte',
  'doge',
  'dogechain',
  'ecash',
  'ellaism',
  'elrond',
  'energyweb',
  'eos',
  'ether-1',
  'ethereum',
  'ethereumpow',
  'everscale',
  'evmos',
  'fantom',
  'filecoin',
  'fio',
  'firo',
  'fusion',
  'gochain',
  'groestlcoin',
  'harmony',
  'heco',
  'hoo',
  'icon',
  'iost',
  'iotex',
  'kava',
  'kavaevm',
  'kcc',
  'kin',
  'klaytn',
  'kusama',
  'litecoin',
  'loom',
  'meter',
  'metis',
  'moonbeam',
  'moonriver',
  'nano',
  'nativeevmos',
  'last month',
  'near',
  'nebulas',
  'neo',
  'nervos',
  'nimiq',
  'nuls',
  'oasis',
  'okc',
  'okxchain',
  'ontology',
  'optimism',
  'osmosis',
  'platon',
  'poa',
  'polkadot',
  'polygon',
  'qtum',
  'ravencoin',
  'ripple',
  'ronin',
  'smartbch',
  'smartchain',
  'solana',
  'steem',
  'stellar',
  'terra',
  'terrav2',
  'tezos',
  'theta',
  'thorchain',
  'thundertoken',
  'tomochain',
  'ton',
  'tron',
  'vechain',
  'viacoin',
  'wanchain',
  'waves',
  'xdai',
  'xdc',
  'zcash',
  'zelcash',
  'zilliqa',
];
