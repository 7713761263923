import type { IUniswapV2Pair } from './../Typings/contracts/uniswapRouter.sol/IUniswapV2Pair';
import type { Multicall } from './../Typings/contracts/MultiCall.sol/Multicall';
import { MultiCalls } from '../Data/MultiCall';
import type { RBXTeleSwapUpgradeable } from './../Typings/contracts/RBXTeleSwap_upg.sol/RBXTeleSwapUpgradeable';
import { TeleSwapRouters } from './../Data/TeleSwapRouters';
import { Routers } from '../Data/Routers';
import type Web3 from 'web3';
import { rpcLooper } from './helpers/rpcLooper';
import BigNumber from 'bignumber.js';
import type { IQuoteResult } from '../Data/QuoteResult';
import type { IToken } from '../Data/Tokens';
import ABI from '../Data/ABI';
import { Chains } from '../Data/Chains';
import type { IChains } from '../Data/Chains';
import { get } from 'svelte/store';
import Contracts from '../Data/Contracts';
import { PairStore } from '../Data/PairsInfoStore';
import type { IPairInfo } from '../Data/PairsInfoStore';
import { checksummed } from './helpers/utils';
import { ChainId } from '../Data/Wallet';
import { web } from '../Data/Web3Store';

BigNumber.set({ DECIMAL_PLACES: 18, EXPONENTIAL_AT: 100 });

// BigNumber.prototype[require("util").inspect.custom] = BigNumber.prototype.valueOf;

interface IBestRate {
    rate?: BigNumber;
    pair?: IPairInfo;
}

export async function getQuoteOut(
    amount: BigNumber,
    token0: IToken | null,
    token1: IToken | null,
    amountInQuote: boolean = false,
    cached: boolean = false
): Promise<IQuoteResult> {
    if (!token0 || !token1) return {} as IQuoteResult;
    if (!amount) return {} as IQuoteResult;
    const chainId = token0?.chainId || token1?.chainId || get(ChainId);

    console.log('getQuoteOut amount:', amount.toString());

    const exp0 = new BigNumber(10).pow(token0.decimals || 0);
    const exp1 = new BigNumber(10).pow(token1.decimals || 0);

    const amountExp = amount?.multipliedBy(amountInQuote ? exp1 : exp0) || new BigNumber(0);
    let validRoutes: string[][] = [];

    let pairs: IPairInfo[] = [];
    let routeRates: IBestRate[] = [];
    let path: string[] = [];
    let leftAmount: BigNumber = new BigNumber(0);
    let rightAmount: BigNumber = new BigNumber(0);

    /*
  TODO: TOTEST: price impact calcs ↴
  get rate before swap (price of $1 in tokens)
  calculate swap amounts
  get rate after swap (price of $1 in tokens)

  calc impact by:
  price impact = abs(rateBefore - rateAfter) / rateBefore

  */

    let quote: IQuoteResult = {
        leftAmount: leftAmount.div(exp0),
        rightAmount: rightAmount.div(exp1),
        base: '',
        path: path,
        approvalNeeded: true
    };

    if (!amountInQuote) {
        pairs = cached ? get(PairStore) : await getPairsAndReservesMulti(token0, token1).catch((e) => false);
        if(!pairs) return quote as IQuoteResult;
        validRoutes = generateRoutes(pairs, token0, token1);
        ({ rate: routeRates, path: path } = getRouteRateOut(amountExp, validRoutes, pairs));
        if (!routeRates || !routeRates[0]?.pair) return quote as IQuoteResult;
        rightAmount = routeRates[routeRates.length - 1].rate || new BigNumber(0);
        leftAmount = amountExp;
    } else {
        pairs = cached ? get(PairStore) : await getPairsAndReservesMulti(token1, token0).catch((e) => false);
        if(!pairs) return quote as IQuoteResult;
        validRoutes = generateRoutes(pairs, token1, token0);

        ({ rate: routeRates, path: path } = getRouteRateIn(amountExp, validRoutes, pairs));
        if (!routeRates || !routeRates[0]?.pair) return quote as IQuoteResult;
        leftAmount = routeRates[routeRates.length - 1].rate || new BigNumber(0);
        rightAmount = amountExp;
    }

    const base = Routers[chainId]?.length > 1 ? checksummed(Routers[chainId][1]?.base) : checksummed(Routers[chainId][0]?.base);

    quote = {
        leftAmount: leftAmount.div(exp0),
        rightAmount: rightAmount.div(exp1),
        base: base,
        path: path,
        approvalNeeded: true
    };

    return quote;
}

const getPairsAndReservesMulti = async (t0: IToken, t1: IToken) => {
    let chainId = t0?.chainId || t1?.chainId || get(ChainId);
    let w3: Web3 | undefined = await rpcLooper(chainId);
    if (!w3) return;
    console.log('getPairsAndReservesMulti chainId:', chainId);
    // let currentBlock = await w3.eth.getBlockNumber();

    // const chains = get(Chains);

    const multicall = new w3.eth.Contract(ABI.multiCall, MultiCalls[chainId].address) as unknown as Multicall;
    const tswap = new w3.eth.Contract(ABI.teleSwap, TeleSwapRouters[chainId].address) as unknown as RBXTeleSwapUpgradeable;

    const routers = Routers[chainId];

    const calls: [string, string][] = [];

    const [t0a, t1a] = sortTokens(t0.address || '', t1.address || '');
    let [pairPaths] = getPairPaths(t0a, t1a, chainId);
    
    let paths: string[][] = [];
    let validRouters: string[] = [];
    let queriedRouters: string[] = [];

    for (let i = 0; i < routers.length; i++) {
        const router = routers[i];

        // map encoded calls for every entry in pairPaths variable through each router
        pairPaths.map((p) => {
            const el = tswap.methods.getPair(router.address, p[0], p[1]).encodeABI();
            calls.push([TeleSwapRouters[chainId].address, el]);
            paths.push([p[0], p[1]]);
            queriedRouters.push(router.address);
        });
    }
    let results = await multicall.methods.aggregate(calls, false).call();

    let reserves: [string, string][] = [];
    let pairs: string[] = [];
    let tokens: string[][] = [];
    for (let i = 0; i < results.returnData.length; i++) {
        if (!results.returnData[i][0]) continue;
        const pairAddy = results.returnData[i][1];
        const decoded: string = checksummed(w3.eth.abi.decodeParameters(['address'], pairAddy)[0]);
        if (!decoded || decoded === '0x0000000000000000000000000000000000000000') continue;
        if (pairs.includes(decoded)) continue;
        validRouters.push(queriedRouters[i]);
        const pair = new w3.eth.Contract(ABI.uniLPtoken, decoded) as unknown as IUniswapV2Pair;

        const reserve = pair.methods.getReserves().encodeABI();
        reserves.push([decoded, reserve]);
        pairs.push(decoded);
        tokens.push(sortTokens(paths[i][0], paths[i][1]));
    }

    const timestamp = Date.now() / 1000;
    let reserveResults: any = await multicall.methods.aggregate(reserves, false).call();
    let decodedReserves: IPairInfo[] = [];
    for (let i = 0; i < reserveResults.returnData.length; i++) {
        const reserve = reserveResults.returnData[i]['data'];
        const decoded = w3.eth.abi.decodeParameters(['uint112', 'uint112', 'uint32'], reserve);
        let price0 = new BigNumber(decoded[1]).div(decoded[0]);
        let price1 = new BigNumber(decoded[0]).div(decoded[1]);
        // prices are in the paired token: ie price0 is the price of token0 in token1 and vice versa
        // so if we are getting the price of token0 in token1, we want price0
        // if we are getting the price of token1 in token0, we want price1
        // BNB to USD is price1
        decodedReserves[i] = {
            pair: pairs[i],
            blockstamp: decoded[2],
            chainId: t0.chainId || 0,
            router: validRouters[i],
            t0: tokens[i][0],
            t1: tokens[i][1],
            r0: decoded[0],
            r1: decoded[1],
            p0: price0.toFixed(18),
            p1: price1.toFixed(18),
            d0: t0.decimals || 0,
            d1: t1.decimals || 0,
            timestamp: timestamp
        };
    }

    PairStore.update(decodedReserves);

    // return get(PairStore);
    return decodedReserves;
};

const getRouteRateOut = (amount: BigNumber, validRoutes: string[][], pairs: IPairInfo[]): { rate: IBestRate[]; path: string[] } => {
    let finalRateOut: IBestRate[][] = [];
    let pathOut: string[][] = [];

    for (let i = 0; i < validRoutes.length; i++) {
        const route = validRoutes[i];
        let currentAmountOut = amount;
        let bestRatesTempOut: IBestRate[] = [];

        for (let j = 0; j < route.length; j++) {
            if (!route[j + 1]) break;

            const pIndices = getPairs(route[j], route[j + 1], pairs);

            if (pIndices.length === 0) {
                bestRatesTempOut = [];
                currentAmountOut = new BigNumber(0);
                break;
            }

            const currentPairs = pIndices.map((i) => pairs[i]);
            const bestRateOut = bestRatesOut(currentAmountOut, currentPairs, route[j]);

            if (bestRateOut.rate?.lte(0) || bestRateOut.rate?.isNaN()) {
                bestRatesTempOut = [];
                currentAmountOut = new BigNumber(0);
                break;
            }

            currentAmountOut = bestRateOut.rate || new BigNumber(0);
            bestRatesTempOut.push(bestRateOut);
        }

        if (bestRatesTempOut?.length > 0) {
            finalRateOut.push(bestRatesTempOut);
            pathOut.push(route);
        }
    }

    let bestRate: IBestRate[] = finalRateOut[0];
    let bestRoute: string[] = pathOut[0];

    for (let i = 1; i < finalRateOut?.length; i++) {
        const rate = finalRateOut[i];
        const path = pathOut[i];

        if (rate[rate.length - 1].rate?.gt(bestRate[bestRate.length - 1].rate!)) {
            bestRate = rate;
            bestRoute = path;
        }
    }

    return { rate: bestRate, path: bestRoute };
};

const getRouteRateIn = (amount: BigNumber, validRoutes: string[][], pairs: IPairInfo[]): { rate: IBestRate[]; path: string[] } => {
    let finalRateIn: IBestRate[][] = [];
    let pathIn: string[][] = [];

    for (let i = 0; i < validRoutes.length; i++) {
        const route: string[] = validRoutes[i];
        let currentAmountIn = amount;
        let bestRatesTempIn: IBestRate[] = [];

        for (let j = 0; j < route.length; j++) {
            if (!route[j + 1]) continue;
            const pIndices = getPairs(route[j], route[j + 1], pairs);
            if (pIndices.length === 0) {
                j = route.length;
                bestRatesTempIn = [];
                currentAmountIn = new BigNumber(0);
                continue;
            }
            const currentPairs = pIndices.map((i) => pairs[i]);
            const bestRateIn = bestRatesIn(currentAmountIn, currentPairs, route[j]);

            if (bestRateIn.rate?.lte(0) || bestRateIn.rate?.isNaN()) {
                j = route.length;
                bestRatesTempIn = [];
                currentAmountIn = new BigNumber(0);
                continue;
            }

            currentAmountIn = bestRateIn.rate || new BigNumber(0);
            bestRatesTempIn.push(bestRateIn);
        }

        if (bestRatesTempIn.length > 0) {
            finalRateIn.push(bestRatesTempIn);
            pathIn.push(route);
        }
    }

    let inTemp: IBestRate[] = finalRateIn[0];
    let routeInTemp: string[] = pathIn[0];
    for (let i = 0; i < finalRateIn.length; i++) {
        const rate: IBestRate[] = finalRateIn[i];
        const path: string[] = pathIn[i];

        if (rate[rate.length - 1].rate?.lt(inTemp[inTemp.length - 1].rate || 0)) {
            // if (rate[0].rate.lt(inTemp[0].rate)) {
            inTemp = rate;
            routeInTemp = path;
        }
    }

    return { rate: inTemp, path: routeInTemp };
};

// calculates the best 'out' rate for given amount and pairs
const bestRatesOut = (amtIn: BigNumber, pairs: IPairInfo[], t0: string): IBestRate => {
    let bestRateOut = new BigNumber(0);
    let bestPairOut: IPairInfo | undefined;

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i];
        const amountOut = getAmountOut(amtIn, t0, pair);
        if (amountOut.gt(bestRateOut)) {
            bestRateOut = amountOut;
            bestPairOut = pair;
        }
    }

    return { rate: bestRateOut, pair: bestPairOut };
};

// calculates the best 'in' rate for given amount and pairs
const bestRatesIn = (amtOut: BigNumber, pairs: IPairInfo[], t0: string, reverse: boolean = true): IBestRate => {
    let bestRateIn = new BigNumber(0);
    let bestPairIn: IPairInfo | undefined;

    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i];
        const amountIn = getAmountIn(amtOut, t0, pair);

        if (amountIn.lte(0)) continue;
        if (amountIn.eq(1)) continue;
        if (amountIn.isNaN()) continue;

        if (bestRateIn.eq(0)) {
            bestRateIn = amountIn;
            bestPairIn = pair;
            continue;
        }
        if (amountIn.lt(bestRateIn)) {
            bestRateIn = amountIn;
            bestPairIn = pair;
        }
    }

    return { rate: bestRateIn, pair: bestPairIn };
};

const getAmountOut = (amountIn: BigNumber, t0: string, pair: IPairInfo): BigNumber => {
    t0 = checksummed(t0);
    pair.t0 = checksummed(pair.t0);
    pair.t1 = checksummed(pair.t1);

    if (pair.t0 !== t0 && pair.t1 !== t0) return BigNumber(0);

    let r0: string;
    let r1: string;

    if (t0 === pair.t0) {
        r0 = pair.r0;
        r1 = pair.r1;
    } else {
        r0 = pair.r1;
        r1 = pair.r0;
    }

    const amountInWithFee = amountIn.times(997);
    const numerator = amountInWithFee.times(r1);
    const denominator = new BigNumber(r0).times(1000).plus(amountInWithFee);
    const amountOut = numerator.div(denominator);

    return amountOut;
};

const getAmountIn = (amountOut: BigNumber, t0: string, pair: IPairInfo): BigNumber => {
    t0 = checksummed(t0);
    pair.t0 = checksummed(pair.t0);
    pair.t1 = checksummed(pair.t1);

    if (pair.t0 !== t0 && pair.t1 !== t0) return BigNumber(0);

    let r0: string;
    let r1: string;

    // if (t0 === pair.t0) { r0 = pair.r0; r1 = pair.r1; }
    // else { r0 = pair.r1; r1 = pair.r0; }
    if (t0 === pair.t0) {
        r0 = pair.r1;
        r1 = pair.r0;
    } else {
        r0 = pair.r0;
        r1 = pair.r1;
    }

    const numerator = amountOut.times(r0).times(1000);
    const denominator = new BigNumber(r1).minus(amountOut).times(997);
    const amountIn = numerator.div(denominator).plus(1);

    return amountIn;
};

const generateRoutes = (pairs: IPairInfo[], token0: IToken, token1: IToken): string[][] => {
    const chainId = token0?.chainId || token1?.chainId || get(ChainId);
    const base = Routers[chainId]?.length > 1 ? checksummed(Routers[chainId][1]?.base) : checksummed(Routers[chainId][0]?.base);

    const t0 = checksummed(token0.address === Contracts.BASE ? base : token0.address);
    const t1 = checksummed(token1.address === Contracts.BASE ? base : token1.address);

    let routes = [];
    let connectorTokens = [];

    for (let i = 0; i < pairs.length; i++) {
        const p = pairs[i];
        if (checksummed(p.t0) !== t0 && checksummed(p.t0) !== t1) connectorTokens.push(checksummed(p.t0));
        if (checksummed(p.t1) !== t1 && checksummed(p.t1) !== t1) connectorTokens.push(checksummed(p.t1));
    }
    connectorTokens = [...new Set(connectorTokens)];

    const perms = permutePairs([t1, ...connectorTokens]);

    for (let i = 0; i < perms.length; i++) {
        const perm = perms[i];
        if (perm[0] === t1) routes.push([t0, perm[1], perm[0]]);
        else if (perm[1] === t1) routes.push([t0, perm[0], perm[1]]);
        else if (!perm.includes(t0) && !perm.includes(t1)) {
            routes.push([t0, ...perm, t1]);
            routes.push([t0, perm[1], perm[0], t1]);
        }
    }
    routes.push([t0, t1]);

    return routes;
};

function permutePairs(arr: string[]): string[][] {
    const output: string[][] = [];
    const n = arr.length;
    for (let i = 0; i < n - 1; i++) {
        for (let j = i + 1; j < n; j++) {
            output.push([arr[i], arr[j]]);
        }
    }
    return output;
}

function getPairs(value0: any, value1: any, pairs: any[]): number[] {
    const indices: number[] = [];
    const queue: any[] = [...pairs];
    const seen: Set<any> = new Set();

    // replace with checksummed address using web3 utils
    value0 = checksummed(value0);
    value1 = checksummed(value1);

    while (queue.length > 0) {
        const obj = queue.shift();
        if (seen.has(obj)) continue;
        seen.add(obj);
        let foundValue0 = false;
        let foundValue1 = false;

        if (obj['t0'] === value0 || obj['t0'] === value1) foundValue0 = true;
        if (obj['t1'] === value0 || obj['t1'] === value1) foundValue1 = true;

        if (foundValue0 && foundValue1) {
            indices.push(pairs.indexOf(obj));
        }
    }
    return indices;
}

export function removeDuplicates(arr: any[][]): any[][] {
    // Create an empty object to store unique arrays
    const uniqueArrays: { [key: string]: any[] } = {};

    // Loop through the input array
    for (let i = 0; i < arr.length; i++) {
        // Convert the current array to a string and store it as a key in the object
        const key = JSON.stringify(arr[i]).toLowerCase();
        // If the key doesn't already exist in the object, add it
        if (!uniqueArrays[key]) {
            uniqueArrays[key] = arr[i];
        }
    }

    // Extract the arrays from the object and return them as a new array
    return Object.values(uniqueArrays);
}

const getPairPaths = (t0: string, t1: string, chainId: number) => {
    let pairs = [];

    const chains: IChains = get(Chains);

    const bases = Routers[chainId].map((r) => r.base);
    const stables = chains[chainId].stableCoins.map((s: { address: string }) => s.address);
    const temp = new Set([...bases, ...stables]);
    const basesAndStables = [...temp];

    // const base = checksummed(Routers[chainId][1]?.base || Routers[chainId][0].base);
    const base = Routers[chainId]?.length > 1 ? checksummed(Routers[chainId][1]?.base) : checksummed(Routers[chainId][0]?.base);

    let token0 = t0 === Contracts.BASE || t0 === Contracts.ZERO ? base : t0;
    let token1 = t1 === Contracts.BASE || t1 === Contracts.ZERO ? base : t1;
    token0 = checksummed(token0);
    token1 = checksummed(token1);
    const s0 = checksummed(
        chains[chainId].stableCoins.find(
            (x: { address: string }) => x.address.toLowerCase() != token0.toLowerCase() && x.address.toLowerCase() != token1.toLowerCase()
        )?.address || Contracts.ZERO
    );
    const s1 = checksummed(
        chains[chainId].stableCoins.find(
            (x: { address: string }) => x.address.toLowerCase() != token0.toLowerCase() && x.address.toLowerCase() != token1.toLowerCase()
        )?.address || Contracts.ZERO
    );

    token0 !== token1 && pairs.push([token0, token1]);
    token0 !== base && pairs.push([token0, base]);
    token1 !== base && pairs.push([token1, base]);

    for (let s = 0; s < basesAndStables?.length || 0; s++) {
        const coin = checksummed(basesAndStables[s]);

        token0 !== coin && pairs.push([token0, coin]);
        token1 !== coin && pairs.push([token1, coin]);
        base !== coin && pairs.push([base, coin]);
    }

    const pairPaths = removeDuplicates(pairs);

    return [pairPaths];
};

const checkPairCache = async (paths: string[][]) => {
    const pairStore = get(PairStore);
    if (!pairStore) return { paths: paths, cached: false };
    const threshold = new BigNumber(60); //(await getDailyBlocks())//.div(24).div(60);
    const now = Date.now() / 1000;

    let neupaths = [];

    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        const pIndices = getPairs(path[0], path[1], pairStore);
        if (pIndices.length === 0) continue;
        const foundPairs: IPairInfo[] = pIndices.map((i) => pairStore[i]);

        for (let j = 0; j < foundPairs.length; j++) {
            const foundPair = foundPairs[j];

            const lastTrade = new BigNumber(foundPair.timestamp).minus(foundPair.blockstamp);
            const howFresh = new BigNumber(now).minus(foundPair.timestamp);

            if (howFresh.lt(lastTrade) || threshold.gt(lastTrade)) continue;
            neupaths.push([foundPair.t0, foundPair.t1]);
        }
    }

    return { paths: paths, cached: neupaths.length > 0 };
};

const sortTokens = (t0: string, t1: string): string[] => {
    t0 = checksummed(t0);
    t1 = checksummed(t1);
    return new BigNumber(t0.toLowerCase()).lt(new BigNumber(t1.toLowerCase())) ? [t0, t1] : [t1, t0];
};

export const getDailyBlocks = async (w3?: Web3, chainId: number = 1) => {
    const web3 = w3 || (await rpcLooper(chainId));
    if (!web3) return;
    const block0 = await web3.eth.getBlock('latest');
    const block1 = await web3.eth.getBlock(block0.number - 128);

    const b0 = new BigNumber(block0.timestamp);
    const b1 = new BigNumber(block1.timestamp);

    const elapsed = b0.minus(b1);
    const bpd = new BigNumber(24 * 60 * 60).div(elapsed).times(128);

    return bpd;
};

// get latest gas price
export const getGasPrice = async () => {
    const web3 = get(web);
    if (!web3) return 0;

    let pendingBlockGas;

    try {
        pendingBlockGas = (await web3.eth.getBlock('pending')).baseFeePerGas || 0;
    } catch (error) {
        console.error('pending block error, getting latest block instead...');
        await web3.eth
            .getBlock('latest')
            .then((block) => {
                pendingBlockGas = block.baseFeePerGas || 0;
            })
            .catch((error) => {
                return 0;
            });
    }

    const allegedGasPrice = await web3.eth.getGasPrice().catch((error) => {
        console.error('gas price error:', error);
        return 0;
    });

    const selectedGasPrice = BigNumber(allegedGasPrice).lt((pendingBlockGas) || '0')
        ? BigNumber(pendingBlockGas || 0)
        : BigNumber(allegedGasPrice);

    const gasPrice = selectedGasPrice.times(1.25).toFixed(0);

    return gasPrice;
};
