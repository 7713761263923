export const MANUAL_TOKENS = 'manual_tokens';
export const RECENT_TOKENS = 'recent_tokens_v2';
export const LIQUIDITY_POSITIONS = 'liquidity_positions';
export const TRANSACTION_HISTORY = 'tx_history_v2';
export const LP_TOKENS = 'lp_tokens_v2';
export const UI_SETTINGS = 'ui_settings';
export const LAUNCH_PROJECTS = 'launch_projects_v4';
export const AUTHORIZATION_TOKEN = 'authorization';
export const OTC_ADDRESS_BOOK = 'otc_address_book';
export const PAIR_INFO_STORE = 'pair_info_store';
export const P2P_CACHE = 'p2p_data_cache';
export const P2P_ASSETS = 'p2p_assets';
export const SKIPSHIFT_DATA = 'skipshift_data';
export const RAMP_ENCRYPTION_KEY = 'ramp_crypt_key';
