import axios from "axios";

export const getTradeAlertStatus = async (address: string): Promise<boolean | undefined> => {
    const resp = await axios.get('https://skipscan.win/api/get-trade-alert-status', {//todo: use the fucken env
        params: {
            address: address,
        }
    });

    if (resp) return resp.data.alertStatus;
    else return true;
}