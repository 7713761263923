import { addActionLogLine } from '../../Data/ActionLogs';
import { ChainId } from '../../Data/Wallet';
import { Chains } from '../../Data/Chains';
import { get } from 'svelte/store';
import { web } from '../../Data/Web3Store';

export const chainSwitcher = async (chainId: number) => {
  const preHexChain = chainId.toString(16); // changed to support harmony networkId
  const preHexNetwork = chainId.toString(16); // changed to support harmony chainId

  const w3 = get(web);
  const provider = w3.currentProvider as any;

  if (!provider) {
    addActionLogLine(`Couldn't switch, provider not found`);
    return 0;
  }

  let eCode: number = 1;
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${preHexNetwork}` }],
    });
  } catch (e: any) {
    console.log('e.message: ', e.message);
    eCode = e.code || 0;
    // addActionLogLine(`${e.message}`);
  }

  if (eCode === 4902) {
    addActionLogLine(`Couldn't switch, attempting to add chain to wallet...`);
    try {
      const chains = get(Chains);
      const explorers = chains[chainId].explorers || [];

      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${preHexNetwork}`,
            chainName: chains[chainId]?.name,
            nativeCurrency: chains[chainId]?.nativeCurrency,
            rpcUrls: chains[chainId]?.rpc,
            blockExplorerUrls: [explorers[0]?.url],
          },
        ],
      });
    } catch (e: any) {
      console.log('e.message: ', e.message);
      eCode = e.code || 0;
      addActionLogLine(`${eCode}`);
    }
  }
  if (eCode === 1) ChainId.set(chainId);
  return eCode;
};

export default chainSwitcher;
