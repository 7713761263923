import { writable } from 'svelte/store';
import { UI_SETTINGS } from './LocalStorageItems';

export interface ITransactionOptions {
  slippage: number | 'auto';
  timeout: number;
  ludicrousGas: boolean;
  receiveWrapped: boolean;
  priceSlipWarnings: boolean;
  requireCollateral?: boolean;
}

function loadFromStorage(): ITransactionOptions {
  return JSON.parse(
    localStorage.getItem(UI_SETTINGS) ||
      `{
    "slippage": "auto",
    "timeout": 30,
    "ludicrousGas": false,
    "receiveWrapped": false,
    "requireCollateral": true
  }`
  );
}

export const TransactionOptions = writable<ITransactionOptions>(loadFromStorage());
