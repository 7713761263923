import { IRawListing } from "Data/P2P";
import { P2PDataStore } from "../../Data/P2PDataStore";
import { IPrefillData } from "../../Data/Prefill";
import { get } from "svelte/store";
import BigNumber from "bignumber.js";

//make sure the prefilled trade details are actually tradeable
export const validatePrefill = (prefill: IPrefillData) => {
    const listings = get(P2PDataStore).returnData;

    const isValidVenue = (listing: IRawListing, prefillVenue: string) => {
        const venues = listing.venues;
        //TODO: FIX THE FUCKEN VENUE TYPES
        //@ts-ignore
        if(venues.some((venue) => venue[0].toLowerCase() == prefillVenue.toLowerCase())){
            return true;
        }
    }

    const matched = listings.find((listing) => {
        return listing.currency == prefill.currency && 
            listing.asset.address?.toLocaleLowerCase() == prefill.asset.toLowerCase() &&
            listing.asset.chainId == prefill.chainId &&
            listing.type == prefill.direction &&
            (prefill.venue ? isValidVenue(listing, prefill.venue) : true)//use venues[0] if none provided in query string
    });

    console.log('found valid listing for prefill', matched);

    if (matched) return {//additional details are for the rampDetails component so it doesnt loadBlast until amount0 is entered
        status: true, 
        min: BigNumber(matched.min).div(10 ** (matched.asset.decimals || 18)), 
        max: BigNumber(matched.max).div(10 ** (matched.asset.decimals || 18)), 
        spread: matched.spread 
    }
    else return { status: true, min: "0", max: "0" };
}