import BigNumber from 'bignumber.js';
import { get } from 'svelte/store';
import type Web3 from 'web3';
import ABI from '../Data/ABI';
import { addActionLogLine } from '../Data/ActionLogs';
import { approveAmount } from '../Data/Constants';
import Contracts from '../Data/Contracts';
import type { IQuoteResult } from '../Data/QuoteResult';
import type { IToken } from '../Data/Tokens';
import { web } from '../Data/Web3Store';
import { rpcLooper } from './helpers/rpcLooper';
import { getGasPrice, getQuoteOut } from './multiQuoter';
import axios from 'axios';
import { chains } from '../Data/Chains';
import { Routers } from '../Data/Routers';

BigNumber.set({ DECIMAL_PLACES: 18, EXPONENTIAL_AT: 100 });

export const getPriceQuote = async (
    amount: BigNumber,
    tokenFrom: IToken | null,
    tokenTo: IToken | null,
    chain: number,
    amountInQuote: boolean = false,
    dual: boolean = false
): Promise<IQuoteResult | undefined> => {
    console.log('chain:', chain);
    console.log('getting price quote:', amount.toString(), tokenFrom?.symbol, tokenTo?.symbol, chain, amountInQuote, dual);


    if (!tokenFrom?.address || !tokenTo?.address) return;

    if(amount.lte(0)) return {
        leftAmount: new BigNumber(0),
        rightAmount: new BigNumber(0),
        base: '',
        path: [],
        approvalNeeded: false
    }

    //TEMPORARY OVERRIDE FOR SHIBARIUM DEPEGGED USDT ISSUE
    if ((chain == 109 ||
        tokenFrom?.chainId == 109 ||
        tokenTo?.chainId == 109) &&
        tokenTo?.symbol == chains[chain].nativeCurrency.symbol) {
        // return getStableQuoteDexscreener(tokenTo?.address || '', amount, chain || tokenTo?.chainId!, tokenTo?.symbol == chains[chain].nativeCurrency.symbol);
        return getStableQuoteWeb3(tokenFrom!, tokenTo!, amount);
    }

    if (tokenFrom?.address.toLowerCase() === tokenTo?.address.toLowerCase()) {
        let quote: IQuoteResult = {
            leftAmount: amount,
            rightAmount: amount,
            base: Routers[chain][0].address,
            path: [tokenFrom?.address, tokenTo?.address],
            approvalNeeded: true
        };

        return Promise.resolve(quote);
    }

    try {
        //TEMPORARY OVERRIDE FOR SHIBARIUM DEPEGGED USDT ISSUE
        // if(chain == 109 || tokenFrom?.chainId == 109 || tokenTo?.chainId == 109){
        //   console.log('shib override amount:', amount.toString());
        //   // return getStableQuoteDexscreener(tokenTo?.address || '', amount, chain || tokenTo?.chainId!, tokenTo?.symbol == chains[chain].nativeCurrency.symbol);
        //   return getStableQuoteWeb3(tokenFrom!, tokenTo!, amount);
        // }

        if (!tokenFrom?.address || !tokenTo?.address || !tokenFrom.chainId || !tokenTo.chainId) return;

        const quoteInfo = await getQuoteOut(amount, tokenFrom, tokenTo, amountInQuote);
        console.log('quoteInfo:', quoteInfo);

        const result: IQuoteResult = {
            leftAmount: quoteInfo.leftAmount,
            rightAmount: quoteInfo.rightAmount,
            approvalNeeded: true,
            base: quoteInfo.base,
            path: quoteInfo.path
        };

        return Promise.resolve(result);
    } catch (error) {
        console.error('Error in getPriceQuote:', error);
        return Promise.reject(null);
    }
};

export const checkApproval = async (wallet: string, tokenAddress: string, spender: string, chain?: number, amount?: BigNumber) => {
    if (!wallet || !tokenAddress || !spender) return true;
    if (tokenAddress === Contracts.BASE) return false;

    let w3: Web3 | undefined = !chain ? get(web) : await rpcLooper(chain);
    if (!w3) return;

    let contract;
    try {
        contract = await new w3.eth.Contract(ABI.erc20, tokenAddress);
    } catch (error) {
        console.error('erc20 contract init error:', error);
    }
    if (!contract) return;

    let allowance;
    try {
        allowance = await contract.methods.allowance(wallet, spender).call();
    } catch (error) {
        console.error('allowance call error:', error);
    }

    //must check if allowance is greater than or equal to amount here
    if (new BigNumber(allowance).gte(amount || 0)) {
        return false;
    } else {
        return true;
    }
};

export const approve = async (wallet: string, tokenAddress: string, spender: string): Promise<boolean> => {
    if (tokenAddress === Contracts.BASE) Promise.resolve(true);
    const w3 = get(web);
    const contract = new w3.eth.Contract(ABI.erc20, tokenAddress);

    const gasLimit = (await w3.eth.getBlock('latest')).gasLimit;

    const gasPrice = await getGasPrice();

    addActionLogLine('Approving...');
    try {
        const gas = await contract.methods.approve(spender, approveAmount).estimateGas({
            from: wallet,
            // gasLimit: gasLimit,
            // gasPrice: gasPrice
        });
        await contract.methods.approve(spender, approveAmount).send({
            from: wallet,
            gas: gas,
            // gasPrice: gasPrice
        });
    } catch (e) {
        console.error(e);
        addActionLogLine('Rejected!', 'text-red-400');
        return Promise.resolve(false);
    }

    addActionLogLine('Approve complete!', 'text-green-400');
    return Promise.resolve(true);
};

/**
 * Retrieves the price of a token from a dexscreener and calculates the rate based on a given USD amount.
 * @param {string} address - The address of the token.
 * @param {BigNumber} usdAmount - The amount in USD.
 * @returns {IQuoteResult} Quote result object
 */
export const getStableQuoteDexscreener = async (address: string, usdAmount: BigNumber, chainId: number, native: boolean = false): Promise<IQuoteResult> => {
    console.log('native?', native);
    let price: BigNumber;
    console.log('getting dexscreener quote');
    try {
        const resp = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${native ? chains[chainId].transportTokens[0].address : address}`);
        console.log(resp.data);
        price = new BigNumber(resp.data.pairs[0].priceUsd);
    } catch (e) {
        console.log(e);
        price = new BigNumber(0);
    }

    const result: IQuoteResult = {
        leftAmount: usdAmount,
        rightAmount: usdAmount.div(price),
        approvalNeeded: true,
        base: '',
        path: []
    };

    console.log(result);

    return result;
};

/**
 * ONLY USE WITH STABLES FOR TOKEN0
 */
export const getStableQuoteWeb3 = async (stable: IToken, token1: IToken, usdAmount: BigNumber) => {
    console.log('getting stablecoin web3 quote for shibarium');
    const resp = await getQuoteOut(BigNumber(1), stable, token1);
    resp.rightAmount = resp.rightAmount?.times(usdAmount);
    return resp;
};
