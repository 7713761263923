const subZero = (num: string) => {
    const subscripts = [
      '\u2080',
      '\u2081',
      '\u2082',
      '\u2083',
      '\u2084',
      '\u2085',
      '\u2086',
      '\u2087',
      '\u2088',
      '\u2089',
    ];
    let regex = /0(0{2,})/;
    let found = num.match(regex);
  
    if(!found) return num;
  
    let s = [...found[1].length.toString()];
  
    let fin = s.map((name) => {
      return name.replace(/\d/g, (num) => subscripts[Number(num)]);
    });

    let rep = num.replace(regex, '0' + fin.toString().replace(',', '') + '');
  
    return rep;
  };
  
  export default subZero;
  