import axios from "axios";
import { currencies, type IP2PTradeLiq, type IRawListing } from "../../Data/P2P";

const serverURL = `${import.meta.env.VITE_SKIPSCAN_SERVER_URL}/skipcache`

export const fetchAddressListings = async (address: string): Promise<IP2PTradeLiq[] | undefined> => {
    const params = {address: address};
    const resp = await axios.get('https://skipscan.win/api/skipcache', {
        params: params
    });

    //convert currency strings to itokens
    resp.data.returnData.forEach((listing: IP2PTradeLiq) => {
        // console.log(listing.currency);
        listing.currency = currencies.find((currency) => {
            return listing.currency == currency.symbol;
        });

        //convert 0/1 (server) to offramp/onramp (ui)
        //@ts-ignore
        listing.type = listing.type == "0" ? "OFFRAMP" : "ONRAMP";
    })

    // console.log('response data', resp.data);

    if(resp) return resp.data.returnData;
    else return;
}