import { rpcLooper } from "./rpcLooper";
import ABI from "../../Data/ABI";
import { SkipShiftContracts } from "../../Data/P2P";
import { SkipShiftCore } from "../../Typings/skipshift/contracts";
import BigNumber from "bignumber.js";
import type Web3 from "web3";

// function to verify that a listing is active (takes an array of listing ids)
export async function verifyActive(maker: string, tier: string, chainId: number): Promise<boolean> {
    const web3 = await rpcLooper(chainId);
    if(!web3) return false;

    const collatCheck = await checkMakerCollateral(web3, maker, tier, chainId);
    console.log('collatCheck: ', collatCheck);

    if (!collatCheck) return false;

    if (collatCheck) return true;
    return false;
}

async function checkMakerCollateral(w3: Web3, maker: string, tier: string, chainId: number) {
    // console.log('checking maker collateral: ', listing, '\n on chain id: ', chainId);
    if (!w3) return false;

    let skipShift = new w3.eth.Contract(ABI.skipShift, SkipShiftContracts[chainId]) as unknown as SkipShiftCore;

    let makerInfo = await skipShift.methods.userInfo(maker).call();
    // console.log('makerInfo: ', makerInfo);

    let tierThresholds = await skipShift.methods.tierThresholds(tier).call();
    // console.log('tierThresholds: ', tierThresholds);

    let collateralized = new BigNumber(makerInfo.collateral).gte(tierThresholds);

    return collateralized;
}