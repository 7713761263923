import { getBaseFee } from "../API/P2P";
import { writable } from "svelte/store";

export const approveAmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const DIVISOR = 10000;

export const PAGE_SIZE = 25;

export const SPREAD_LIMIT = 5000;


// function get platform fee directly from skipshift contract


export const PLATFORM_FEE_MULTIPLIER = writable<number>(0.975);
