import { IP2PLiquidity, IProviderRate } from "Data/P2P"
import { IChain } from "../../Data/Chains"
import { IToken } from "../../Data/Tokens"
import { ChainId, Wallet } from "../../Data/Wallet"
import chains from "../../Data/Chains"
import { get } from 'svelte/store';

export const formatError = (error: string, asset: IToken, listing?: IProviderRate) => {
    const wallet = get(Wallet);
    if(!asset?.chainId) return `Please connect your wallet to a supported chain to initiate this trade.`;
    
    const chain = chains[asset?.chainId];
    const currentChain: number = get(ChainId);

    console.log('err',error);
    console.log(currentChain, asset?.chainId);

    if(currentChain !== (asset?.chainId || 0))
        return `Please connect your wallet to ${chain.name} to initiate this trade.`
    else if(error.search('msg.sender == maker') > -1 || listing?.maker == wallet.address)
        return `Liquidity providers cannot initiate trades with their own listings.`
    else if(error.search('allowance') > -1)
        return `Your ${asset?.symbol} allowance is too low to cover the gas and/or collateral costs for this transaction.`
    else if(error.search('insufficient funds') > -1 || error.search('not have enough') > -1)
        return `Your ${chain.nativeCurrency?.symbol} balance is too low to cover the gas and/or collateral costs for this transaction.`
    else if(error.search('denied transaction') > -1)
        return `Uh oh, transaction was rejected manually! Let's give it another whirl, shall we?`
    else return `There's an issue with your transaction, please contact tech support on telegram @Skipshift_hub`;
}