import { writable } from 'svelte/store';

export const MESSAGE_TRACKER = 'messageCount';

export interface IMsgTrack {
  lastCount: number;
  count: number;
  newMessages: number;
}

export interface IMessageTracker {
  [keys: string]: IMsgTrack;
}

function createMessageTrackerStore() {
  const initialValue: IMessageTracker = JSON.parse(localStorage.getItem(MESSAGE_TRACKER) || '{}');
  const { subscribe, set, update } = writable<IMessageTracker>(initialValue);

  return {
    subscribe,
    set: (value: IMessageTracker) => {
      localStorage.setItem(MESSAGE_TRACKER, JSON.stringify(value));
      set(value);
    },
    update: (key: string, data: IMsgTrack) => update(n => {
      const updated = { ...n, [key]: data };
      localStorage.setItem(MESSAGE_TRACKER, JSON.stringify(updated));
      return updated;
    })
  };
}

export const messageTrackerStore = createMessageTrackerStore();
