import type { AbiItem } from 'web3-utils';

const ABI: Readonly<{ [keys: string]: AbiItem | AbiItem[] }> = {
    erc20: [
        {
            inputs: [
                { internalType: 'contract IUniswapV2Router02', name: '_uniswapV2Router', type: 'address' },
                { internalType: 'uint128', name: 'minTokensBeforeSwap', type: 'uint128' },
                { internalType: 'bool', name: 'swapAndLiquifyEnabled', type: 'bool' }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: 'uint8', name: '_feeDecimals', type: 'uint8' },
                { indexed: false, internalType: 'uint32', name: '_feePercentage', type: 'uint32' }
            ],
            name: 'FeeUpdated',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, internalType: 'uint128', name: '_minTokensBeforeSwap', type: 'uint128' }],
            name: 'MinTokensBeforeSwapUpdated',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
                { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: 'uint256', name: 'tokensSwapped', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'ethReceived', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'tokensIntoLiqudity', type: 'uint256' }
            ],
            name: 'SwapAndLiquify',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, internalType: 'bool', name: 'enabled', type: 'bool' }],
            name: 'SwapAndLiquifyEnabledUpdated',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'from', type: 'address' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            inputs: [],
            name: '_antiDumpToggle',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: '_burnPool',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: '_feeDecimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: '_feePercentage',
            outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: '_maxSellAmount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: '_swapAndLiquifyEnabled',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: '_totalBurnedLpTokens',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'owner', type: 'address' },
                { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint256', name: 'divisor', type: 'uint256' }],
            name: 'antiDumpAmount',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'bool', name: 'setting', type: 'bool' }],
            name: 'antiDumpToggle',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: '_token', type: 'address' },
                { internalType: 'address', name: '_to', type: 'address' },
                { internalType: 'uint256', name: '_amount', type: 'uint256' }
            ],
            name: 'burnLiq',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: '_amount', type: 'uint256' },
                { internalType: 'uint8', name: 'feeDecimals', type: 'uint8' },
                { internalType: 'uint32', name: 'feePercentage', type: 'uint32' }
            ],
            name: 'calculateTokenFee',
            outputs: [{ internalType: 'uint256', name: 'locked', type: 'uint256' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
            name: 'excludeAccount',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
            name: 'includeAccount',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
            name: 'isExcluded',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint256', name: 'tAmount', type: 'uint256' }],
            name: 'reflect',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'tAmount', type: 'uint256' },
                { internalType: 'bool', name: 'deductTransferFee', type: 'bool' }
            ],
            name: 'reflectionFromToken',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
        {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint256', name: 'rAmount', type: 'uint256' }],
            name: 'tokenFromReflection',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalFees',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'recipient', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'sender', type: 'address' },
                { internalType: 'address', name: 'recipient', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniswapV2Pair',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniswapV2Router',
            outputs: [{ internalType: 'contract IUniswapV2Router02', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint32', name: 'feePercentage', type: 'uint32' }],
            name: 'updateFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint128', name: 'minTokensBeforeSwap', type: 'uint128' }],
            name: 'updateMinTokensBeforeSwap',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'bool', name: '_enabled', type: 'bool' }],
            name: 'updateSwapAndLiquifyEnabled',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],
    uniRouter: [
        {
            inputs: [
                { internalType: 'address', name: '_factory', type: 'address' },
                { internalType: 'address', name: '_WETH', type: 'address' }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            inputs: [],
            name: 'WETH',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'WAVAX',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' },
                { internalType: 'uint256', name: 'amountADesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBDesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'addLiquidity',
            outputs: [
                { internalType: 'uint256', name: 'amountA', type: 'uint256' },
                { internalType: 'uint256', name: 'amountB', type: 'uint256' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'amountTokenDesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'addLiquidityETH',
            outputs: [
                { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'amountTokenDesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'multiplier', type: 'uint256' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'addLeveragedLiquidityETH',
            outputs: [
                { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'FACTORY',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'factory',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'uint256', name: 'reserveIn', type: 'uint256' },
                { internalType: 'uint256', name: 'reserveOut', type: 'uint256' }
            ],
            name: 'getAmountIn',
            outputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'reserveIn', type: 'uint256' },
                { internalType: 'uint256', name: 'reserveOut', type: 'uint256' }
            ],
            name: 'getAmountOut',
            outputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' }
            ],
            name: 'getAmountsIn',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' }
            ],
            name: 'getAmountsOut',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountA', type: 'uint256' },
                { internalType: 'uint256', name: 'reserveA', type: 'uint256' },
                { internalType: 'uint256', name: 'reserveB', type: 'uint256' }
            ],
            name: 'quote',
            outputs: [{ internalType: 'uint256', name: 'amountB', type: 'uint256' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'removeLiquidity',
            outputs: [
                { internalType: 'uint256', name: 'amountA', type: 'uint256' },
                { internalType: 'uint256', name: 'amountB', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'removeLiquidityETH',
            outputs: [
                { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETH', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
            outputs: [{ internalType: 'uint256', name: 'amountETH', type: 'uint256' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' },
                { internalType: 'bool', name: 'approveMax', type: 'bool' },
                { internalType: 'uint8', name: 'v', type: 'uint8' },
                { internalType: 'bytes32', name: 'r', type: 'bytes32' },
                { internalType: 'bytes32', name: 's', type: 'bytes32' }
            ],
            name: 'removeLiquidityETHWithPermit',
            outputs: [
                { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETH', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' },
                { internalType: 'bool', name: 'approveMax', type: 'bool' },
                { internalType: 'uint8', name: 'v', type: 'uint8' },
                { internalType: 'bytes32', name: 'r', type: 'bytes32' },
                { internalType: 'bytes32', name: 's', type: 'bytes32' }
            ],
            name: 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens',
            outputs: [{ internalType: 'uint256', name: 'amountETH', type: 'uint256' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' },
                { internalType: 'bool', name: 'approveMax', type: 'bool' },
                { internalType: 'uint8', name: 'v', type: 'uint8' },
                { internalType: 'bytes32', name: 'r', type: 'bytes32' },
                { internalType: 'bytes32', name: 's', type: 'bytes32' }
            ],
            name: 'removeLiquidityWithPermit',
            outputs: [
                { internalType: 'uint256', name: 'amountA', type: 'uint256' },
                { internalType: 'uint256', name: 'amountB', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapETHForExactTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactETHForTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForETH',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'uint256', name: 'amountInMax', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapTokensForExactETH',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'uint256', name: 'amountInMax', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapTokensForExactTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],
    uniLPtoken: [
        { inputs: [], payable: false, stateMutability: 'nonpayable', type: 'constructor' },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' }
            ],
            name: 'Burn',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256' }
            ],
            name: 'Mint',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'amount0In', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1In', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1Out', type: 'uint256' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' }
            ],
            name: 'Swap',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: 'uint112', name: 'reserve0', type: 'uint112' },
                { indexed: false, internalType: 'uint112', name: 'reserve1', type: 'uint112' }
            ],
            name: 'Sync',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'from', type: 'address' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            constant: true,
            inputs: [],
            name: 'DOMAIN_SEPARATOR',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'MINIMUM_LIQUIDITY',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'PERMIT_TYPEHASH',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'address', name: '', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
            name: 'burn',
            outputs: [
                { internalType: 'uint256', name: 'amount0', type: 'uint256' },
                { internalType: 'uint256', name: 'amount1', type: 'uint256' }
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'factory',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'getReserves',
            outputs: [
                { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
                { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
                { internalType: 'uint32', name: '_blockTimestampLast', type: 'uint32' }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                { internalType: 'address', name: '_token0', type: 'address' },
                { internalType: 'address', name: '_token1', type: 'address' }
            ],
            name: 'initialize',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'kLast',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
            name: 'mint',
            outputs: [{ internalType: 'uint256', name: 'liquidity', type: 'uint256' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'nonces',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                { internalType: 'address', name: 'owner', type: 'address' },
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'value', type: 'uint256' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' },
                { internalType: 'uint8', name: 'v', type: 'uint8' },
                { internalType: 'bytes32', name: 'r', type: 'bytes32' },
                { internalType: 'bytes32', name: 's', type: 'bytes32' }
            ],
            name: 'permit',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'price0CumulativeLast',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'price1CumulativeLast',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
            name: 'skim',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                { internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
                { internalType: 'uint256', name: 'amount1Out', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'bytes', name: 'data', type: 'bytes' }
            ],
            name: 'swap',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [],
            name: 'sync',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'token0',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'token1',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                { internalType: 'address', name: 'from', type: 'address' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],
    factory: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_feeToSetter',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token0',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token1',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'pair',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'PairCreated',
            type: 'event'
        },
        {
            constant: true,
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'allPairs',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'allPairsLength',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    internalType: 'address',
                    name: 'tokenA',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'tokenB',
                    type: 'address'
                }
            ],
            name: 'createPair',
            outputs: [
                {
                    internalType: 'address',
                    name: 'pair',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'feeTo',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'feeToSetter',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'getPair',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    internalType: 'address',
                    name: '_feeTo',
                    type: 'address'
                }
            ],
            name: 'setFeeTo',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    internalType: 'address',
                    name: '_feeToSetter',
                    type: 'address'
                }
            ],
            name: 'setFeeToSetter',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],
    wrappedNative: [
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [
                {
                    name: '',
                    type: 'string'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    name: 'guy',
                    type: 'address'
                },
                {
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [
                {
                    name: '',
                    type: 'bool'
                }
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    name: '',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    name: 'src',
                    type: 'address'
                },
                {
                    name: 'dst',
                    type: 'address'
                },
                {
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [
                {
                    name: '',
                    type: 'bool'
                }
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'withdraw',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    name: '',
                    type: 'uint8'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                {
                    name: '',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    name: '',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    name: '',
                    type: 'string'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: false,
            inputs: [
                {
                    name: 'dst',
                    type: 'address'
                },
                {
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'transfer',
            outputs: [
                {
                    name: '',
                    type: 'bool'
                }
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            constant: false,
            inputs: [],
            name: 'deposit',
            outputs: [],
            payable: true,
            stateMutability: 'payable',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                {
                    name: '',
                    type: 'address'
                },
                {
                    name: '',
                    type: 'address'
                }
            ],
            name: 'allowance',
            outputs: [
                {
                    name: '',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            payable: true,
            stateMutability: 'payable',
            type: 'fallback'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: 'src',
                    type: 'address'
                },
                {
                    indexed: true,
                    name: 'guy',
                    type: 'address'
                },
                {
                    indexed: false,
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: 'src',
                    type: 'address'
                },
                {
                    indexed: true,
                    name: 'dst',
                    type: 'address'
                },
                {
                    indexed: false,
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: 'dst',
                    type: 'address'
                },
                {
                    indexed: false,
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'Deposit',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: 'src',
                    type: 'address'
                },
                {
                    indexed: false,
                    name: 'wad',
                    type: 'uint256'
                }
            ],
            name: 'Withdrawal',
            type: 'event'
        }
    ],
    skipShift: [
      {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "ArbitrationRequested",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "enum Ancillaries.RampPhase",
            "name": "outcome",
            "type": "uint8"
          }
        ],
        "name": "ArbitrationResolved",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "bool",
            "name": "status",
            "type": "bool"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          }
        ],
        "name": "LiquidityStatus",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "bytes32",
            "name": "confHash",
            "type": "bytes32"
          }
        ],
        "name": "PaymentConf",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "ProviderSwitched",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "RampAccepted",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "RampCancelled",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "RampComplete",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "RampInitiated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "RampRejected",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "referrer",
            "type": "address"
          }
        ],
        "name": "ReferralCollected",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "enum Ancillaries.LiqTier",
            "name": "tier",
            "type": "uint8"
          }
        ],
        "name": "TierChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "DIVISOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "NATIVE_ASSET",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "reqCollateral",
            "type": "bool"
          },
          {
            "internalType": "enum Ancillaries.LiqTier",
            "name": "tier",
            "type": "uint8"
          },
          {
            "internalType": "enum Ancillaries.LiqDirection",
            "name": "buyOrSell",
            "type": "uint8"
          },
          {
            "internalType": "enum Ancillaries.AssetType",
            "name": "assetType",
            "type": "uint8"
          },
          {
            "internalType": "bytes3",
            "name": "currency",
            "type": "bytes3"
          },
          {
            "internalType": "uint256[]",
            "name": "minMaxSpread",
            "type": "uint256[]"
          },
          {
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "platform",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "identHash",
                "type": "bytes32"
              }
            ],
            "internalType": "struct Ancillaries.Venue[]",
            "name": "venues",
            "type": "tuple[]"
          },
          {
            "internalType": "bytes",
            "name": "kyp",
            "type": "bytes"
          }
        ],
        "name": "addRampLiquidity",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_arbitrationFee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_arbitrationBond",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_baseFee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_referralSplit",
            "type": "uint256"
          }
        ],
        "name": "adjustFees",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "split",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "referrer",
            "type": "address"
          }
        ],
        "name": "adjustReferrerSplit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "enum Ancillaries.LiqTier[]",
            "name": "tiers",
            "type": "uint8[]"
          },
          {
            "internalType": "uint256[]",
            "name": "levels",
            "type": "uint256[]"
          }
        ],
        "name": "adjustTiers",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "arbitrationBond",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "arbitrationFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "baseFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "baseSpread",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "cancelRampTrade",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          }
        ],
        "name": "changeCollateralToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "changeRequiredCollateral",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "collateralPair",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "collateralToken",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "collateralize",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "completeTrade",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "cutOffTime",
        "outputs": [
          {
            "internalType": "uint64",
            "name": "",
            "type": "uint64"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "decollateralize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "feeCollector",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "start",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getAccountListings",
        "outputs": [
          {
            "components": [
              {
                "internalType": "bool",
                "name": "active",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "reqCollateral",
                "type": "bool"
              },
              {
                "internalType": "enum Ancillaries.LiqDirection",
                "name": "buyOrSell",
                "type": "uint8"
              },
              {
                "internalType": "enum Ancillaries.AssetType",
                "name": "assetType",
                "type": "uint8"
              },
              {
                "internalType": "enum Ancillaries.LiqTier",
                "name": "tier",
                "type": "uint8"
              },
              {
                "internalType": "uint16",
                "name": "spread",
                "type": "uint16"
              },
              {
                "internalType": "bytes3",
                "name": "currency",
                "type": "bytes3"
              },
              {
                "internalType": "uint256",
                "name": "listingId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "min",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "max",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "maker",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "taker",
                "type": "address"
              },
              {
                "internalType": "bytes",
                "name": "kypFields",
                "type": "bytes"
              }
            ],
            "internalType": "struct Ancillaries.RampDetailsKyp[]",
            "name": "",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "platform",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "identHash",
                "type": "bytes32"
              }
            ],
            "internalType": "struct Ancillaries.Venue[][]",
            "name": "",
            "type": "tuple[][]"
          },
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "start",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "asset",
            "type": "address"
          }
        ],
        "name": "getAssetListings",
        "outputs": [
          {
            "components": [
              {
                "internalType": "bool",
                "name": "active",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "reqCollateral",
                "type": "bool"
              },
              {
                "internalType": "enum Ancillaries.LiqDirection",
                "name": "buyOrSell",
                "type": "uint8"
              },
              {
                "internalType": "enum Ancillaries.AssetType",
                "name": "assetType",
                "type": "uint8"
              },
              {
                "internalType": "enum Ancillaries.LiqTier",
                "name": "tier",
                "type": "uint8"
              },
              {
                "internalType": "uint16",
                "name": "spread",
                "type": "uint16"
              },
              {
                "internalType": "bytes3",
                "name": "currency",
                "type": "bytes3"
              },
              {
                "internalType": "uint256",
                "name": "listingId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "min",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "max",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "maker",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "taker",
                "type": "address"
              },
              {
                "internalType": "bytes",
                "name": "kypFields",
                "type": "bytes"
              }
            ],
            "internalType": "struct Ancillaries.RampDetailsKyp[]",
            "name": "",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "platform",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "identHash",
                "type": "bytes32"
              }
            ],
            "internalType": "struct Ancillaries.Venue[][]",
            "name": "",
            "type": "tuple[][]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getProviderAssets",
        "outputs": [
          {
            "internalType": "address[]",
            "name": "",
            "type": "address[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "start",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "end",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getUserTrades",
        "outputs": [
          {
            "components": [
              {
                "internalType": "enum Ancillaries.RampPhase",
                "name": "status",
                "type": "uint8"
              },
              {
                "internalType": "enum Ancillaries.LiqDirection",
                "name": "tradeDirection",
                "type": "uint8"
              },
              {
                "internalType": "uint64",
                "name": "lastAction",
                "type": "uint64"
              },
              {
                "internalType": "uint256",
                "name": "tradeId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "listingId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "totalCost",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "asset",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "taker",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "maker",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "destination",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
              },
              {
                "internalType": "bytes32",
                "name": "takerDataHash",
                "type": "bytes32"
              },
              {
                "components": [
                  {
                    "internalType": "string",
                    "name": "platform",
                    "type": "string"
                  },
                  {
                    "internalType": "bytes32",
                    "name": "identHash",
                    "type": "bytes32"
                  }
                ],
                "internalType": "struct Ancillaries.Venue",
                "name": "venue",
                "type": "tuple"
              }
            ],
            "internalType": "struct Ancillaries.RampTrade[]",
            "name": "",
            "type": "tuple[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "start",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getUserTrades",
        "outputs": [
          {
            "components": [
              {
                "internalType": "enum Ancillaries.RampPhase",
                "name": "status",
                "type": "uint8"
              },
              {
                "internalType": "enum Ancillaries.LiqDirection",
                "name": "tradeDirection",
                "type": "uint8"
              },
              {
                "internalType": "uint64",
                "name": "lastAction",
                "type": "uint64"
              },
              {
                "internalType": "uint256",
                "name": "tradeId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "listingId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "totalCost",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "asset",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "taker",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "maker",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "destination",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
              },
              {
                "internalType": "bytes32",
                "name": "takerDataHash",
                "type": "bytes32"
              },
              {
                "components": [
                  {
                    "internalType": "string",
                    "name": "platform",
                    "type": "string"
                  },
                  {
                    "internalType": "bytes32",
                    "name": "identHash",
                    "type": "bytes32"
                  }
                ],
                "internalType": "struct Ancillaries.Venue",
                "name": "venue",
                "type": "tuple"
              }
            ],
            "internalType": "struct Ancillaries.RampTrade[]",
            "name": "",
            "type": "tuple[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "initComplete",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_referralSplit",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_baseFee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_userCollateral",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_arbitrationFee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_arbitrationBond",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_feeCollector",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_collateralToken",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_wNative",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_collateralPair",
            "type": "address"
          }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "destination",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "referrer",
            "type": "address"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "platform",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "identHash",
                "type": "bytes32"
              }
            ],
            "internalType": "struct Ancillaries.Venue",
            "name": "venue",
            "type": "tuple"
          }
        ],
        "name": "initiateRampTrade",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "kypFields",
        "outputs": [
          {
            "internalType": "bytes",
            "name": "",
            "type": "bytes"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "status",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "asset",
            "type": "address"
          }
        ],
        "name": "listingStatus",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          },
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          },
          {
            "internalType": "bytes",
            "name": "",
            "type": "bytes"
          }
        ],
        "name": "onERC1155BatchReceived",
        "outputs": [
          {
            "internalType": "bytes4",
            "name": "",
            "type": "bytes4"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "",
            "type": "bytes"
          }
        ],
        "name": "onERC1155Received",
        "outputs": [
          {
            "internalType": "bytes4",
            "name": "",
            "type": "bytes4"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "",
            "type": "bytes"
          }
        ],
        "name": "onERC721Received",
        "outputs": [
          {
            "internalType": "bytes4",
            "name": "",
            "type": "bytes4"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          }
        ],
        "name": "pingTrade",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "postedBonds",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "deposit",
            "type": "bool"
          },
          {
            "internalType": "enum Ancillaries.LiqTier",
            "name": "tier",
            "type": "uint8"
          }
        ],
        "name": "provisioning",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "rampIndex",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "rampLiquidity",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "rampTrades",
        "outputs": [
          {
            "internalType": "enum Ancillaries.RampPhase",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "enum Ancillaries.LiqDirection",
            "name": "tradeDirection",
            "type": "uint8"
          },
          {
            "internalType": "uint64",
            "name": "lastAction",
            "type": "uint64"
          },
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalCost",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "asset",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "taker",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "maker",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "destination",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "referrer",
            "type": "address"
          },
          {
            "internalType": "bytes32",
            "name": "takerDataHash",
            "type": "bytes32"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "platform",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "identHash",
                "type": "bytes32"
              }
            ],
            "internalType": "struct Ancillaries.Venue",
            "name": "venue",
            "type": "tuple"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "referralSplit",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          }
        ],
        "name": "requestArbitration",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "requiredCollateral",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          },
          {
            "internalType": "address payable",
            "name": "recipient",
            "type": "address"
          }
        ],
        "name": "rescueNative",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "enum Ancillaries.AssetType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "ordinal",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "recipient",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          }
        ],
        "name": "rescueTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "internalType": "enum Ancillaries.RampPhase",
            "name": "outcome",
            "type": "uint8"
          }
        ],
        "name": "resolveArbitration",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "accept",
            "type": "bool"
          },
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalCost",
            "type": "uint256"
          }
        ],
        "name": "respondRamp",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "baseSpread_",
            "type": "uint256"
          }
        ],
        "name": "setBaseSpread",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "status",
            "type": "bool"
          }
        ],
        "name": "setCollatStatus",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint64",
            "name": "cutOffTime_",
            "type": "uint64"
          }
        ],
        "name": "setCutOffTime",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "internalType": "enum Ancillaries.RampPhase",
            "name": "status",
            "type": "uint8"
          }
        ],
        "name": "setTradeStatus",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "internalType": "bytes32",
            "name": "confirmationHash",
            "type": "bytes32"
          }
        ],
        "name": "submitConfirmation",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes4",
            "name": "interfaceId",
            "type": "bytes4"
          }
        ],
        "name": "supportsInterface",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tradeId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "newListingId",
            "type": "uint256"
          }
        ],
        "name": "switchProvider",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "enum Ancillaries.LiqTier",
            "name": "",
            "type": "uint8"
          }
        ],
        "name": "tierThresholds",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "userInfo",
        "outputs": [
          {
            "internalType": "uint8",
            "name": "tradeLocked",
            "type": "uint8"
          },
          {
            "internalType": "int8",
            "name": "score",
            "type": "int8"
          },
          {
            "internalType": "uint256",
            "name": "firstTrade",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "lastTrade",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "collateral",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "tradeIndex",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "wNative",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "whitelistedProvider",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "stateMutability": "payable",
        "type": "receive"
      }
    ],
    teleSwap: [
        {
            inputs: [],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'receiver',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'finalToken',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint64',
                    name: 'dstChainId',
                    type: 'uint64'
                },
                {
                    indexed: false,
                    internalType: 'bytes32',
                    name: 'transferId',
                    type: 'bytes32'
                }
            ],
            name: 'CrossSwap',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint64',
                    name: 'dstChainId',
                    type: 'uint64'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'transferId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'CrossSwapMulti',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'MessageBridge',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'receiver',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'conduit',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'tribute',
                    type: 'uint256'
                }
            ],
            name: 'Pylons',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'target',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bytes32',
                    name: 'txId',
                    type: 'bytes32'
                },
                {
                    indexed: false,
                    internalType: 'bytes',
                    name: 'result',
                    type: 'bytes'
                }
            ],
            name: 'RelayCompleted',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'Swap',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'destinationChain',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'bridgeTxId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'bytes32',
                    name: 'transferId',
                    type: 'bytes32'
                },
                {
                    indexed: false,
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'SwapCrossLoad',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'xReceive',
            type: 'event'
        },
        {
            inputs: [],
            name: 'DIVISOR',
            outputs: [
                {
                    internalType: 'uint16',
                    name: '',
                    type: 'uint16'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'adapters',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint8',
                    name: '_baseFee',
                    type: 'uint8'
                }
            ],
            name: 'adjustBaseFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_bridge',
                    type: 'address'
                }
            ],
            name: 'adjustBridge',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_feeCollector',
                    type: 'address'
                }
            ],
            name: 'adjustFeeCollector',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_relayer',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'status_',
                    type: 'bool'
                }
            ],
            name: 'adjustRelayer',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint8',
                    name: '_voteThreshold',
                    type: 'uint8'
                }
            ],
            name: 'adjustVoteThreshold',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint8',
                    name: '_xswapFee',
                    type: 'uint8'
                }
            ],
            name: 'adjustXSwapFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'baseFee',
            outputs: [
                {
                    internalType: 'uint16',
                    name: '',
                    type: 'uint16'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'bridge',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint64',
                    name: '',
                    type: 'uint64'
                }
            ],
            name: 'chainSwappers',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'feeCollector',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_router',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_path0',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_path1',
                    type: 'address'
                }
            ],
            name: 'getPair',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner_',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'feeCollector_',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'paper_',
                    type: 'address'
                }
            ],
            name: 'initialize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data_',
                    type: 'bytes'
                }
            ],
            name: 'messageProcess',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bool',
                    name: '_unwrap',
                    type: 'bool'
                },
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amountOutMin',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                },
                {
                    internalType: 'address[]',
                    name: 'path',
                    type: 'address[]'
                },
                {
                    internalType: 'address[]',
                    name: 'pairs',
                    type: 'address[]'
                }
            ],
            name: 'multiPairSwap',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amountOutMin',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'router',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                },
                {
                    internalType: 'address[]',
                    name: 'path',
                    type: 'address[]'
                }
            ],
            name: 'multiPoolNativeSwap',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes1',
                    name: '_unwrap',
                    type: 'bytes1'
                },
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amountOutMin',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'router',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                },
                {
                    internalType: 'address[]',
                    name: 'path',
                    type: 'address[]'
                }
            ],
            name: 'multiPoolTokenSwap',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paper',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paperBridge',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paused',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'refSplit',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'referralSplit',
            outputs: [
                {
                    internalType: 'uint16',
                    name: '',
                    type: 'uint16'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'target',
                    type: 'address'
                },
                {
                    internalType: 'bytes32',
                    name: 'txId',
                    type: 'bytes32'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'relayMessage',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'sourceTxId',
                    type: 'bytes32'
                }
            ],
            name: 'relayMessageSwap',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes1',
                    name: 'wrapmap',
                    type: 'bytes1'
                },
                {
                    internalType: 'uint64',
                    name: 'originChainId',
                    type: 'uint64'
                },
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amountOutMin',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'router',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'originSwapper',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'receiver',
                    type: 'address'
                },
                {
                    internalType: 'address[]',
                    name: 'path',
                    type: 'address[]'
                },
                {
                    internalType: 'bytes32',
                    name: 'sourceTxId',
                    type: 'bytes32'
                }
            ],
            name: 'relayXSwap',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'relayers',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                }
            ],
            name: 'retrieveAsset',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_paper',
                    type: 'address'
                }
            ],
            name: 'setPaper',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_paperBridge',
                    type: 'address'
                }
            ],
            name: 'setPaperBridge',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_refSplit',
                    type: 'uint256'
                }
            ],
            name: 'setSplit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amountOutMin',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'pair',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'input',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'output',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                }
            ],
            name: 'singlePoolNativeSwap',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes1',
                    name: 'firstByte',
                    type: 'bytes1'
                },
                {
                    internalType: 'uint64',
                    name: 'destinationChain',
                    type: 'uint64'
                },
                {
                    internalType: 'address',
                    name: 'receiver',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'bridgeV2',
                    type: 'address'
                },
                {
                    internalType: 'address[]',
                    name: 'path',
                    type: 'address[]'
                },
                {
                    components: [
                        {
                            internalType: 'uint32',
                            name: 'slippage',
                            type: 'uint32'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address',
                            name: 'router',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'destSwapper',
                            type: 'address'
                        }
                    ],
                    internalType: 'struct RBXTeleSwapUpgradeable.xTrade',
                    name: 'details',
                    type: 'tuple'
                },
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                },
                {
                    internalType: 'bytes',
                    name: 'data_',
                    type: 'bytes'
                }
            ],
            name: 'swapCrossLoad',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'voteThreshold',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            name: 'xMsgStatus',
            outputs: [
                {
                    internalType: 'enum RBXTeleSwapUpgradeable.xStatus',
                    name: '',
                    type: 'uint8'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint64',
                    name: 'destinationChain',
                    type: 'uint64'
                },
                {
                    internalType: 'address',
                    name: 'finalToken',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'receiver',
                    type: 'address'
                },
                {
                    internalType: 'address[]',
                    name: 'path',
                    type: 'address[]'
                },
                {
                    components: [
                        {
                            internalType: 'uint32',
                            name: 'slippage',
                            type: 'uint32'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address',
                            name: 'router',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'destSwapper',
                            type: 'address'
                        }
                    ],
                    internalType: 'struct RBXTeleSwapUpgradeable.xTrade',
                    name: 'details',
                    type: 'tuple'
                },
                {
                    internalType: 'address',
                    name: 'referrer',
                    type: 'address'
                }
            ],
            name: 'xSwap',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'xswapFee',
            outputs: [
                {
                    internalType: 'uint16',
                    name: '',
                    type: 'uint16'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            name: 'xswapStatus',
            outputs: [
                {
                    internalType: 'uint8',
                    name: 'votes',
                    type: 'uint8'
                },
                {
                    internalType: 'uint64',
                    name: 'targetChain',
                    type: 'uint64'
                },
                {
                    internalType: 'enum RBXTeleSwapUpgradeable.xStatus',
                    name: 'status',
                    type: 'uint8'
                },
                {
                    internalType: 'bytes32',
                    name: 'sourceTxId',
                    type: 'bytes32'
                },
                {
                    internalType: 'bytes32',
                    name: 'destTxId',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            stateMutability: 'payable',
            type: 'receive'
        }
    ],
    erc721: [
        {
            inputs: [
                {
                    internalType: 'string',
                    name: '_name',
                    type: 'string'
                },
                {
                    internalType: 'string',
                    name: '_symbol',
                    type: 'string'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'approved',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'ApprovalForAll',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'getApproved',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                }
            ],
            name: 'isApprovedForAll',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'string',
                    name: 'tokenURI_',
                    type: 'string'
                }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'ownerOf',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'string',
                    name: 'baseURI_',
                    type: 'string'
                }
            ],
            name: 'setBaseURI',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes4',
                    name: 'interfaceId',
                    type: 'bytes4'
                }
            ],
            name: 'supportsInterface',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'tokenURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],
    multiCall: [
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'target',
                            type: 'address'
                        },
                        {
                            internalType: 'bytes',
                            name: 'callData',
                            type: 'bytes'
                        }
                    ],
                    internalType: 'struct Multicall.Call[]',
                    name: 'calls',
                    type: 'tuple[]'
                },
                {
                    internalType: 'bool',
                    name: 'strict',
                    type: 'bool'
                }
            ],
            name: 'aggregate',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'blockNumber',
                    type: 'uint256'
                },
                {
                    components: [
                        {
                            internalType: 'bool',
                            name: 'success',
                            type: 'bool'
                        },
                        {
                            internalType: 'bytes',
                            name: 'data',
                            type: 'bytes'
                        }
                    ],
                    internalType: 'struct Multicall.Return[]',
                    name: 'returnData',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'blockNumber',
                    type: 'uint256'
                }
            ],
            name: 'getBlockHash',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: 'blockHash',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getCurrentBlockCoinbase',
            outputs: [
                {
                    internalType: 'address',
                    name: 'coinbase',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getCurrentBlockDifficulty',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'difficulty',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getCurrentBlockGasLimit',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'gaslimit',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getCurrentBlockTimestamp',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'timestamp',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'addr',
                    type: 'address'
                }
            ],
            name: 'getEthBalance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'balance',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getLastBlockHash',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: 'blockHash',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]
};

export default ABI;
