declare const _paq: any;

export const trackEvent = (category: string, action: string, name: string, value: string) => {
    /*
    Example of how to use this function:

        # Tracking Brochure Downloads
        
        Event Category: Downloads
        Event Action: PDF Brochure Download
        Event Name: Matomo Event Tracking Brochure
        Event Value: blank

        trackEvent('Downloads', 'PDF Brochure Download', 'Matomo Event Tracking Brochure Downloads', '');
        

        # Tracking User Reviews
        
        Event Category: Reviews
        Event Action: Published Matomo Review
        Event Name: blank
        Event Value: 10

        trackEvent('Reviews', 'Published Matomo Review', '', '10');


    */

        console.log('inside trackEvent');

    if (typeof _paq === 'undefined') {
        console.log('analytics: not initialized');
        return;
    }

    console.log('analytics: sending event: ', category, action, name, value);

    try {
        _paq.push(['trackEvent', category, action, name, value]);
    } catch (e) {
        console.log('analytics: error: ', e);
    }
};
