<script lang="ts">
  import { onMount } from "svelte";
  import type { IChain } from "../../Data/Chains";
  import RadioButton from "../Common/RadioButton.svelte";
  import { createEventDispatcher } from "svelte";
  import { SkipTexts, Locale } from "../../Data/Locale";

  export let message = '';
  export let venue: IChain | null = null;
  export let pulse: boolean = false;
  export let availableVenues: IChain[];
  export let loading: boolean =  false;
  // export let borderColor: string = '';

  const dispatch = createEventDispatcher();

  onMount(() => {
    console.log('availableVenues', availableVenues);
  });

  let buttonClass: string;
  $: buttonClass = pulse ? "paymentmethod flex items-center mr-2 animate-pulse infoText" : "paymentmethod flex items-center mr-2 infoText";

</script>

<button  on:click={() => dispatch('showPaymentMethods')} class="{buttonClass}" style='border: 1px solid var(--border-color)'>
  <!-- <div class="pl-2">
    <RadioButton active="{active}"></RadioButton>
  </div> -->
  {#if venue}
    <img class="py-2 px-2 paymentmethod-icon" src="{venue.logoURI}" alt="venue-icon">
    <span class="pr-2">{message}</span>
  {:else if loading}
    <span class="px-2 py-2">Loading...</span>
  {:else if availableVenues.length == 0}
    <span class="px-2 py-2">Select Fiat/Coin</span>
  {:else}
    <span class="px-2 py-2">{SkipTexts[$Locale].select_venue}</span>
  {/if}
</button>

<style lang="scss">
  .paymentmethod{
    border-radius: 0.75rem;
    /* border: 1px solid var(--34-sky-500, #0EA5E9); */
    // background: linear-gradient(180deg, var(--button-gradient) 0%, var(--button-gradient-end) 110%);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
  }
  .paymentmethod-icon{
    width: 3em;
  }

  .disabled{
    /* @apply text-gray-400 bg-gray-900; */
    background: #e3e3e3;
    border-color: var(--border-color);
    border-style: dotted;
    border-width: 2px;
    /* font-style: italic; */
    font-weight: 400;
  }

  .disabled span {
    color: #7a7a7a;
    font-weight: 400;
  }
</style>