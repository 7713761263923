import { get, writable } from 'svelte/store';
import { SKIPSHIFT_DATA } from './LocalStorageItems';
import type { IToken } from './Tokens';
import { ChainId } from './Wallet';
import type { IRawListing } from './P2P';

interface Asset {
  address: string;
  chainId: number;
  decimals: number;
  logoURI: string;
  name: string;
  symbol: string;
}

interface Venue extends Array<string> {}

interface ReturnData {
  id: number;
  active: boolean;
  reqCollateral: boolean;
  type: string;
  assetType: string;
  tier: string;
  spread: string;
  currency: string;
  asset: Asset;
  min: string;
  max: string;
  maker: string;
  venues: Venue[];
  chainID: number;
}

export interface Pair extends Array<string | Venue[]> {}

export interface SkipShiftData {
  returnData: IRawListing[];
  pairs: Pair[];
  minMax?: any;
  timestamp: number;
}

const deepUnique = (obj: any): any => {
  const seen = new Set();

  const deepUniqHelper = (currentObj: any): typeof currentObj => {
    const serialized = JSON.stringify(currentObj);
    if (seen.has(serialized)) {
      return null;
    }
    seen.add(serialized);

    if (typeof currentObj !== 'object' || currentObj === null) {
      return currentObj;
    }

    if (Array.isArray(currentObj)) {
      return currentObj.map(deepUniqHelper).filter((x) => x !== null);
    }

    const newObj: any = {};
    for (const key in currentObj) {
      const value = deepUniqHelper(currentObj[key]);
      if (value !== null) {
        newObj[key] = value;
      }
    }

    return newObj as typeof currentObj;
  };

  return [...(new Set(deepUniqHelper(obj)))];
};

const shallowUnique = (obj: { [key: string]: any }): { [key: string]: any } => {
  const seen = new Set();
  const result: { [key: string]: any } = {};

  for (const key in obj) {
    const value = obj[key];
    const serialized = JSON.stringify(value);

    if (!seen.has(serialized)) {
      seen.add(serialized);
      result[key] = value;
    }
  }

  return result;
};

const shallowUniqueArray = (arr: any[]): any[] => {
  const seen = new Set();
  const result: any[] = [];

  for (const obj of arr) {
    const serialized = JSON.stringify(obj);
    if (!seen.has(serialized)) {
      seen.add(serialized);
      result.push(obj);
    }
  }

  return result;
};

type DeepDataType = any[] | { [key: string]: any };

const deepReplace = (newData: DeepDataType, oldData: DeepDataType): DeepDataType => {
  if (Array.isArray(newData) && Array.isArray(oldData)) {
    const result: any[] = [...oldData];

    for (const item of newData) {
      const index = oldData.findIndex((oldItem) => JSON.stringify(oldItem) === JSON.stringify(item));
      if (index === -1) {
        result.push(item);
      } else {
        result[index] = item;
      }
    }

    return result;
  }

  if (!Array.isArray(newData) && !Array.isArray(oldData)) {
    const result: { [key: string]: any } = { ...oldData };

    for (const key in newData) {
      if (typeof newData[key] === 'object' && newData[key] !== null && oldData.hasOwnProperty(key)) {
        result[key] = deepReplace(newData[key], oldData[key]);
      } else {
        result[key] = newData[key];
      }
    }

    return result;
  }

  return newData;
};





const loadAndMerge = (): SkipShiftData => {
  const storedData = localStorage.getItem(SKIPSHIFT_DATA) || '[]';
  return JSON.parse(storedData) as SkipShiftData;
};

const updateSkipShiftData = (newData: SkipShiftData, existingData: SkipShiftData): SkipShiftData => {
  const updatedReturnData = deepReplace(newData.returnData, existingData?.returnData || []);
  const updatedPairs = deepReplace(newData.pairs, existingData?.pairs || []);
  const updatedTimestamp = Math.max(existingData.timestamp, newData.timestamp);

  return {
    returnData: updatedReturnData as IRawListing[],
    pairs: updatedPairs as Pair[],
    timestamp: updatedTimestamp,
  };
};


const p2pDataStore = (initialData: SkipShiftData) => {
  const { subscribe, set, update } = writable(initialData);

  return {
    subscribe,
    set: (data: SkipShiftData) => {
      localStorage.setItem(SKIPSHIFT_DATA, JSON.stringify(data));
      set(data);
    },
    update: (newData: SkipShiftData) => {
      update(existingData => {
        const updatedData = updateSkipShiftData(newData, existingData || []);
        localStorage.setItem(SKIPSHIFT_DATA, JSON.stringify(updatedData));
        return updatedData;
      });
    },
  };
};

export const P2PDataStore = p2pDataStore(loadAndMerge());
