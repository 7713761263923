const CustomizationList = [
    'gempad',
];

export const setCustomTheme = () => {
    let found = false;
    for (let i = 0; i < CustomizationList.length; i++) {
        // window.location.ancestorOrigins[0]
        let hostname;

        try {
            const hostSplit = new URL(window.location.ancestorOrigins[0]).hostname.split('.');
            console.log('hostSplit: ', hostSplit);
            hostname = hostSplit[hostSplit.length - 2];
            console.log('hostname: ', hostname);
        } catch (error) {
            hostname = null;
            
        }

        if (hostname && hostname.search(CustomizationList[i]) > -1) {
            document.body.parentElement?.setAttribute('data-theme', CustomizationList[i]);
            found = true;
            break;
        }
    }

    if(!found) {
        const htmlElement = document.body.parentElement;
        const currentTheme = htmlElement?.getAttribute('data-theme') || 
                            (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

        
        htmlElement?.setAttribute('data-theme', currentTheme);
    }
};
