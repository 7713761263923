const isObjectEmpty = (obj: Object) => {
    // Check if the object itself is empty
    if (Object.keys(obj).length === 0) return true;
  
    // Check if all properties are empty values
    return Object.values(obj).every(value =>
      value === null ||
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' && value !== null && Object.keys(value).length === 0)
    );
  };

export default isObjectEmpty;