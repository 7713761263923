declare const ethereum: any;

import { web } from '../Data/Web3Store';
import Web3 from 'web3';
import { ChainId, Wallet, WalletConnected } from '../Data/Wallet';
import { BaseTokens } from '../Data/Tokens';
import { get } from 'svelte/store';
import BigNumber from 'bignumber.js';
import { Chains } from '../Data/Chains';
import { rpcLooper } from './helpers/rpcLooper';
import { SupportedChains } from '../Data/SupportedChains';
import { isEmbeddedInIFrame } from '../API/helpers/iframeProvider';
import { IFrameEthereumProvider } from '../API/helpers/iframeProvider';
import { trackEvent } from './analytics';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { PLATFORM_FEE_MULTIPLIER } from '../Data/Constants';
import { getBaseFee } from './P2P';
import { provider } from 'web3-core';
import { isMobile } from './utils/checkMobile';
import { Provider } from '../Data/Provider';

export const connect = async (selectedProvider?: Provider | null, useWalletConnect: boolean = false) => {
    let givenProvider = selectedProvider?.provider || null;
    console.log('connect: provider: ', givenProvider);
    let account: string = '';

    if (useWalletConnect) {
        givenProvider = await initWalletConnect(account);
    } else if (isEmbeddedInIFrame() && isMobile()) {
        console.log('connect: mobile, embedded in iframe, using iframe provider');
        givenProvider = new IFrameEthereumProvider() as unknown as provider;
        givenProvider.requestConnection();
        const target = window as any;
        target.web3 = givenProvider;
        target.ethereum = givenProvider;
    } else if (isEmbeddedInIFrame() && selectedProvider) {
        console.log('connect: embedded in iframe, using iframe provider');
        const providerInfo = selectedProvider.info;
        givenProvider = new IFrameEthereumProvider(providerInfo.uuid) as unknown as provider;
        givenProvider.requestConnection();
        const target = window as any;
        target.web3 = givenProvider;
        target.ethereum = givenProvider;
    } else if (!givenProvider && window.ethereum) {
        console.log('connect: using window.ethereum');
        givenProvider = window.ethereum;
    } else if (givenProvider) {
        console.log('connect: provider included in call');
        await setConnection(givenProvider);
    } else {
        console.log('no provider found, using wallet connect');
        givenProvider = await initWalletConnect(account);
    }

    if (givenProvider) await setConnection(givenProvider);
    else {
        console.error('no provider found');
        return false;
    }

    if (get(WalletConnected)) trackEvent('p2p', 'wallet_connected', '', 'success');
    return true;
};

async function initWalletConnect(account: string | undefined) {
    const provider = await generateWalletConnectProvider();

    //  Enable session (triggers QR Code modal if no wallet is detected)
    await provider.enable();

    if (provider.connected) {
        console.log('wallet connected');
        await setConnection(provider as provider);
    }

    return provider;
}

export async function setConnection(provider: provider) {
    console.log('setting connection to provider: ', provider);
    const w3 = new Web3(provider);
    console.log('setConnection w3: ', w3);

    try {
        // await provider.enable();
        // const addresses = await w3.eth.requestAccounts();
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        const chainId = await w3.eth.getChainId();
        console.log('setConnection chainId: ', chainId);
        console.log('chainId: ', chainId, ', addresses: ', addresses);
        const account = addresses[0];

        web.set(w3);
        ChainId.set(chainId);
        WalletConnected.set(true);
        await updateWallet(chainId, account || '');

        // Subscribe to accounts change
        addListeners(w3);
    } catch (e) {
        console.error('Error setting connection to provider: ', e);
        WalletConnected.set(false);
    }
}

export const updateWallet = async (chain: number, account: string) => {
    ChainId.set(chain);

    getBaseFee().then((fee) => {
        PLATFORM_FEE_MULTIPLIER.set(fee);
    });

    if (!account) {
        WalletConnected.set(false);
        Wallet.set({
            address: '',
            balance: new BigNumber(0),
            decimals: 0,
            symbol: 'UNK',
        });
        return;
    }

    const w3 = get(web);
    const baseToken = BaseTokens.find((t) => t.chainId === chain);

    // try to get balance
    let bal = new BigNumber(0);
    try {
        bal = BigNumber(await w3.eth.getBalance(account));
    } catch (e) {
        console.error('Error getting balance: ', e);
        WalletConnected.set(false);
        return false;
    }

    Wallet.set({
        address: account,
        balance: bal,
        symbol: baseToken?.symbol || 'ETH',
        decimals: baseToken?.decimals || 18,
    });
};

function addListeners(w3: any) {
    const provider = w3.currentProvider as any;

    provider.on('accountsChanged', async (accounts: string[]) => {
        console.log('accountsChanged to: ', accounts);
        const chain = await w3.eth.getChainId();
        const addresses = await provider.request({ method: 'eth_accounts' });
        updateWallet(chain, addresses[0]);
    });

    // Subscribe to chainId change
    provider.on('chainChanged', async (chainId: number) => {
        console.log('chainChanged to: ', chainId);
        const chain = await w3.eth.getChainId();
        const addresses = await provider.request({ method: 'eth_accounts' });
        updateWallet(chain, addresses[0]);
    });

    // Subscribe to session connection
    provider.on('connect', async () => {
        const chain = await w3.eth.getChainId();
        const addresses = await provider.request({ method: 'eth_accounts' });
        console.log('connect event, chain: ', chain, ', addresses: ', addresses);
        updateWallet(chain, addresses[0]);
    });

    // Subscribe to session disconnection
    provider.on('disconnect', async (code: number, reason: string) => {
        // const chain = await w3.eth.getChainId();
        // const addresses = await provider.request({ method: 'eth_accounts' });
        updateWallet(0, '');
    });
}

export const disconnect = async () => {
    const chains = get(Chains);

    Wallet.set({
        address: '',
        balance: new BigNumber(0),
        decimals: 1,
        symbol: 'ETH',
    });

    WalletConnected.set(false);

    const params = new URLSearchParams(location.search);
    const defaultChain = parseInt(params.get('chain_id') || '') || 1;

    ChainId.set(defaultChain);
    const rpcs = chains[defaultChain].rpc || [];
    const defaultWeb = await rpcLooper(defaultChain);
    if (defaultWeb) web.set(defaultWeb);
};

const generateWalletConnectProvider = async () => {
    const chainsStore = get(Chains);

    // create mapping of Chains object to Onboard chains setting
    const replacedKeysInData: any = {};
    Object.keys(chainsStore).forEach((key: any) => {
        const rpcList = chainsStore[key]?.rpc;
        if (!rpcList) return;
        const rpcId = Math.round(Math.random() * rpcList.length);
        const rpc = rpcList[rpcId];
        if (!rpc) return;
        const id = key; //parseInt(key);
        replacedKeysInData[id] = rpc;
    });

    let supportedChains = get(SupportedChains);

    const provider = await EthereumProvider.init({
        projectId: '607da897f899868383a293815e9e9775',
        metadata: {
            name: 'SkipShift',
            description: 'Decentralized fiat on/off ramp',
            url: 'https://skipshift.io', // origin must match your domain & subdomain
            icons: [''],
        },
        showQrModal: true,
        optionalChains: supportedChains as any,
        rpcMap: replacedKeysInData,
    });

    return provider;
};
