import { ChainId } from "../../Data/Wallet";
import { rpcLooper } from "./rpcLooper";
import { get } from "svelte/store";
import Web3 from "web3";
import keccak from "keccak";

export function toTitleCase(str: string) {
    const arr = str.split('_');
  
    const result = [];
  
    for (const word of arr) {
      result.push(word.charAt(0).toUpperCase() +
                  word.slice(1).toLowerCase() // 👈️ lowercase remainder
      );
    }
  
    return result.join(' ');
  }


export const getIndexBreadthFirst = (obj: any, value: any): [string[], any[]] => {
    // Create an empty array to store the indices
    const indices: string[] = [];
    let matches: typeof obj[] = [];
  
    // Create a queue to store the objects to be searched
    let queue: any[] = [{ obj, path: Object.keys(obj)[0] }];
    queue.push();
  
    // Perform a breadth-first search of the object
    while (queue.length > 0) {
      const { obj, path } = queue.shift();
  
      // Loop through the properties of the object
      for (const key of Object.keys(obj)) {
        // If the current property is an object, add it to the queue to be searched
        if (typeof obj[key] === "object" && obj[key] !== null) {
          queue.push({ obj: obj[key], path: `${path}.${key}` });
        } else {
          // If the current property matches the value, add its index to the indices array
          if (obj[key] === value) {
            indices.push(path ? `${path}.${key}` : key);
            matches.push(obj);
          }
        }
      }
    }
  
    matches = [...(new Set(matches))];
    // Return the array of indices
    return [indices, matches];
  }

// return checksummed wallet address using web3 utils
export const checksummed = (address: any) => {
  // const web3 = await rpcLooper(get(ChainId));
  // const web3 = new Web3();
  // return web3.utils.toChecksumAddress(address);
  return toChecksumAddress(address);
}


export function toChecksumAddress (address: string, chainId?: string | undefined) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    throw new Error(
      `Given address "${address}" is not a valid Ethereum address.`
    )
  }

  const stripAddress = stripHexPrefix(address).toLowerCase()
  const prefix = chainId != null ? chainId.toString() + '0x' : ''
  const keccakHash = keccak('keccak256')
    .update(prefix + stripAddress)
    .digest('hex')
  let checksumAddress = '0x'

  for (let i = 0; i < stripAddress.length; i++) {
    checksumAddress +=
      parseInt(keccakHash[i], 16) >= 8
        ? stripAddress[i].toUpperCase()
        : stripAddress[i]
  }

  return checksumAddress
}

function checkAddressChecksum (address: string, chainId?: string | undefined) {
  const stripAddress = stripHexPrefix(address).toLowerCase()
  const prefix = chainId != null ? chainId.toString() + '0x' : ''
  const keccakHash = keccak('keccak256')
    .update(prefix + stripAddress)
    .digest('hex')

  for (let i = 0; i < stripAddress.length; i++) {
    let output =
      parseInt(keccakHash[i], 16) >= 8
        ? stripAddress[i].toUpperCase()
        : stripAddress[i]
    if (stripHexPrefix(address)[i] !== output) {
      return false
    }
  }
  return true
}

function stripHexPrefix (value: any) {
  return value.slice(0, 2) === '0x' ? value.slice(2) : value
}