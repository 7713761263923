import { writable } from "svelte/store";
import type BigNumber from "bignumber.js";

export interface IPrefillData{
    direction: '0' | '1';
    currency: string;
    asset: string;
    chainId: number;
    amount?: BigNumber;
    venue?: string | null;
}

export const PrefillData = writable<IPrefillData>();