<script lang='ts'>
  import Home from "./Pages/Home.svelte";
  import Products from "./Pages/Products.svelte";
  import Partners from "./Pages/Partners.svelte";
  import Methods from "./Pages/Methods.svelte";
  import Blurb from "./Pages/Blurb.svelte";
  import Subscribe from "./Pages/Subscribe.svelte";
  import SkipShift from "./SkipShift/SkipShift.svelte";
</script>

  <div id="home" class="large-section">
    <Home></Home>
  </div>
  <div id="products" class="small-section">
    <Products></Products>
  </div>
  <div id="partners" class="small-section">
    <Partners></Partners>
  </div>
  <div id="methods" class="small-section">
    <Methods></Methods>
  </div>
  <div id="blurb" class="small-section">
    <Blurb></Blurb>
  </div>
  <div id="subscribe" class="small-section">
    <Subscribe></Subscribe>
  </div>


<style lang="scss">
  .large-section {
    @apply flex;
    height: 100%;
    /* max-height: 650px; */
    /* padding: 5rem 10rem; */
  }
  .small-section {
    /* @apply min-h-fit; */
  }
</style>