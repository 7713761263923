<script lang="ts">
    import Nav from './components/Nav.svelte';
    import Footer from './components/Footer.svelte';
    // import DEXModal from './components/Common/DEXModal.svelte';
    import { onMount } from 'svelte';
    import chains from './Data/Chains';
    import { Chains } from './Data/Chains';
    import { SupportedChains } from './Data/SupportedChains';
    import { ChainId, Referrer } from './Data/Wallet';
    import { Toaster } from 'svelte-sonner';
    // import AlertsModal from './components/Common/AlertsModal.svelte';
    import { getRate } from './API/helpers/getCurrencyConversionRate';
    import Router from 'svelte-spa-router';
    import { wrap } from 'svelte-spa-router/wrap';
    import Loadable from './components/Utils/Loadable.svelte';
    import Content from './components/Content.svelte';
    import { isEmbeddedInIFrame } from './API/helpers/iframeProvider';
    import { IFrameEthereumProvider } from './API/helpers/iframeProvider';
    import Contracts from './Data/Contracts';
    import { getUsernameWallet } from './API/helpers/referrals';
    import { Locale, isSupportedLanguage } from './Data/Locale';
    import { PrefillData } from './Data/Prefill';
    import BigNumber from 'bignumber.js';
    // import { showDEXModal } from './Data/Modals';
    import { WalletConnected } from './Data/Wallet';
    import { setCustomTheme } from './API/thirdparty/customizations';
    import { createLogPanel } from './API/utils/mobileDebug';
    import { createStore } from 'mipd';
    import WalletProviders from './components/Utils/WalletProviders.svelte';
    import { connect } from './API/Wallet';

    const routes = {
        '/': Content,
        '/tos': wrap({
            asyncComponent: () => import('./components/Pages/TOS.svelte'),
        }),
        '/policy': wrap({
            asyncComponent: () => import('./components/Pages/Policy.svelte'),
        }),
        '/integrate': wrap({
            asyncComponent: () => import('./components/Pages/Integrate.svelte'),
        }),
        // '/dex': wrap({
        //     asyncComponent: () => import('./components/Pages/DEX.svelte')
        // }),
        '*': wrap({
            asyncComponent: () => import('./components/Content.svelte'),
        }),
    };

    let widgetLoad = false;
    let iframeResize;
    if (window.location.href.search('widget') >= 0) {
        widgetLoad = true;
        console.log('loading in widget mode...');
        (async () => {
            iframeResize = await import('@iframe-resizer/child');
        })();
    }

    const options = {
        duration: 5000,
        pausable: true,
        theme: {
            '--toastBtnHeight': 'auto',
            '--toastMinHeight': '40px',
            '--toastContainerTop': 'revert',
            '--toastContainerBottom': '1.5rem',
            '--toastBorderRadius': '0.5rem',
            '--toastBtnFont': '200%, Open-sans, inherit',
            '--toastBackground': 'linear-gradient(90deg, #0EA5E9 0%, rgba(14, 165, 233, 0.50) 100%)',
            '--toastWidth': '21rem',
        },
    };

    let showAlertsModal = false;
    let showRPCFixModal = false;

    let onShowAlertsClick = () => {
        showAlertsModal = !showAlertsModal;
    };
    // let onShowDEXClick = () => {
    //     $showDEXModal = !$showDEXModal;
    // };
    let onShowRPCFixClick = () => {
        showRPCFixModal = !showRPCFixModal;
    };

    onMount(async () => {
        // if (isEmbeddedInIFrame()) {
        //     const target = window as any;
        //     const provider = new IFrameEthereumProvider();

        //     target.web3 = provider;
        //     target.ethereum = provider;
        // }

        if (!isEmbeddedInIFrame()) {

        getProviders();
        }
        const urlParams = new URLSearchParams(window.location.search);
        const referrer = urlParams.get('r');
        console.log('referrer:', urlParams.get('r'));

        setCustomTheme();

        const direction = urlParams.get('direction');
        const currency = urlParams.get('currency');
        const asset = urlParams.get('asset');
        const chainId = urlParams.get('chainId');
        const amount = urlParams.get('amount');
        const venue = urlParams.get('venue');

        if (direction && currency && asset && chainId) {
            $PrefillData = {
                direction: direction == 'on' ? '1' : '0',
                currency: currency.toUpperCase(),
                asset: asset,
                chainId: +chainId,
                amount: BigNumber(amount || 0),
                venue: venue,
            };
        }

        const locale: string | null = urlParams.get('locale');

        const userLocale = (navigator?.language || navigator?.userLanguage || '').slice(0, 2);

        if (!!locale && isSupportedLanguage(locale)) $Locale = locale;
        else if (!!userLocale && isSupportedLanguage(userLocale)) $Locale = userLocale;
        else $Locale = 'en';

        $Referrer = (await getUsernameWallet(referrer || '')) || Contracts.ZERO;

        console.log('$Referrer: ', $Referrer);

        if (window.location.href.search('mobile_debug') >= 0) createLogPanel();

        $Chains = chains;
        $SupportedChains = [1, 56, 43114, 369, 109];

        //call getRate once to update the store and cache all exchangeRates
        await getRate('EUR');
    });

    const getProviders = async () => {
        const store = createStore();
        let providers = store.getProviders();
        store.subscribe((providerDetails) => (providers = providerDetails));

        console.log('providers', providers);
    };


</script>

{#if !widgetLoad}
    <div class="bg-gradient-to-b from-blue-500 to-blue-900 md:bg-dark-100 flex flex-col">
        <div id="svelte-toast-container">
            <Toaster visibleToasts={5} closeButton />
        </div>
        <Nav on:showNotifsModal={onShowAlertsClick} on:showRPCFixModal={onShowRPCFixClick} />
        <Router {routes} />
        <Footer on:showAlerts={onShowAlertsClick} />
        <!-- {#if showAlertsModal}
            <AlertsModal on:close={onShowAlertsClick} />
        {/if}
        {#if $showDEXModal && $WalletConnected}
            <DEXModal on:close={onShowDEXClick} />
        {/if} -->


    </div>
{:else}
    <Loadable loader={() => import('./components/FrameSkipShift.svelte')} />
{/if}

<style lang="scss">
    :root {
        --toastContainerTop: auto;
        --toastContainerBottom: 1.5rem;
    }

    :global(._toastContainer) {
        right: 0.5rem !important;
    }
    :global(._toastItem) {
        font-size: 10pt;
        width: 20rem;
        background: var(--toastBackground, rgba(66, 66, 66, 0.9));
    }
    :global(._toastBtn) {
        font-size: 200%;
        padding: 0.25rem 1.5rem;
    }
    :global(._toastBar) {
        background: var(--toastBarBackground, white);
    }
</style>
