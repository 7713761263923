import { writable } from 'svelte/store';
import axios from 'axios';
import BackendUrl from '../BackendDetails';
import { ChainData } from './ChainsFallback';

export interface IChains {
  [key: number]: any;
}

/**
 * RPCs to use for RPC Fix button
 */
export interface IGoodRPCs {
  [key: number]: string;
}
export const goodRPCs: IGoodRPCs = {
  1: 'https://eth.llamarpc.com',
  43114: 'https://api.avax.network/ext/bc/C/rpc',
  // 43114: 'https://rpc.tenderly.co/fork/989f1ce4-422a-4ccc-a0fc-2cbc1987e99c',
  56: 'https://binance.llamarpc.com',
  369: 'https://rpc.pulsechain.com',
  109: 'https://shibrpc.com'
}

export const chains: IChains = {
  '0': {
    logoURI: '/images/chains/unknown.svg',
    name: 'Unknown Network',
    chainId: 0,
    shortName: 'Unknown',
    nativeCurrency: {
      name: 'Unknown',
      symbol: 'UNK',
      decimals: 18
    },
    rpc: [

    ],
  },
  '1': {
    logoURI: '/images/chains/Ethereum.svg',
    name: 'Ethereum Mainnet',
    chainId: 1,
    shortName: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpc: [
      "https://eth-mainnet.gateway.pokt.network/v1/lb/6f54bd24",
"https://eth-archival.gateway.pokt.network/v1/lb/6f54bd24",
      'https://cloudflare-eth.com',
      'https://cloudflare-eth.com',
      'https://rpc.ankr.com/eth',],
    explorers: [
      {
        name: 'etherscan',
        url: 'https://etherscan.io',
        standard: 'EIP3091'
      }
    ],
    stableCoins: [
      {
        name: 'USDC',
        symbol: 'USDC',
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        decimals: 6,
        chainId: 1
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        decimals: 6,
        chainId: 1
      },
      {
        name: 'BUSD',
        symbol: 'BUSD',
        address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
        decimals: 18,
        chainId: 1
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        decimals: 18,
        chainId: 1
      }
    ],
    transportTokens: [
      {
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
        name: 'Wrapped ETH',
        symbol: 'WETH'
      }
    ]
  },
  '5': {
    logoURI: '/images/chains/goerli.png',
    name: 'Goerli Test Network',
    chainId: 5,
    shortName: 'Goerli',
    nativeCurrency: {
      name: 'Goerli Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpc: [
      'https://eth-goerli.gateway.pokt.network/v1/lb/6f54bd24',
      'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://ethereum-goerli.publicnode.com',
    'https://rpc.ankr.com/eth_goerli',
    'https://eth-goerli.public.blastapi.io',
    'https://goerli.blockpi.network/v1/rpc/public',
    'https://rpc.goerli.eth.gateway.fm',
      'https://eth-goerli.api.onfinality.io/public',
    ],
    explorers: [
      {
        name: 'etherscan',
        url: 'https://goerli.etherscan.io',
        standard: 'EIP3091'
      }
    ],
    stableCoins: [
      {
        address: '0x3aaF85F9F92275b4B70D961d8Ae2b93952fdE418',
        decimals: 18,
        name: 'USDtst',
        symbol: 'USDtst',
        chainId: 5
      }
    ],
    transportTokens: [
      {
        address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
        decimals: 18,
        name: 'Goerli WETH',
        symbol: 'gWETH'
      }
    ]
  },
  '56': {
    logoURI: '/images/chains/binance_smart_chain.svg',
    name: 'BNB Chain',
    chainId: 56,
    shortName: 'BSC',
    nativeCurrency: {
      name: 'Binance Native',
      symbol: 'BNB',
      decimals: 18
    },
    rpc: [
      "https://bsc-mainnet.gateway.pokt.network/v1/lb/6f54bd24",
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
    ],
    explorers: [
      {
        name: 'bscscan',
        url: 'https://bscscan.com',
        standard: 'EIP3091'
      }
    ],
    stableCoins: [
      {
        name: 'BUSD',
        symbol: 'BUSD',
        address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18,
        chainId: 56
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        decimals: 18,
        chainId: 56
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        address: '0x55d398326f99059ff775485246999027b3197955',
        decimals: 18,
        chainId: 56
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        decimals: 18,
        chainId: 56
      }
    ],
    transportTokens: []
  },
  '109': {
    name: 'Shibarium',
    shortName: 'Shibarium',
    chain: 'SHIB',
    network: 'mainnet',
    rpc: [
      'https://shib.nownodes.io/bb716152-b431-4ae7-8f4f-2666a25b72e5',
      'https://www.shibrpc.com'
    ],
    faucets: [],
    nativeCurrency: {
      name: 'Bone',
      symbol: 'BONE',
      decimals: 18,
      logoURI: '/images/chains/BONE.svg'
    },
    transportTokens: [
      {
        address: '0x839FdB6cc98342B428E074C1573ADF6D48CA3bFd',
        decimals: 18,
        name: 'Wrapped BONE',
        symbol: 'WBONE'
      }
    ],
    chainId: 109,
    networkId: 109,
    logoURI: '/images/chains/shibarium.png',
    stableCoins: [
      {
        name: 'Tether',
        symbol: 'USDT',
        address: '0xaB082b8ad96c7f47ED70ED971Ce2116469954cFB',
        decimals: 6,
        chainId: 109
      }
    ]
  },
  '137': {
    name: 'Matic Mainnet',
    shortName: 'Polygon',
    chainId: 137,
    explorerUrl: 'https://polygonscan.com/tx/',
    chain: 'Matic',
    network: 'mainnet',
    networkId: 137,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    stableCoins: [
      {
        name: 'USDC',
        symbol: 'USDC',
        address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        decimals: 6,
        chainId: 137
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        decimals: 6,
        chainId: 137
      },
      {
        name: 'BUSD',
        symbol: 'BUSD',
        address: '0x9fb83c0635de2e815fd1c21b3a292277540c2e8d',
        decimals: 18,
        chainId: 137
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
        decimals: 18,
        chainId: 137
      }
    ],
    rpc: ['https://rpc-mainnet.matic.network'],
    faucets: [],
    explorers: [
      {
        name: 'polygonscan',
        url: 'https://polygonscan.com',
        standard: 'EIP3091'
      }
    ],
    infoURL: 'https://matic.network/',
    logoURI: '/images/chains/polygon.svg'
  },
  '369': {
    name: 'PulseChain',
    chain: 'Pulse',
    icon: 'PLS',
    logoURI: '/images/chains/pulsechain.svg',
    rpc: [
      'https://pulse.nownodes.io/bb716152-b431-4ae7-8f4f-2666a25b72e5',
      'https://rpc.pulsechain.com'
    ],
    faucets: [],
    nativeCurrency: {
      name: 'Pulse',
      symbol: 'PLS',
      decimals: 18,
      wrapped: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    infoURL: 'https://pulsechain.com/',
    shortName: 'PulseChain',
    chainId: 369,
    networkId: 369,
    transportTokens: [
      {
        address: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
        decimals: 18,
        name: 'Wrapped PLS',
        symbol: 'WPLS'
      }
    ],
    stableCoins: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
        decimals: 6,
        chainId: 369
      },
      {
        name: 'Tether',
        symbol: 'USDT',
        address: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
        decimals: 6,
        chainId: 369
      },
      {
        name: 'Dai',
        symbol: 'DAI',
        address: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
        decimals: 18,
        chainId: 369
      },
    ],
    explorers: [
      {
        name: 'PulseScan',
        url: 'https://scan.pulsechain.com/',
        standard: 'EIP3091',
      },
    ],
  },
  '43114': {
    logoURI: '/images/chains/avalanche.png',
    name: 'Avalanche Mainnet',
    chainId: 43114,
    shortName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpc: [
      "https://avax-mainnet.gateway.pokt.network/v1/lb/6f54bd24",

      'https://api.avax.network/ext/bc/C/rpc',
      'https://rpc.ankr.com/avalanche',
      'https://1rpc.io/avax/c',
      'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
      //'https://avalancheapi.terminet.io/ext/bc/C/rpc'
    ],
    explorers: [{}],
    stableCoins: [
      {
        name: 'USDC.e',
        symbol: 'USDC.e',
        address: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
        decimals: 6,
        chainId: 43114
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        address: '0xc7198437980c041c805a1edcba50c1ce5db95118',
        decimals: 6,
        chainId: 43114

      },
      {
        name: 'BUSD',
        symbol: 'BUSD',
        address: '0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98',
        decimals: 18,
        chainId: 43114
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        address: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
        decimals: 18,
        chainId: 43114
      }
    ],
    transportTokens: []
  },
};

export default chains;

export interface IChain {
  name: string;
  shortName?: string;
  chainId: number;
  chain?: string;
  network?: string;
  networkId?: number;
  color?: string;
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  explorerUrl?: string;
  explorers?: {
    name: string;
    url: string;
    standard: string;
  }[];
  rpc?: string[];
  stableCoins: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
  }[];
  transportTokens?: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
  }[];
  faucets?: string[];
  infoURL?: string;
  logoURI?: string;
}

export const Chains = writable<{ [key: number]: IChain }>({});

export const loadChainData = async () => {
  let resp;
  try {
    resp = await axios.get(`${BackendUrl}/chain-data`);
  } catch (e) {
    resp = null;
  }

  resp = !!resp ? resp.data : ChainData;

  return resp;
};
