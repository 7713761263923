<script lang="ts">
    import { Wallet } from "../../Data/Wallet";
    import { createEventDispatcher, onMount } from "svelte";
    import { ModalTexts, Locale } from "../../Data/Locale";
    import type { ICancellationModalDetails } from "../../Data/Modals";
    import { cancelTrade, rejectTrade } from "../../API/P2P";
    import { addActionLogLine } from "../../Data/ActionLogs";
    import BigNumber from "bignumber.js";

    const dispatch = createEventDispatcher();

    export let modalDetails: ICancellationModalDetails;

    let loading: boolean = false;

    const isDirectCancellable = (): boolean => {
        if (!modalDetails.trade?.lastAction) return false;
        const currentTime = Math.floor(Date.now() / 1000);
        const thirtySixHoursInSeconds = 36 * 60 * 60;

        return (
            currentTime - +modalDetails.trade.lastAction >
            thirtySixHoursInSeconds
        );
    };

    //maker in onramps, taker in offramps
    const isCancelController = (): boolean => {
        if (modalDetails.trade?.type == "ONRAMP") {
            return (
                $Wallet.address.toLowerCase() ==
                modalDetails.trade.maker?.toLowerCase()
            );
        } else {
            return (
                $Wallet.address.toLowerCase() ==
                modalDetails.trade?.taker?.toLowerCase()
            );
        }
    };

    const getCancellationTime = (): string => {
        if (!modalDetails.trade?.lastAction) return "";
        return `<span style="color: var(--active-menu-text-color);">${new Date((+modalDetails.trade?.lastAction + 36 * 60 * 60) * 1000).toLocaleString()}</span>`;
    };

    const getSymbolAndAmount = (): string => {
        return `<span style="color: var(--active-menu-text-color);">${BigNumber(modalDetails.trade?.amount || 0).toFixed(3)} ${modalDetails.trade?.asset.symbol}</span>`;
    };

    const getTitle = (): string | undefined => {
        return "Cancellation request";
    };

    const getDescription = (): string | undefined => {
        if (isCancelController()) {
            if (
                modalDetails.trade?.rampPhase == 2 ||
                modalDetails.trade?.rampPhase == 3
            ) {
                return `Submit a cancellation request for this trade.<br><br> If the counterparty confirms the cancellation, you will automatically receive back the ${modalDetails.trade.asset.symbol} that was held for escrow.<br><br>Alternatively, clicking the cancel button again after ${getCancellationTime()} will refund ${getSymbolAndAmount()} to your wallet.`;
            } else if (modalDetails.trade?.rampPhase == 6) {
                return `You have already requested cancellation for this trade. Come back after ${getCancellationTime()} to withdraw your escrowed funds if the counterparty does not confirm the cancellation.`;
            } else if (modalDetails.trade?.rampPhase == 7) {
                return `The counterparty has requested cancellation for this trade. Click on the button below to withdraw ${getSymbolAndAmount()} from escrow`;
            }
        } else {
            if (
                modalDetails.trade?.rampPhase == 2 ||
                modalDetails.trade?.rampPhase == 3
            ) {
                return `Submit a cancellation request for this trade.<br><br> Please let the provider know in the chat if you experienced any technical issues sending payment on ${modalDetails.trade.venue?.platform}`;
            } else if (modalDetails.trade?.rampPhase == 6) {
                return `The counterparty has requested cancellation for this trade. Please click the button below to confirm the cancellation, and make sure to check the chat for any messages.`;
            } else if (modalDetails.trade?.rampPhase == 7) {
                return `You have already requested cancellation for this trade.`;
            }
        }
    };

    const onCancelClick = async () => {
        let cancel: boolean = false;
        dispatch("close");
        if (!modalDetails.trade) return;
        loading = true;

        if (
            modalDetails.trade?.rampPhase == 0 &&
            modalDetails.trade?.maker?.toLowerCase() ==
                $Wallet.address.toLowerCase()
        ) {
            console.log("rejecting trade");
            addActionLogLine("Rejecting P2P Trade...");
            cancel = await rejectTrade(modalDetails.trade).catch(
                (e) => (loading = false),
            );
            dispatch("reload");
        } else {
            console.log("cancelling trade");
            addActionLogLine("Cancelling P2P Trade...");
            cancel = await cancelTrade(modalDetails.trade).catch(
                (e) => (loading = false),
            );
            dispatch("reload");
        }
        if (!cancel) {
            addActionLogLine("Cancellation failed, please try again");
        } else {
            addActionLogLine("P2P Trade Cancelled/Rejected");
        }

        loading = false;
    };

    onMount(() => {
        console.log("cancellation modal deets onmount", modalDetails);
    });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
    class="fixed inset-0 z-30 flex flex-col justify-center items-center"
    on:click={(_) => /*helpModal = false*/ null}
>
    <div
        class="modalBg text-black px-4 pb-3 pt-1 rounded-3xl md:w-96 neu-border z-[31]"
    >
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <h2>{getTitle()}</h2>
        <p class="infoText text-sm text-left px-4 py-8">
            {@html getDescription()}
        </p>

        <div class="flex gap-8 justify-center my-4">
            <div class="flex justify-center gap-3 md:gap-4 w-1/3">
                <button
                    class="collat rounded-lg px-4 py-1 border border-white text-white text-sm"
                    color="black"
                    on:click={(_) => dispatch("close")}
                >
                    Close
                </button>
                <button
                    class="collat rounded-lg px-4 py-1 border border-white text-white {(isCancelController() && !isDirectCancellable() && [6,7].includes(modalDetails.trade?.rampPhase || 0))
                        ? ' bg-gray-400'
                        : ''} text-sm flex flex-col"
                    color="black"
                    on:click={onCancelClick}
                    disabled={isCancelController() && !isDirectCancellable() && [6,7].includes(modalDetails.trade?.rampPhase || 0)}
                >
                    {#if isCancelController() && (isDirectCancellable() || (modalDetails.trade?.rampPhase == 6 || modalDetails.trade?.rampPhase == 7))}
                        <span class="inline-block text-center leading-tight">
                            Cancel<br>trade
                        </span>
                    {:else}
                        <span class="inline-block text-center leading-tight">
                            Request<br>cancellation
                        </span>
                    {/if}
                </button>
            </div>
        </div>
    </div>
    <div
        on:click={() => dispatch("close")}
        class="w-screen h-screen bg-transparent fixed top-0 left-0"
    ></div>
</div>

<style lang="scss">
    h2 {
        @apply text-lg text-sky-400 font-semibold text-center;
    }

    .neu-border {
        border-width: 1px;
        border-style: solid;
        border-bottom-left-radius: 2rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 0rem;
        overflow: hidden;
        border-block-color: darkgrey;
        border-block-style: dotted;
    }

    .collat {
        padding: 0 0.5rem;
        // color: white;
        // border-radius: 2rem;
        border-style: solid;
        border-width: 0.13rem;
        border-color: var(--border-color);
        width: 100%;
    }
</style>
