import { currencies, paymentMethods } from "../../Data/P2P";
import type { IRawListing } from "../../Data/P2P";


interface IToken {
    name?: string | null;
    chainId?: number;
    symbol?: string | null;
    logoURI?: string;
    address?: string;
  }
  
  export interface IChainBit {
    name: string;
    shortName?: string;
    chainId: number;
  }
  
  type InputDataType = {
      currency: string;
      asset: IToken;
      venues: [[string, string]];
      [key: string]: any;
  }[];
  

  
  export function createDataObjects(inputData: IRawListing[]) {
    let venueChainIdMap: Record<string, IChainBit> = {};
    const assetListingChainIdMap: Record<string, IToken> = {};
    const currencyChainIdMap: Record<string, IToken[]> = {};

    inputData.forEach((dataItem) => {
        // map venue with chainId
        dataItem.venues.forEach((venue) => {
            const venueName = venue['platform'];
            const venueDetails = Object.values(paymentMethods).find((method) => method.shortName === venueName);

            if (venueDetails) {
                venueChainIdMap[venueDetails.name] = venueDetails;
            }

            // map currency with chainId based on venue
            const currencyData = currencies.find((currency) => currency.symbol === dataItem.currency);
            if (currencyData) {
                const updatedCurrencyData = {
                    ...currencyData,
                    chainId: venueDetails?.chainId
                };
                // if (currencyChainIdMap[dataItem.currency] && currencyChainIdMap[dataItem.currency].length > 0) {
                if (currencyChainIdMap[dataItem.currency]) {
                    const existingChainIds = currencyChainIdMap[dataItem.currency].map((currency) => currency.chainId);
                    if (!existingChainIds.includes(updatedCurrencyData.chainId)) {
                        currencyChainIdMap[dataItem.currency].push(updatedCurrencyData);
                    }
                } else {
                    currencyChainIdMap[dataItem.currency] = [updatedCurrencyData];
                }
            }
        });

        // map asset listing with chainId
        const assetAddress = dataItem.asset.address;
        //56-0xEeeeeee... etc
        const assetKey = `${assetAddress}-${dataItem.asset.chainId}`;
        assetListingChainIdMap[assetKey] = dataItem.asset;
    });

    let tempChains: Record<string, IChainBit> = {};
    Object.values(venueChainIdMap).forEach((chain) => {
        tempChains[chain.chainId] = chain;
    });
    venueChainIdMap = tempChains;

    return {
        venues: venueChainIdMap,
        assets: [...Object.values(assetListingChainIdMap)],
        currencies: [...[...Object.values(currencyChainIdMap)].flat()],
    };
}
