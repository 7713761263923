import {  writable } from 'svelte/store';
import { PAIR_INFO_STORE } from './LocalStorageItems';

export interface IPairInfo {
  t0: string; // token0
  t1: string; // token1
  r0: string; // reserves0
  r1: string; // reserves1
  p0: string; // price0
  p1: string; // price1
  d0: number; // decimals0
  d1: number; // decimals1
  router?: string;
  factory?: string;
  pair: string;
  chainId: number;
  blockstamp: number | string;
  timestamp: number | string;
}

const loadAndMerge = () => {
  const mem = JSON.parse(localStorage.getItem(PAIR_INFO_STORE) || '[]') as IPairInfo[];
  // localStorage.setItem(PAIR_INFO_STORE, JSON.stringify(mem, null, 2))
  return mem;
};

// export const PairStore = writable<IPairInfo[]>(loadAndMerge());

// function that returns an IPairInfo[] with duplicates removed



export const pairStore = (pairInfo: IPairInfo[]) => {
  const toString = (value: any) => JSON.stringify(value, null, 2); // helper function
  const toObj = JSON.parse; // helper function

  if (localStorage.getItem(PAIR_INFO_STORE) === null) localStorage.setItem(PAIR_INFO_STORE, toString(pairInfo));
  const saved = toObj(localStorage.getItem(PAIR_INFO_STORE) || ''); // convert to object
  const { subscribe, set, update } = writable(saved); // create the underlying writable store

  return {
    subscribe,
    set,
    update: (pairsInfo: IPairInfo[]) => {
      let items = JSON.parse(localStorage.getItem(PAIR_INFO_STORE) || '') as IPairInfo[];
      if(items){
      for (let i = 0; i < pairsInfo.length; i++) {
        const pair = pairsInfo[i];
        items = items.filter((item: IPairInfo) => item.pair !== pair.pair);
        items.push(pair);
      }
      localStorage.setItem(PAIR_INFO_STORE, toString(items)); // save also to local storage as a string
      return set(items);
    } else {
      localStorage.setItem(PAIR_INFO_STORE, toString(pairsInfo)); // save also to local storage as a string
      return set(pairsInfo);
    }}
  };
}

export const PairStore = pairStore(loadAndMerge());