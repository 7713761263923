import { toast } from 'svelte-sonner'
import { get, writable } from 'svelte/store';

export interface IActionLog {
  text: string;
  css: string;
}

export const ActionLogs = writable<IActionLog[]>([]);
export const ShowActionLogs = writable<boolean>(false);

export const addActionLogLine = (text: string, css: string = '') => {
  let logs = [...get(ActionLogs)];
  // if(logs.some(item => item.text === text)) return;
  logs.push({ text, css });
  ActionLogs.set(logs);
  // const t = get(toast);
  // if (t.length > 2) toast.pop(t[t.length - 1].id)
  toast(text, { 
    unstyled: true,
    // duration: Number.POSITIVE_INFINITY,
    classes: {
      toast: 'toast-unit px-4 py-2 rounded-lg text-sm',
      title: 'text-sm font-light ' + css,
      // description: '_toastItem ' + css,
      // actionButton: '_toastBtn',
      // cancelButton: '_toastBtn',
      closeButton: 'toast-close',
    }
    });
};

export const clearActionLog = () => {
  ActionLogs.set([]);
};

export const appendActionLogLine = (text: string) => {
  let logs = [...get(ActionLogs)];
  if (logs.length === 0) return '';
  logs[logs.length - 1].text += text;
  ActionLogs.set(logs);
};