import axios from 'axios';

export const getWalletUsername = async (address: string): Promise<string> => {
    try {
        const resp = await axios.get(`https://skipscan.win/api/get-referrer-username?walletAddress=${address}`);

        return resp.data.username;
    } catch (e) {
        console.log('updateWalletUsername:', e);
        return '';
    }
}

export const getUsernameWallet = async (username: string): Promise<string> => {
    if(username.length < 1) return '';
    try {
        console.log('getting referrer wallet for username', username);
        const resp = await axios.get(`https://skipscan.win/api/get-referrer-wallet?username=${username}`);
        console.log('referrer data', resp.data);

        return resp.data;
    } catch (e) {
        console.log('updateWalletUsername:', e);
        return '';
    }
}