import { writable } from 'svelte/store';
import type { IP2PTrade } from './P2P';

export const ShowAlertsModal = writable<boolean>(false);

export const ShowDEXModal = writable<boolean>(false);

export interface ICancellationModalDetails {
    show: boolean;
    trade?: IP2PTrade | undefined;
}
export const ShowCancellationModal = writable<ICancellationModalDetails>({show: false});