<script lang="ts">
    import { blur, fade, fly, slide } from 'svelte/transition';
    import SwitchComponent from '../Common/SwitchComponent.svelte';
    import { USER_SETTINGS } from '../../Data/TransactionSettings';
    import { createEventDispatcher, onMount } from 'svelte';
    import type { IChain } from '../../Data/Chains';
    import { SkipTexts, Locale } from '../../Data/Locale';
    import autoAnimate from '../../API/helpers/autoAnimate';

    export let availableVenues: IChain[] = [];
    export let selectedVenue: IChain | null = null;

    const dispatch = createEventDispatcher();

    const onCloseClick = () => {
        dispatch('close');
    };

    onMount(() => {});
</script>

<main class="flex flex-col venues-container">
    <div class="grid grid-cols-4 gap-2" use:autoAnimate>
        {#each availableVenues as venue}
            <div class="flex flex-col justify-center" use:autoAnimate>
                <button
                    on:click={() => {
                        selectedVenue = venue;
                        dispatch('close');
                    }}
                    use:autoAnimate>
                    <img src={venue.logoURI} class="venue-icon" alt={venue.name + ' logo'} use:autoAnimate />
                </button>
            </div>
        {/each}
    </div>
</main>

<style lang="scss">
    .venue-icon {
        width: 2em;
    }

    .venues-container {
        z-index: 0;
        border-radius: 16px;
        padding: 0px 0px 0px 16px;
    }
</style>
